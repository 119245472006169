import { Alert, AlertTitle, Grid } from '@mui/material'
import MedecinEntreprise from 'assureur/store/types/medecinEntreprise'
import { chipStatutMedecin } from 'plateforme/services/medecin.services'
import React from 'react'

type AlertStatutMedecinPartProps = {
  medecin?: MedecinEntreprise
}

export default function AlertStatutMedecinPart({ medecin }: AlertStatutMedecinPartProps) {
  return (
    <Alert
      severity="info"
      elevation={1}
      sx={{
        '& .MuiAlert-message': {
          width: '100%',
        },
        paddingBottom: 0.5,
      }}
    >
      <AlertTitle>
        <Grid container>
          <Grid item xs={12} lg={6} marginTop="-2px">
            <strong>Statut plateforme : </strong>
            {chipStatutMedecin(medecin?.statut, medecin?.labelStatut)}
          </Grid>
        </Grid>
      </AlertTitle>
    </Alert>
  )
}

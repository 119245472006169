import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Card, CardContent, CardHeader, Grid, Snackbar } from '@mui/material'
import {
  ButtonsStack,
  ChercherButton,
  DateTimePickerInput,
  EditTextField,
  RechargerButton,
  SelectOption,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { formatDateTimeISO } from 'plateforme/services/dates.services'
import { trimToUndefined } from 'plateforme/services/utils'
import { TypeMessageNotification } from 'plateforme/store/types/referentiel'
import { NotificationMedecinFilter } from 'medecin/store/types/notificationsMedecin'
import {
  getNotificationCriteria,
  setNotificationCriteria,
} from 'medecin/store/slices/searchCriteriaSlice/searchCriteriaSlice'
import { setNotificationMessageSort } from 'medecin/store/slices/sortSlice/sortSlice'
import AutocompleteEntreprise from 'medecin/components/AutocompleteEntreprise'
import { useGetReferentielEntrepriseQuery } from 'medecin/store/apis/referentielEntrepriseMedecinApi'

type SearchNotificationsProps = {
  search: (criteria: NotificationMedecinFilter) => void
  searchError: IQueryErrorResponse
}

export interface NotificationsFilterData {
  objet: string | null
  dateDebut: Date | null
  dateFin: Date | null
  codeEntrepriseDest: SelectOption | null
}

export default function RechercheNotificationsMessageMedecinPart({ search, searchError }: SearchNotificationsProps) {
  // get default values from localStorage
  const defaultNotificationCriteria = useSelector(getNotificationCriteria)
  const dispatch = useDispatch()

  // Les valeurs initiales du formulaire :
  const initialValues: NotificationsFilterData = {
    objet: defaultNotificationCriteria?.objet ?? '',
    dateDebut: defaultNotificationCriteria?.dateDebut ? new Date(defaultNotificationCriteria?.dateDebut) : null,
    dateFin: defaultNotificationCriteria?.dateFin ? new Date(defaultNotificationCriteria?.dateFin) : null,
    codeEntrepriseDest: defaultNotificationCriteria?.codeEntrepriseDest
      ? ({
          code: defaultNotificationCriteria?.codeEntrepriseDest,
          label: defaultNotificationCriteria?.labelEntrepriseDest,
        } as SelectOption)
      : null,
  }

  const resetValues: NotificationsFilterData = {
    objet: '',
    dateDebut: null,
    dateFin: null,
    codeEntrepriseDest: null,
  }

  const {
    handleSubmit,
    control,
    setError,
    getValues,
    formState: { isValid },
    reset,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })
  useErrorFormMapper(searchError, setError, getValues)

  // state management
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const onSubmit = async (data: NotificationsFilterData) => {
    const filtre = {
      objet: trimToUndefined(data.objet),
      dateDebut: formatDateTimeISO(data.dateDebut),
      dateFin: formatDateTimeISO(data.dateFin),
      types: ['MEL_MED'],
      codeEntrepriseDest: trimToUndefined(data.codeEntrepriseDest?.code),
      tous: 1,
    } as NotificationMedecinFilter
    search(filtre)
    dispatch(
      setNotificationCriteria({
        ...filtre,
        labelEntrepriseDest: trimToUndefined(data.codeEntrepriseDest?.label),
      })
    )
  }

  const handleClickInitSearch = () => {
    const filtre = {
      objet: undefined,
      dateCreationDebut: undefined,
      dateCreationFin: undefined,
      types: ['MEL_MED' as TypeMessageNotification],
      codeEntrepriseDest: undefined,
      tous: 1 as 0 | 1 | undefined,
    }

    search(filtre)
    dispatch(setNotificationCriteria(filtre))
    dispatch(setNotificationMessageSort({}))
    reset(resetValues)
  }

  // Snackbar handlers :
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <Card>
      <CardHeader title="Recherche avancée" />
      <CardContent sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-notifications">
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Controller
                name="objet"
                control={control}
                defaultValue={initialValues.objet}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="objet"
                    label="Objet"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="codeEntrepriseDest"
                control={control}
                defaultValue={initialValues.codeEntrepriseDest}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <AutocompleteEntreprise
                    id="entreprise-dest"
                    label="Entreprise"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    useQuery={useGetReferentielEntrepriseQuery}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="dateDebut"
                control={control}
                defaultValue={initialValues.dateDebut}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DateTimePickerInput
                    InputProps={{
                      id: 'date-debut',
                      fullWidth: true,
                    }}
                    label="Date de début"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="dateFin"
                control={control}
                defaultValue={initialValues.dateFin}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DateTimePickerInput
                    InputProps={{
                      id: 'date-fin',
                      fullWidth: true,
                    }}
                    label="Date de fin"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonsStack>
                <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                  Réinitialiser
                </RechargerButton>
                <ChercherButton
                  variant="contained"
                  type="submit"
                  form="form-recherche-notifications"
                  disabled={!isValid}
                >
                  Rechercher
                </ChercherButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={openSnackbar} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            Erreur serveur lors de la recherche des habilitations
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  )
}

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import {
  AjouterButton,
  EditTextField,
  NoRowsOverlay,
  SelectInput,
  SelectInputReferentiel,
  SupprimerIconButton,
} from 'plateforme/components'
import React, { useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

export const STATUT_TYPES = [
  { code: 'Actif', label: 'Actif' },
  { code: 'Inactif', label: 'Inactif' },
]

interface MissionsSpecifiquesPartProps {
  readOnly?: boolean
}

export default function MissionsSpecifiquesPart({ readOnly }: MissionsSpecifiquesPartProps) {
  const fieldName = 'parametrage.domainesContractuels'
  const { control, getValues } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  })
  const [initialSize] = useState(fields.length)

  const addOnClick = () => {
    append({
      code: '',
      libelle: '',
      dommages: [],
      statutLabel: 'Actif',
    })
  }

  const removeOnClick = (index: number) => {
    return () => remove(index)
  }

  const isAlreadyRegistered = (index: number) => {
    return getValues(`${fieldName}.${index}.registered`)
  }

  return (
    <Card>
      <CardHeader title="Missions spécifiques" />
      <CardContent>
        <Grid container>
          {!readOnly && (
            <Grid item xs={12} textAlign="right">
              <AjouterButton onClick={addOnClick} title="Ajouter une mission spécifique">
                Ajouter une mission spécifique
              </AjouterButton>
            </Grid>
          )}
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ width: '10%' }}>
                      Code domaine
                    </TableCell>
                    <TableCell align="center" sx={{ width: '15%' }}>
                      Libellé domaine
                    </TableCell>
                    <TableCell align="center" sx={{ width: '60%' }}>
                      Postes préjudice
                    </TableCell>
                    <TableCell align="center" sx={{ width: '10%' }}>
                      Statut
                    </TableCell>
                    {fields.length !== initialSize && (
                      <TableCell sx={{ width: '5%' }} align="center">
                        &nbsp;
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                {fields.length > 0 ? (
                  <TableBody>
                    {fields.map((item, index) => {
                      return (
                        <TableRow key={`${item.id}`}>
                          <TableCell sx={{ borderBottom: 'none' }} component="th" scope="row">
                            <Controller
                              name={`${fieldName}.${index}.code`}
                              control={control}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <EditTextField
                                  fullWidth
                                  value={value}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  fieldError={error}
                                  readOnly={readOnly || isAlreadyRegistered(index)}
                                  label="Code domaine"
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ borderBottom: 'none' }} component="th" scope="row">
                            <Controller
                              name={`${fieldName}.${index}.libelle`}
                              control={control}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <EditTextField
                                  fullWidth
                                  value={value}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  fieldError={error}
                                  readOnly={readOnly || isAlreadyRegistered(index)}
                                  label="Libelle domaine"
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ borderBottom: 'none' }} component="th" scope="row">
                            <Controller
                              name={`${fieldName}.${index}.dommages`}
                              control={control}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <SelectInputReferentiel
                                  readOnly={readOnly || isAlreadyRegistered(index)}
                                  id="postes-prejudice"
                                  label="Postes préjudice"
                                  value={value}
                                  onBlur={onBlur}
                                  multiple
                                  onChangeMultiple={onChange}
                                  referentielName="dommagePredefini"
                                  fullWidth
                                  fieldError={error}
                                  dataFormatter={(code, label) => {
                                    return `${label}`
                                  }}
                                  withColors
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ borderBottom: 'none' }} component="th" scope="row">
                            <Controller
                              name={`${fieldName}.${index}.statutLabel`}
                              control={control}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <SelectInput
                                  fullWidth
                                  id={`${fieldName}.${index}.statutLabel`}
                                  label="Statut"
                                  value={value}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  options={STATUT_TYPES}
                                  fieldError={error}
                                  readOnly={readOnly}
                                />
                              )}
                            />
                          </TableCell>
                          {!(readOnly || isAlreadyRegistered(index)) && (
                            <TableCell sx={{ borderBottom: 'none' }} component="th" scope="row">
                              <SupprimerIconButton onClick={removeOnClick(index)} />
                            </TableCell>
                          )}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                ) : (
                  <NoRowsOverlay />
                )}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

import React from 'react'
import {
  capitalize,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { AnnulerButton, CommentaireIconButton, NoRowsOverlay, ReadOnlyTextField } from 'plateforme/components'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import { formatDateFR, formatDateTimeFR } from 'plateforme/services/dates.services'
import Facture from 'admin/store/types/factureAdmin'
import MissionAdmin from 'admin/store/types/missionAdmin'
import ReadOnlyDatePicker from 'plateforme/components/inputs/ReadOnlyDatePicker'

type FactureMissionCardProps = {
  mission: MissionAdmin
}

export default function FactureMissionEntrepriseCard({ mission }: FactureMissionCardProps) {
  const totalFactures = mission?.factures?.length ?? 0

  const { confirm, confirmDialog, closeConfirmDialog } = useConfirmDialog()

  const showCommentaire = (facture: Facture) => {
    confirm({
      title: 'Commentaire',
      confirmMsg: facture.commentaireDemandeModification,
      withForm: true,
      form: (
        <Grid container paddingTop={0}>
          <Grid justifyContent="flex-end" container item xs={12} columnSpacing={2}>
            <Grid item>
              <AnnulerButton onClick={() => closeConfirmDialog()}>Fermer</AnnulerButton>
            </Grid>
          </Grid>
        </Grid>
      ),
    })
  }

  const montantHonoraire = (value?: number) => (
    <Stack direction="row" margin={0} padding={0} spacing={0}>
      <Typography variant="body2" color="readOnly.main" minWidth={82}>
        {'Honoraires : '}
      </Typography>
      <Typography variant="body2" color="text.primary">
        {`${value?.toFixed(2)} EUR`}
      </Typography>
    </Stack>
  )

  const montantFrais = (value?: number) =>
    value !== undefined ? (
      <Stack direction="row" margin={0} padding={0} spacing={0}>
        <Typography variant="body2" color="readOnly.main" minWidth={42}>
          {'Frais : '}
        </Typography>
        <Typography variant="body2" color="text.primary">
          {`${value?.toFixed(2)} EUR`}
        </Typography>
      </Stack>
    ) : (
      ''
    )

  return (
    <>
      {confirmDialog}
      <Card>
        <CardHeader
          title={`Mission ${mission.refMissionEntreprise ?? mission.code} ${
            mission.refMissionMedecin ? ` / ${mission.refMissionMedecin}` : ''
          } ${mission.medecin?.libelle ? ` - ${mission.medecin.libelle}` : ''}`}
        />
        <CardContent>
          <Stack spacing={2}>
            <Grid container paddingBottom={3}>
              <Grid item xs={6}>
                <ReadOnlyDatePicker
                  InputProps={{
                    id: `id-date-acceptation-mission`,
                    fullWidth: true,
                  }}
                  value={mission.dateReponseMedecin ?? null}
                  label="Date d'acceptation de la mission"
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyDatePicker
                  InputProps={{
                    id: `id-date-cloture-mission`,
                    fullWidth: true,
                  }}
                  value={mission.dateValidationMedecin ?? null}
                  label="Date de validation de la mission"
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyTextField
                  fullWidth
                  id="id-ref-mission"
                  value={mission.refMissionEntreprise}
                  label="Référence mission entreprise"
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyTextField
                  fullWidth
                  id="id-type-mission"
                  value={mission.refMissionMedecin}
                  label="Référence mission médecin"
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack spacing={2}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date facture</TableCell>
                    <TableCell>Numéro facture</TableCell>
                    <TableCell>Montant HT</TableCell>
                    <TableCell>Montant TVA</TableCell>
                    <TableCell>Montant TTC</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell sx={{ width: 100 }} />
                  </TableRow>
                </TableHead>
                {totalFactures > 0 ? (
                  <TableBody>
                    {mission?.factures?.map((facture) => (
                      <TableRow key={facture.code}>
                        <TableCell component="th" scope="row">
                          {formatDateFR(facture.dateFacture)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {facture.numeroFacture}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {montantHonoraire(facture.montantHonoraires?.montantHT)}
                          {montantFrais(facture.montantFrais?.montantHT)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {montantHonoraire(facture.montantHonoraires?.montantTVA)}
                          {montantFrais(facture.montantFrais?.montantTVA)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {montantHonoraire(facture.montantHonoraires?.montantTTC)}
                          {montantFrais(facture.montantFrais?.montantTTC)}
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{ maxWidth: '200px' }}>
                          {`${capitalize(facture.labelStatut ?? '')} le ${formatDateTimeFR(
                            facture.dateCreation,
                            'à'
                          )} `}
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{ width: '100px' }}>
                          <Grid direction="row" justifyContent="center" container>
                            {facture?.commentaireDemandeModification && (
                              <Grid item>
                                <CommentaireIconButton onClick={() => showCommentaire(facture)} />
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <NoRowsOverlay />
                )}
              </Table>
            </TableContainer>
          </Stack>
        </CardContent>
      </Card>
    </>
  )
}

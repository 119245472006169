import React from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useSelector } from 'react-redux'
import { Stack } from '@mui/material'
import { FallbackError } from 'plateforme/components'
import NavigationPart from 'plateforme/parts/NavigationPart'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import { useGetRechercheDossierQuery } from 'assureur/store/apis/dossierAssureurApi'
import RechercheDossierEntreprisePart from 'assureur/parts/rechercheDossier/RechercheDossierEntreprisePart'
import ListeDossierEntreprisePart from 'assureur/parts/rechercheDossier/ListeDossierEntreprisePart'
import { getDossierCriteria } from 'assureur/store/slices/searchCriteriaSlice/searchCriteriaEntrepriseSlice'
import { useGetProfilEntrepriseQuery } from 'assureur/store/apis/profilEntrepriseApi'
import { getDossierSort } from 'assureur/store/slices/sortSlice/sortSlice'
import useErrorSnackBar from 'plateforme/hooks/useErrorSnackBar'

export default function RechercheDossierEntreprisePage() {
  const { data: utilisateur, isError: isErrorUser, error: errorUser } = useGetUtilisateurActifQuery()
  const { data: profilEntreprise, isError: isErrorProfil, error: errorProfil } = useGetProfilEntrepriseQuery()
  const handleError = useErrorHandler()

  // handle error of useGetUtilisateurActifQuery:
  if (isErrorUser) {
    handleError({
      errorApi: errorUser,
      title: 'Erreur chargement des données utilisateur actif',
      api: 'GetUtilisateurActif',
    } as FallbackError)
  }
  // handle error of useGetProfilEntrepriseQuery:
  if (isErrorProfil) {
    handleError({
      errorApi: errorProfil,
      title: 'Erreur chargement des données profil',
      api: 'getProfilEntreprise',
    } as FallbackError)
  }

  // get default values of filter and sort params from slice
  const defaultDossierCriteria = useSelector(getDossierCriteria)
  const defaultDossierSort = useSelector(getDossierSort)

  const {
    combinedData,
    exportParams,
    readMore,
    remoteTotal,
    search,
    initSort,
    sortBy,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetRechercheDossierQuery, {
    defaultCriteria: defaultDossierCriteria,
    defaultSort: defaultDossierSort,
  })

  useErrorSnackBar(error, isError, 'La Recherche des dossiers a échoué')

  return (
    <Stack>
      <NavigationPart label="Liste des dossiers " />
      <RechercheDossierEntreprisePart
        search={search}
        searchError={error}
        profilEntreprise={profilEntreprise}
        profilUtilisateur={utilisateur?.profilActif}
      />
      <ListeDossierEntreprisePart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        dossiers={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        isSuccess={isSuccess}
        exportParams={exportParams}
      />
    </Stack>
  )
}

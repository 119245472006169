import * as React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { AreaLoading, ButtonsStack, AjouterButton, ModifierButton, SupprimerButton } from 'plateforme/components'
import { StatutsHabilitation } from 'plateforme/store/types/habilitation'

import { dateCompare, formatDateFR } from 'plateforme/services/dates.services'
import { useLazyGetListeHabilitationsEntrepriseQuery } from 'assureur/store/apis/habilitationEntrepriseApi'
import HabilitationEntreprise, { top100HabilitationRequest } from 'assureur/store/types/habilitationEntreprise'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import { habilitationModifiable } from 'assureur/services/rolesEntreprise.services'
import { LoadingProps } from 'plateforme/services/utils'
import MedecinEntreprise from 'assureur/store/types/medecinEntreprise'
import { getGroupeProfilSelectionne } from 'plateforme/store/slices/profilSlice'
import { useSelector } from 'react-redux'
import { useGetEntreprisesGroupeQuery } from 'assureur/store/apis/profilEntrepriseApi'
import useFetchByGroupe from 'plateforme/hooks/fetchByGroupe'
import AjoutHabilitationMedecinGroupeForm, { EntreprisesGroupe } from './AjoutHabilitationMedecinGroupeForm'
import CloturerHabilitationMedecinGroupeForm from './CloturerHabilitationMedecinGroupeForm'
import ModifierHabilitationMedecinGroupeForm from './ModifierHabilitationMedecinGroupeForm'

/* Page de la fiche médecin tableau habilitations déclarées */
export interface HabilitationsMedecinPartProps {
  medecin?: MedecinEntreprise
}

export interface HabilitationsMedecinPartLoadedProps {
  medecin: MedecinEntreprise
}

export default function HabilitationsMedecinGroupePart({
  medecin,
  loading,
}: HabilitationsMedecinPartProps & LoadingProps) {
  if (loading || medecin === undefined) {
    return <AreaLoading height={551} />
  }
  return <HabilitationsMedecinGroupeLoadedPart medecin={medecin} />
}

function HabilitationsMedecinGroupeLoadedPart({ medecin }: HabilitationsMedecinPartLoadedProps) {
  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()

  const headerValues = useSelector(getGroupeProfilSelectionne)
  const memoizedHeaderValues = React.useMemo(() => headerValues ?? [], [headerValues])

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  // get entreprises groupe :
  const { data: entreprisesGroupe } = useGetEntreprisesGroupeQuery()

  const entreprises = React.useMemo(
    () =>
      entreprisesGroupe
        ?.filter((entreprise) =>
          memoizedHeaderValues.some((header) => entreprise.code && header.includes(entreprise.code.slice(2)))
        )
        ?.map((entreprise) => ({
          libelle: entreprise.libelle,
          code: entreprise.code,
          headerValue: memoizedHeaderValues.find(
            (header) => entreprise.code && header.includes(entreprise.code.slice(2))
          ),
          intervenantOccasionnelParametrage: entreprise.parametrage?.intervenantOccasionnel,
          expertiseSurPieceParametrage: entreprise.parametrage?.expertiseSurPiece,
        })),
    [entreprisesGroupe, memoizedHeaderValues]
  )

  // get the user connected :
  const { data: utilisateur } = useGetUtilisateurActifQuery()
  const profilActif = utilisateur?.profilActif

  const {
    items: habilitations,
    isLoading,
    refetch: refetchHabilitations,
  } = useFetchByGroupe(useLazyGetListeHabilitationsEntrepriseQuery, {
    ...top100HabilitationRequest(medecin.code),
    _timestamp: Date.now(),
  })

  if (isLoading) {
    return <AreaLoading height={551} />
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const habilitationsEncours = habilitations
    ? habilitations
        ?.filter((item: HabilitationEntreprise) =>
          [StatutsHabilitation.ACTIVE, StatutsHabilitation.ACTIVE_AU_FUTUR].includes(
            item?.statut as StatutsHabilitation
          )
        )
        ?.map((habilitation: HabilitationEntreprise) => ({
          ...habilitation,
          headerValue: memoizedHeaderValues.find(
            // note : attention il faut utiliser le codeEntreprise et pas le code
            (header) => habilitation.codeEntreprise && header.includes(habilitation.codeEntreprise.slice(2))
          ),
        }))
    : []

  const total = habilitations?.length ?? 0

  const hasHabilitationActive = habilitationsEncours?.length > 0

  const getSpecifites = (intervenantOccasionnel?: boolean, expertiseSurPiece?: boolean) => {
    return [intervenantOccasionnel && 'Intervenant occasionnel', expertiseSurPiece && 'Expertise sur pièces']
      .filter((item) => !!item)
      .join(' / ')
  }

  const getPeriode = (statut?: StatutsHabilitation, dateDebut?: Date, dateFin?: Date) => {
    switch (statut) {
      case StatutsHabilitation.ACTIVE:
        return `Active depuis le ${formatDateFR(dateDebut)} ${dateFin ? `jusqu'au ${formatDateFR(dateFin)}` : ''}`

      case StatutsHabilitation.ACTIVE_AU_FUTUR:
        return `Active à partir du ${formatDateFR(dateDebut)} ${dateFin ? `jusqu'au ${formatDateFR(dateFin)}` : ''}`

      case StatutsHabilitation.INACTIVE:
        return `Du ${formatDateFR(dateDebut)} ${dateFin ? `au ${formatDateFR(dateFin)}` : ''}`

      default:
        return ''
    }
  }

  return (
    <Card>
      {confirmDialog}
      <CardHeader title="Habilitations déclarées pour ce médecin" />
      <CardContent>
        {habilitationModifiable(profilActif) && (
          <ButtonsStack withGutter>
            {hasHabilitationActive ? (
              <>
                <ModifierButton
                  onClick={() =>
                    confirm({
                      maxWidth: 'lg',
                      fullWidth: true,
                      withForm: true,
                      form: (
                        <ModifierHabilitationMedecinGroupeForm
                          medecin={medecin}
                          habilitations={habilitationsEncours}
                          onClose={closeConfirmDialog}
                          refetchHabilitations={refetchHabilitations}
                        />
                      ),
                      confirmMsg: 'Êtes-vous sûr de vouloir modifier les habilitations en cours ?',
                      title: 'Modifier les habilitations en cours',
                    })
                  }
                >
                  Modifier les habilitations en cours
                </ModifierButton>
                <SupprimerButton
                  onClick={() =>
                    confirm({
                      maxWidth: 'lg',
                      fullWidth: true,
                      withForm: true,
                      variant: 'delete',
                      form: (
                        <CloturerHabilitationMedecinGroupeForm
                          medecin={medecin}
                          habilitations={habilitationsEncours}
                          onClose={closeConfirmDialog}
                          refetchHabilitations={refetchHabilitations}
                        />
                      ),
                      confirmMsg: 'Êtes-vous sûr de vouloir clôturer les habilitations en cours ?',
                      title: 'Clôturer les habilitations en cours',
                    })
                  }
                >
                  Clôturer les habilitations en cours
                </SupprimerButton>
              </>
            ) : (
              <AjouterButton
                onClick={() =>
                  confirm({
                    maxWidth: 'lg',
                    fullWidth: true,
                    withForm: true,
                    form: (
                      <AjoutHabilitationMedecinGroupeForm
                        medecin={medecin}
                        entreprises={entreprises as EntreprisesGroupe[]}
                        onClose={closeConfirmDialog}
                        refetchHabilitations={refetchHabilitations}
                      />
                    ),
                    confirmMsg: 'Êtes-vous sûr de vouloir habiliter le médecin en cours ?',
                    title: 'Ajouter une habilitation',
                  })
                }
              >
                Ajouter une habilitation
              </AjouterButton>
            )}
          </ButtonsStack>
        )}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Entreprise</TableCell>
                <TableCell>N°. Habilitation</TableCell>
                <TableCell>Spécificités</TableCell>
                <TableCell>Période d&apos;habilitation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(habilitations as HabilitationEntreprise[])
                ?.sort((a, b) => {
                  // D'abord, comparer par dateDebut
                  const debutComparison = dateCompare(a.dateDebut, b.dateDebut)
                  // Si les dateDebuts sont identiques, alors comparer par dateFin
                  if (debutComparison === 0) {
                    return dateCompare(a.dateFin, b.dateFin)
                  }
                  // Sinon, retourner le résultat de la comparaison de dateDebut
                  return debutComparison
                })
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: HabilitationEntreprise) => (
                  <TableRow key={row?.id}>
                    <TableCell sx={{ width: '20%' }}>{row?.libelleEntreprise}</TableCell>
                    <TableCell sx={{ width: '20%' }}>{row?.numeroHabilitation ?? row?.code}</TableCell>
                    <TableCell sx={{ width: '25%' }}>
                      {getSpecifites(row?.intervenantOccasionnel, row?.expertiseSurPiece)}
                    </TableCell>
                    <TableCell sx={{ width: '35%' }}>{getPeriode(row?.statut, row?.dateDebut, row?.dateFin)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 50]}
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  )
}

import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { ReferenceDossier } from './dossierEntreprise'
import { ReferenceMission } from './missionEntreprise'

export default interface FactureEntreprise {
  code: string
  numeroFacture?: string
  dossier?: ReferenceDossier
  mission?: ReferenceMission
  dateFacture?: Date
  montantHonoraires?: Montant
  montantFrais?: Montant
  statut: StatutFacture
  labelStatut?: string
  commentaireDemandeModification?: string
  document: DocumentTeleverse
  dateCreation?: Date
  dateAcceptation?: Date
  dateAnnulation?: Date
  dateDemandeModification?: Date
  dateMiseAJour?: Date
}

export type Montant = {
  montantHT?: number
  montantTTC?: number
  montantTVA?: number
  tauxTVA?: number
}

export enum StatutFacture {
  DEPOSEE = 'DEPOSEE',
  ANNULEE = 'ANNULEE',
  ACCEPTEE = 'ACCEPTEE',
  DEMANDE_MODIFICATION = 'DEMANDE_MODIFICATION',
}

export interface FactureFilter {
  statuts?: StatutFacture[]
  dateDebut?: string
  dateFin?: string
  codeMedecin?: string
  labelMedecin?: string
  affectationCentrale?: 0 | 1
  service?: string
  gestionnaire?: string
}

export enum FactureSortType {
  MEDECIN_SORT = 'medecin',
  DATE_FACTURE_SORT = 'date_facture',
  REFERENCE_MISSION_SORT = 'reference_mission',
  NUMERO_FACTURE_SORT = 'numero_facture',
  STATUT_SORT = 'statut',
}

export enum SuiviFactureEntrepriseAction {
  ACCEPTER = 'ACCEPTER',
  DEMANDER_MODIFICATION = 'DEMANDER_MODIFICATION',
}

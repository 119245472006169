import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from '@mui/material'
import { ErrorField } from 'plateforme/components'
import { ErrorMessage } from '@hookform/error-message'
import { Controller, useFormContext } from 'react-hook-form'

interface ActivationEvenementPartProps {
  readOnly?: boolean
}

export default function ActivationEvenementPart({ readOnly }: ActivationEvenementPartProps) {
  const { control, formState, clearErrors, setValue } = useFormContext()

  const naturesEvenementError = 'parametrage.naturesEvenements._error'
  const contactsError = 'parametrage.contacts._error'
  const typesRapportConseilDefError = 'parametrage.typesRapportConseilDef._error'
  const typesRapportConseilProError = 'parametrage.typesRapportConseilPro._error'

  function clearNaturesEvenementError() {
    clearErrors(naturesEvenementError)
    clearErrors(contactsError)
    clearErrors(typesRapportConseilDefError)
    clearErrors(typesRapportConseilProError)
    setValue(naturesEvenementError, undefined, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const formatErrorMessage = (message: string) => {
    if (message === 'Doit être renseigné') {
      return `Au moins une nature d'événement doit être renseignée`
    }
    return `Natures d'événement : ${message}`
  }

  return (
    <Card>
      <CardHeader title="Activation des natures d'événement spécifiques" />
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Controller
              name="parametrage.naturesEvenements.BIRC"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="naturesEvenements.BIRC"
                    value={value}
                    control={
                      <Switch
                        disabled={readOnly}
                        checked={value}
                        onChange={(e) => {
                          clearNaturesEvenementError()
                          onChange(e)
                        }}
                        onBlur={onBlur}
                      />
                    }
                    label="Badinter IRCA"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="parametrage.naturesEvenements.PREV"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="naturesEvenements.PREV"
                    value={value}
                    control={
                      <Switch
                        onChange={(e) => {
                          clearNaturesEvenementError()
                          onChange(e)
                        }}
                        disabled={readOnly}
                        onBlur={onBlur}
                        checked={value}
                      />
                    }
                    label="Prévoyance"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="parametrage.naturesEvenements.BNIR"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="naturesEvenements.BNIR"
                    value={value}
                    control={
                      <Switch
                        disabled={readOnly}
                        checked={value}
                        onChange={(e) => {
                          clearNaturesEvenementError()
                          onChange(e)
                        }}
                        onBlur={onBlur}
                      />
                    }
                    label="Badinter non IRCA"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="parametrage.naturesEvenements.ACCV"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="naturesEvenements.ACCV"
                    value={value}
                    control={
                      <Switch
                        onChange={(e) => {
                          clearNaturesEvenementError()
                          onChange(e)
                        }}
                        disabled={readOnly}
                        onBlur={onBlur}
                        checked={value}
                      />
                    }
                    label="Accident de la vie"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="parametrage.naturesEvenements.RCGE"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="naturesEvenements.RCGE"
                    value={value}
                    control={
                      <Switch
                        disabled={readOnly}
                        checked={value}
                        onChange={(e) => {
                          clearNaturesEvenementError()
                          onChange(e)
                        }}
                        onBlur={onBlur}
                      />
                    }
                    label="RC générale"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="parametrage.naturesEvenements.FAUT"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="naturesEvenements.FAUT"
                    value={value}
                    control={
                      <Switch
                        onChange={(e) => {
                          clearNaturesEvenementError()
                          onChange(e)
                        }}
                        disabled={readOnly}
                        onBlur={onBlur}
                        checked={value}
                      />
                    }
                    label="Faute inexcusable"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="parametrage.naturesEvenements.RCMD"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="naturesEvenements.RCMD"
                    value={value}
                    control={
                      <Switch
                        onChange={(e) => {
                          clearNaturesEvenementError()
                          onChange(e)
                        }}
                        disabled={readOnly}
                        onBlur={onBlur}
                        checked={value}
                      />
                    }
                    label="RC médicale"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="parametrage.naturesEvenements.ASSC"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="naturesEvenements.ASSC"
                    value={value}
                    control={
                      <Switch
                        onChange={(e) => {
                          clearNaturesEvenementError()
                          onChange(e)
                        }}
                        disabled={readOnly}
                        onBlur={onBlur}
                        checked={value}
                      />
                    }
                    label="Assurance du conducteur"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="parametrage.naturesEvenements.AUTR"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="naturesEvenements.AUTR"
                    value={value}
                    control={
                      <Switch
                        onChange={(e) => {
                          clearNaturesEvenementError()
                          onChange(e)
                        }}
                        disabled={readOnly}
                        onBlur={onBlur}
                        checked={value}
                      />
                    }
                    label="Autre"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          {formState.errors && Object.keys(formState.errors).length !== 0 && (
            <Grid item xs={12} color="error.main">
              <ErrorMessage
                name={naturesEvenementError}
                errors={formState.errors}
                render={({ message }) => <ErrorField message={formatErrorMessage(message)} />}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

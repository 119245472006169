import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryGlobal } from 'plateforme/security/keycloak'
import PageRecherche, { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import { OPTION_GROUPE } from 'plateforme/constantes'
import { StatutsHabilitation } from 'plateforme/store/types/habilitation'
import { StatutMedecin } from 'plateforme/services/medecin.services'
import MedecinEntreprise, {
  MedecinPlateformeEntreprise,
  MedecinEntrepriseFilter,
  MedecinEntrepriseRequest,
  MedecinEntrepriseSortType,
} from '../types/medecinEntreprise'
import HabilitationEntreprise from '../types/habilitationEntreprise'

export interface MedecinPartenaireFilter {
  statutsMedecin?: StatutMedecin[]
  statutsHabilitation?: StatutsHabilitation[]
}

export const medecinEntrepriseApi = createApi({
  reducerPath: 'medecinEntrepriseApi',
  baseQuery: baseQueryGlobal,
  tagTypes: ['MedecinPartenaire', 'MedecinEntreprise', 'MedecinHabilitable'],
  endpoints: (builder) => ({
    getMedecinByCode: builder.query<MedecinEntreprise, MedecinEntrepriseRequest>({
      query: ({ codeMedecin }) => `entreprise/medecin/${codeMedecin}`,
      providesTags: (result, error, { codeMedecin }) => [{ type: 'MedecinEntreprise', id: codeMedecin }],
    }),
    getListeMedecinsEntreprise: builder.query<
      PageRecherche<MedecinPlateformeEntreprise>,
      PageRechercheRequest<MedecinEntrepriseFilter, MedecinEntrepriseSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal, headerGroupe }) => ({
        url: 'entreprise/medecin',
        extraOptions: !headerGroupe || OPTION_GROUPE,
        headers: headerGroupe !== undefined ? { 'X-Profile-Id': headerGroupe } : undefined,
        params: {
          ...filter,
          ...sort,
          perPage,
          page,
          onlyTotal,
        },
      }),
    }),
    getMedecinsPartenaire: builder.query<HabilitationEntreprise[], MedecinPartenaireFilter>({
      query: ({ statutsMedecin, statutsHabilitation }) => ({
        url: 'entreprise/medecin-partenaire',
        params: {
          statutsMedecin,
          statutsHabilitation,
        },
      }),
      providesTags: ['MedecinPartenaire'],
    }),
    getMedecinsPlateforme: builder.query<MedecinPlateformeEntreprise[], unknown>({
      query: () => 'entreprise/medecin-plateforme',
      providesTags: ['MedecinEntreprise'],
    }),
    getMedecinsHabilitables: builder.query<MedecinEntreprise[], unknown>({
      query: ({ headerGroupe }) => ({
        url: 'entreprise/medecin-habilitable',
        extraOptions: !headerGroupe || OPTION_GROUPE,
        headers: headerGroupe !== undefined ? { 'X-Profile-Id': headerGroupe } : undefined,
      }),
      providesTags: ['MedecinHabilitable'],
    }),
  }),
})

export const {
  useGetListeMedecinsEntrepriseQuery,
  useLazyGetListeMedecinsEntrepriseQuery,
  useGetMedecinByCodeQuery,
  useGetMedecinsPartenaireQuery,
  useGetMedecinsPlateformeQuery,
  useGetMedecinsHabilitablesQuery,
  useLazyGetMedecinsHabilitablesQuery,
} = medecinEntrepriseApi

export const useGetListeMedecinsEntrepriseHabilitableQuery = ({
  filter,
  perPage,
  page,
  sort,
  onlyTotal,
}: PageRechercheRequest<MedecinEntrepriseFilter, MedecinEntrepriseSortType>) =>
  useGetListeMedecinsEntrepriseQuery({
    perPage,
    page,
    sort,
    onlyTotal,
    filter: {
      ...filter,
      habilitable: 1,
    },
  })

import React from 'react'
import { Stack } from '@mui/material'
import NavigationPart from 'plateforme/parts/NavigationPart'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'
import { useGetListeMessagesQuery } from 'admin/store/apis/notificationsAdminApi'
import { useSelector } from 'react-redux'
import { getNotificationCriteria } from 'admin/store/slices/searchCriteriaSlice/searchCriteriaAdminSlice'
import { NotificationAdminFilter } from 'admin/store/types/notificationsAdmin'
import RechercheNotificationsMessageAdminPart from 'admin/part/RechercheNotificationsMessageAdminPart/RechercheNotificationsMessageAdminPart'
import ListeNotificationsMessageAdminPart from 'admin/part/RechercheNotificationsMessageAdminPart/ListeNotificationsMessageAdminPart'
import { getNotificationMessageSort } from 'admin/store/slices/sortSlice/sortSlice'
import useErrorSnackBar from 'plateforme/hooks/useErrorSnackBar'

export default function RechercheNotificationsMessagesAdminPage() {
  // get default values of filter and sort params from slice
  const defaultNotificationCriteria = useSelector(getNotificationCriteria) as NotificationAdminFilter
  const defaultNotificationSort = useSelector(getNotificationMessageSort)
  const {
    combinedData,
    readMore,
    remoteTotal,
    search,
    initSort,
    sortBy,
    isLoading: listeNotificationLoading,
    isFetching,
    isError: isErrorListeNotifications,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetListeMessagesQuery, {
    defaultCriteria: defaultNotificationCriteria,
    defaultSort: defaultNotificationSort,
  })

  const isLoading = listeNotificationLoading

  useErrorSnackBar(error, isErrorListeNotifications, 'La recherche des messages médecins a échoué')

  return (
    <Stack>
      <NavigationPart label="Liste des messages médecins vers les entreprises" />
      <RechercheNotificationsMessageAdminPart search={search} searchError={error} />
      <ListeNotificationsMessageAdminPart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        combinedData={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isErrorListeNotifications}
        isSuccess={isSuccess}
      />
    </Stack>
  )
}

import { formatDateISO } from 'plateforme/services/dates.services'
import {
  estimationDataToUndefined,
  isRapportConseilNotEmpty,
  objectEmptyToUndefined,
  trimToUndefined,
  valueToUndefined,
} from 'plateforme/services/utils'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import { ReferentielDommage } from 'plateforme/store/types/referentiel'
import { isRapportConclusionProvisoire } from 'plateforme/services/rapport.services'
import {
  ReferenceParametrageEntreprise,
  TypeTiercePersonneDefinitivePeriodeSansFin,
} from 'plateforme/store/types/profilEntreprise'
import RapportConclusionMedecin, {
  Dommage,
  Dommages,
  LesionRequest,
  LesionSequelleRequest,
  ModeDommage,
  Periode,
  PeriodeRequest,
  SauvegarderConclusionRequest,
  TiercePersonne,
} from 'medecin/store/types/rapportConclusionMedecin'
import {
  DommageData,
  EstimationData,
  FicheConclusionData,
  PeriodeData,
  PlageDeDateData,
  RapportConseilData,
  TiercePersonneData,
} from './FicheRapportMedecinForm'

export const formatPeriode = (periode: Periode): PeriodeRequest => {
  return {
    au: periode?.au !== undefined ? formatDateISO(periode.au) : undefined,
    du: periode?.du !== undefined ? formatDateISO(periode.du) : undefined,
  }
}

// NOTE : conversion des dommages pour la requete d'envoi
const convertToDommage = (
  rapportConseilActif: boolean,
  typeRapport: TypeRapport,
  dommage?: DommageData,
  parametrage?: ReferenceParametrageEntreprise
): Dommage | undefined => {
  const nonEvaluableActif = rapportConseilActif && isRapportConclusionProvisoire(typeRapport)
  const isDommageNonEvaluable = nonEvaluableActif && dommage?.nonEvaluable

  switch (dommage?.code) {
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_PROFESSIONNELLES':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SCOLAIRES_UNIVERSITAIRES_OU_DE_FORMATION':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_AGREMENT':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SEXUELLES':
    case 'INSTITUTIONNALISATION':
    case 'PREJUDICE_ETABLISSEMENT':
    case 'PREJUDICE_ANGOISSE_MORT_IMMINENTE':
    case 'FRAIS_FUTURS_A_CARACTERE_CERTAIN_ET_PREVISIBLE':
    case 'FRAIS_APPAREILLAGE':
    case 'AMENAGEMENT_DU_DOMICILE':
    case 'AMENAGEMENT_DU_VEHICULE':
    case 'FRAIS_MEDICAUX_ET_PARAMEDICAUX':
    case 'DOMMAGE_ESTHETIQUE_TEMPORAIRE':
      return {
        codePrejudice: dommage.codePrejudice,
        code: dommage.code,
        label: dommage.label,
        typeDommage: dommage.typeDommage,
        commentaire: trimToUndefined(dommage.commentaire),
        nonEvaluable: nonEvaluableActif ? dommage.nonEvaluable : undefined,
        rapportConseil:
          rapportConseilActif && isRapportConseilNotEmpty(dommage.rapportConseil)
            ? {
                commentaire: trimToUndefined(dommage?.rapportConseil?.commentaire),
              }
            : undefined,
      } as Dommage

    case 'DATES_HOSPITALISATION_IMPUTABLES':
    case 'DATES_ARRETS_DE_TRAVAIL_IMPUTABLES':
      return {
        codePrejudice: dommage.codePrejudice,
        code: dommage.code,
        label: dommage.label,
        typeDommage: dommage.typeDommage,
        commentaire: trimToUndefined(dommage.commentaire),
        plageDeDates: isDommageNonEvaluable
          ? undefined
          : objectEmptyToUndefined({
              mode:
                dommage?.plageDeDates &&
                dommage.plageDeDates.mode === ModeDommage.PERIODE &&
                dommage.plageDeDates.periodes &&
                dommage.plageDeDates.periodes.some((p) => p.du || p.au)
                  ? dommage.plageDeDates.mode
                  : undefined,
              periodes:
                dommage?.plageDeDates &&
                dommage.plageDeDates.mode === ModeDommage.PERIODE &&
                dommage.plageDeDates.periodes &&
                dommage.plageDeDates.periodes.some((p) => p.du || p.au)
                  ? dommage.plageDeDates.periodes.map((periode) => formatPeriode(periode as Periode))
                  : undefined,
            }),
        nonEvaluable: nonEvaluableActif ? dommage.nonEvaluable : undefined,
        rapportConseil:
          rapportConseilActif && isRapportConseilNotEmpty(dommage.rapportConseil)
            ? {
                commentaire: trimToUndefined(dommage?.rapportConseil?.commentaire),
              }
            : undefined,
      } as Dommage
    case 'GENE_TEMPORAIRE_TOTALE':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_1':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_2':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_3':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_4':
      return {
        codePrejudice: dommage.codePrejudice,
        code: dommage.code,
        label: dommage.label,
        typeDommage: dommage.typeDommage,
        plageDeDates: isDommageNonEvaluable
          ? undefined
          : objectEmptyToUndefined({
              mode: dommage?.plageDeDates && dommage.plageDeDates.mode ? dommage.plageDeDates.mode : undefined,
              min:
                dommage?.plageDeDates &&
                dommage.plageDeDates.mode === ModeDommage.FOURCHETTE &&
                dommage.plageDeDates.min
                  ? estimationDataToUndefined(dommage.plageDeDates.min)
                  : undefined,
              max:
                dommage?.plageDeDates &&
                dommage.plageDeDates.mode === ModeDommage.FOURCHETTE &&
                dommage.plageDeDates.max
                  ? estimationDataToUndefined(dommage.plageDeDates.max)
                  : undefined,
              estimation:
                dommage?.plageDeDates &&
                dommage.plageDeDates.mode === ModeDommage.ESTIMATION &&
                dommage.plageDeDates.estimation
                  ? estimationDataToUndefined(dommage.plageDeDates.estimation)
                  : undefined,
              periodes:
                dommage?.plageDeDates &&
                dommage.plageDeDates.mode === ModeDommage.PERIODE &&
                dommage.plageDeDates.periodes &&
                dommage.plageDeDates.periodes.some((p) => p.du || p.au)
                  ? dommage.plageDeDates.periodes.map((periode) => formatPeriode(periode as Periode))
                  : undefined,
            }),
        nonEvaluable: nonEvaluableActif ? dommage.nonEvaluable : undefined,
        rapportConseil:
          rapportConseilActif && isRapportConseilNotEmpty(dommage.rapportConseil)
            ? { commentaire: trimToUndefined(dommage?.rapportConseil?.commentaire) }
            : undefined,
      } as Dommage

    case 'ATTEINTE_A_INTEGRITE_PHYSIQUE_ET_PSYCHIQUE':
      return {
        codePrejudice: dommage.codePrejudice,
        code: dommage.code,
        label: dommage.label,
        typeDommage: dommage.typeDommage,
        valeur: isDommageNonEvaluable ? undefined : valueToUndefined(dommage?.valeur),
        nonEvaluable: nonEvaluableActif ? dommage.nonEvaluable : undefined,
        rapportConseil:
          rapportConseilActif && isRapportConseilNotEmpty(dommage.rapportConseil)
            ? {
                commentaire: trimToUndefined(dommage?.rapportConseil?.commentaire),
                min: dommage?.rapportConseil?.min ? dommage.rapportConseil.min : undefined,
                max: dommage?.rapportConseil?.max ? dommage.rapportConseil.max : undefined,
              }
            : undefined,
      } as Dommage
    case 'DOMMAGE_ESTHETIQUE_PERMANENT':
    case 'SOUFFRANCES_ENDUREES':
      return {
        codePrejudice: dommage.codePrejudice,
        code: dommage.code,
        label: dommage.label,
        typeDommage: dommage.typeDommage,
        valeur: isDommageNonEvaluable ? undefined : valueToUndefined(dommage.valeur),
        nonEvaluable: nonEvaluableActif ? dommage.nonEvaluable : undefined,
        rapportConseil:
          rapportConseilActif && isRapportConseilNotEmpty(dommage.rapportConseil)
            ? {
                commentaire: trimToUndefined(dommage?.rapportConseil?.commentaire),
                min:
                  dommage?.rapportConseil?.valeur instanceof Array && dommage?.rapportConseil?.valeur.length > 0
                    ? valueToUndefined(dommage?.rapportConseil?.valeur[0])
                    : undefined,
                max:
                  dommage?.rapportConseil?.valeur instanceof Array && dommage?.rapportConseil?.valeur.length > 1
                    ? valueToUndefined(dommage?.rapportConseil?.valeur[1])
                    : undefined,
              }
            : undefined,
      } as Dommage

    case 'TIERCE_PERSONNE_DEFINITIVE':
      return {
        codePrejudice: dommage.codePrejudice,
        code: dommage.code,
        label: dommage.label,
        typeDommage: dommage.typeDommage,
        periodes:
          !isDommageNonEvaluable &&
          dommage?.periodes &&
          dommage.periodes.some(
            (tiercePersonne) =>
              tiercePersonne.type ||
              tiercePersonne.nombreHeures ||
              tiercePersonne.par ||
              tiercePersonne.periode?.du ||
              tiercePersonne.periode?.au
          )
            ? dommage.periodes.map((tiercePersonne) => {
                return {
                  type: tiercePersonne.type ?? undefined,
                  nombreHeures: tiercePersonne.nombreHeures ? valueToUndefined(tiercePersonne.nombreHeures) : undefined,
                  par: tiercePersonne.par ?? undefined,
                  periode: formatPeriodeDefinitive(tiercePersonne.periode as Periode, parametrage),
                } as TiercePersonne
              })
            : undefined,
        nonEvaluable: nonEvaluableActif ? dommage.nonEvaluable : undefined,
        rapportConseil:
          rapportConseilActif && isRapportConseilNotEmpty(dommage.rapportConseil)
            ? { commentaire: trimToUndefined(dommage?.rapportConseil?.commentaire) }
            : undefined,
      } as Dommage
    case 'TIERCE_PERSONNE_TEMPORAIRE':
      return {
        codePrejudice: dommage.codePrejudice,
        code: dommage.code,
        label: dommage.label,
        typeDommage: dommage.typeDommage,
        periodes:
          !isDommageNonEvaluable &&
          dommage?.periodes &&
          dommage.periodes.some(
            (tiercePersonne) =>
              tiercePersonne.type ||
              tiercePersonne.nombreHeures ||
              tiercePersonne.par ||
              tiercePersonne.periode?.du ||
              tiercePersonne.periode?.au
          )
            ? dommage.periodes.map((tiercePersonne) => {
                return {
                  type: tiercePersonne.type ?? undefined,
                  nombreHeures: tiercePersonne.nombreHeures ? valueToUndefined(tiercePersonne.nombreHeures) : undefined,
                  par: tiercePersonne.par ?? undefined,
                  periode: formatPeriode(tiercePersonne.periode as Periode),
                } as TiercePersonne
              })
            : undefined,
        nonEvaluable: nonEvaluableActif ? dommage.nonEvaluable : undefined,
        rapportConseil:
          rapportConseilActif && isRapportConseilNotEmpty(dommage.rapportConseil)
            ? { commentaire: trimToUndefined(dommage?.rapportConseil?.commentaire) }
            : undefined,
      } as Dommage

    default:
      return undefined
  }
}

const formatPeriodeDefinitive = (periode: Periode, parametrage?: ReferenceParametrageEntreprise): PeriodeRequest => {
  return {
    du: periode?.du !== undefined ? formatDateISO(periode.du) : undefined,
    au: formatAuPeriodeDefinitive(periode?.au, parametrage),
  }
}

const formatAuPeriodeDefinitive = (au?: Date, parametrage?: ReferenceParametrageEntreprise) => {
  if (parametrage?.tiercePersonneDefinitivePeriodeSansFin === TypeTiercePersonneDefinitivePeriodeSansFin.ACTIF) {
    return undefined
  }

  return au !== undefined ? formatDateISO(au) : undefined
}

export function convertToPlageDeDatesPeriodeForm(dommage?: Dommage) {
  return {
    mode: ModeDommage.PERIODE,
    periodes: (dommage?.plageDeDates?.periodes ?? [undefined]).map((p) => {
      return { du: p?.du ?? null, au: p?.au ?? null } as PeriodeData
    }),
    min: null,
    max: null,
    estimation: null,
  } as PlageDeDateData
}

export function convertToPlageDeDatesForm(dommage?: Dommage) {
  return {
    mode: dommage?.plageDeDates?.mode ?? null,
    periodes: (dommage?.plageDeDates?.periodes ?? [undefined]).map((p) => {
      return { du: p?.du ?? null, au: p?.au ?? null } as PeriodeData
    }),
    min: dommage?.plageDeDates?.min ?? ({ valeur: '', unite: null } as EstimationData),
    max: dommage?.plageDeDates?.max ?? ({ valeur: '', unite: null } as EstimationData),
    estimation: dommage?.plageDeDates?.estimation ?? ({ valeur: '', unite: null } as EstimationData),
  } as PlageDeDateData
}

// NOTE : mapping des valeurs initiales des dommages:
export const convertToDommageForm = (dommage?: Dommage, dommagePredefini?: ReferentielDommage): DommageData => {
  switch (dommagePredefini?.code) {
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_PROFESSIONNELLES':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SCOLAIRES_UNIVERSITAIRES_OU_DE_FORMATION':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_AGREMENT':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SEXUELLES':
    case 'INSTITUTIONNALISATION':
    case 'PREJUDICE_ETABLISSEMENT':
    case 'PREJUDICE_ANGOISSE_MORT_IMMINENTE':
    case 'FRAIS_FUTURS_A_CARACTERE_CERTAIN_ET_PREVISIBLE':
    case 'FRAIS_APPAREILLAGE':
    case 'AMENAGEMENT_DU_DOMICILE':
    case 'AMENAGEMENT_DU_VEHICULE':
    case 'FRAIS_MEDICAUX_ET_PARAMEDICAUX':
    case 'DOMMAGE_ESTHETIQUE_TEMPORAIRE':
      return {
        codePrejudice: dommagePredefini.codePrejudice,
        code: dommagePredefini.code,
        label: dommagePredefini.label,
        typeDommage: dommagePredefini.typeDommage,
        handicapGrave: dommagePredefini?.handicapGrave,
        commentaire: dommage?.commentaire ? dommage.commentaire : '',
        nonEvaluable: !!dommage?.nonEvaluable,
        rapportConseil: {
          commentaire: dommage?.rapportConseil?.commentaire ?? '',
        } as RapportConseilData,
      } as DommageData

    case 'DATES_HOSPITALISATION_IMPUTABLES':
    case 'DATES_ARRETS_DE_TRAVAIL_IMPUTABLES':
      return {
        codePrejudice: dommagePredefini.codePrejudice,
        code: dommagePredefini.code,
        label: dommagePredefini.label,
        typeDommage: dommagePredefini.typeDommage,
        handicapGrave: dommagePredefini?.handicapGrave,
        plageDeDates: convertToPlageDeDatesPeriodeForm(dommage),
        commentaire: dommage?.commentaire ? dommage.commentaire : '',
        nonEvaluable: !!dommage?.nonEvaluable,
        rapportConseil: {
          commentaire: dommage?.rapportConseil?.commentaire ?? '',
        } as RapportConseilData,
      } as DommageData
    case 'GENE_TEMPORAIRE_TOTALE':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_1':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_2':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_3':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_4':
      return {
        codePrejudice: dommagePredefini.codePrejudice,
        code: dommagePredefini.code,
        label: dommagePredefini.label,
        typeDommage: dommagePredefini.typeDommage,
        handicapGrave: dommagePredefini?.handicapGrave,
        plageDeDates: convertToPlageDeDatesForm(dommage),
        nonEvaluable: !!dommage?.nonEvaluable,
        rapportConseil: {
          commentaire: dommage?.rapportConseil?.commentaire ?? '',
        } as RapportConseilData,
      } as DommageData

    case 'ATTEINTE_A_INTEGRITE_PHYSIQUE_ET_PSYCHIQUE':
      return {
        codePrejudice: dommagePredefini.codePrejudice,
        code: dommagePredefini.code,
        label: dommagePredefini.label,
        typeDommage: dommagePredefini.typeDommage,
        handicapGrave: dommagePredefini?.handicapGrave,
        valeur: dommage?.valeur ?? '',
        nonEvaluable: !!dommage?.nonEvaluable,
        rapportConseil: {
          commentaire: dommage?.rapportConseil?.commentaire ?? '',
          min: dommage?.rapportConseil?.min ?? '',
          max: dommage?.rapportConseil?.max ?? '',
        } as RapportConseilData,
      } as DommageData

    case 'DOMMAGE_ESTHETIQUE_PERMANENT':
    case 'SOUFFRANCES_ENDUREES':
      return {
        codePrejudice: dommagePredefini.codePrejudice,
        code: dommagePredefini.code,
        label: dommagePredefini.label,
        typeDommage: dommagePredefini.typeDommage,
        handicapGrave: dommagePredefini?.handicapGrave,
        valeur: dommage?.valeur ?? null,
        nonEvaluable: !!dommage?.nonEvaluable,
        rapportConseil: {
          commentaire: dommage?.rapportConseil?.commentaire ?? '',
          valeur: [dommage?.rapportConseil?.min ?? null, dommage?.rapportConseil?.max ?? null],
        } as RapportConseilData,
      } as DommageData

    case 'TIERCE_PERSONNE_DEFINITIVE':
    case 'TIERCE_PERSONNE_TEMPORAIRE':
      return {
        codePrejudice: dommagePredefini.codePrejudice,
        code: dommagePredefini.code,
        label: dommagePredefini.label,
        typeDommage: dommagePredefini.typeDommage,
        handicapGrave: dommagePredefini?.handicapGrave,
        periodes: (dommage?.periodes ?? [undefined]).map((p) => {
          return {
            type: p?.type ?? null,
            nombreHeures: p?.nombreHeures ?? '',
            par: p?.par ?? null,
            periode: { du: p?.periode?.du ?? null, au: p?.periode?.au ?? null } as PeriodeData,
          } as TiercePersonneData
        }),
        nonEvaluable: !!dommage?.nonEvaluable,
        rapportConseil: {
          commentaire: dommage?.rapportConseil?.commentaire ?? '',
        } as RapportConseilData,
      } as DommageData

    default:
      return {
        codePrejudice: dommagePredefini?.codePrejudice,
        code: dommagePredefini?.code,
        label: dommagePredefini?.label,
        typeDommage: dommagePredefini?.typeDommage,
        handicapGrave: dommagePredefini?.handicapGrave,
        nonEvaluable: !!dommage?.nonEvaluable,
        rapportConseil: {
          commentaire: dommage?.rapportConseil?.commentaire ?? '',
          valeur: dommage?.valeur ?? '',
          min: dommage?.rapportConseil?.min ?? null,
          max: dommage?.rapportConseil?.max ?? null,
        } as RapportConseilData,
      } as DommageData
  }
}

// build de l'objet SauvegarderConclusionRequest
export const prepareSauvegarderConclusionRequestFromRapportConclusionMedecin = (
  codeDossier: string,
  codeMission: string,
  typeRapport: TypeRapport,
  validation: boolean,
  rapportConclusionMedecin: RapportConclusionMedecin
): SauvegarderConclusionRequest => {
  return {
    codeDossier,
    codeMission,
    typeRapport,
    validation,
    nomPersonneAExaminer: trimToUndefined(rapportConclusionMedecin.nomPersonneAExaminer),
    prenomPersonneAExaminer: trimToUndefined(rapportConclusionMedecin.prenomPersonneAExaminer),
    dateNaissancePersonneAExaminer: formatDateISO(rapportConclusionMedecin.dateNaissancePersonneAExaminer),
    lieuSignature: trimToUndefined(rapportConclusionMedecin.lieuSignature),
    dateSignature: formatDateISO(rapportConclusionMedecin.dateSignature),
    consentement: rapportConclusionMedecin.consentement,
    refConsentement: rapportConclusionMedecin.refConsentement,
    signataire: trimToUndefined(rapportConclusionMedecin.signataire),
    commentaireNouvelExamen: trimToUndefined(rapportConclusionMedecin.commentaireNouvelExamen),
    commentaire: trimToUndefined(rapportConclusionMedecin.commentaire),
    dateConsolidationPro: formatDateISO(rapportConclusionMedecin.dateConsolidationPro),
    dateConsolidationDef: formatDateISO(rapportConclusionMedecin.dateConsolidationDef),
    delaiNouvelExamen: trimToUndefined(rapportConclusionMedecin.delaiNouvelExamen),
    dateExamen: formatDateISO(rapportConclusionMedecin.dateExamen),
    lesions: rapportConclusionMedecin.lesions ?? [],
    lesionSequelles:
      rapportConclusionMedecin.lesionSequelles?.map(
        ({ lesion, sequelle }) =>
          ({
            lesion,
            sequelle,
          } as LesionSequelleRequest)
      ) || [],
    dommages: rapportConclusionMedecin.dommages,
    motifCarence: trimToUndefined(rapportConclusionMedecin.motifCarence),
    remissionnementPreconise: !!rapportConclusionMedecin.remissionnementPreconise,
  }
}

export const prepareSauvegarderConclusionRequestFromDataConclusionForm = (
  codeDossier: string,
  codeMission: string,
  typeRapport: TypeRapport,
  validation: boolean,
  rapportConseilActive: boolean,
  dataConclusionForm: FicheConclusionData,
  showHandicapGrave: boolean,
  parametrage?: ReferenceParametrageEntreprise
): SauvegarderConclusionRequest => {
  const dommages = dataConclusionForm.dommages || {}

  return {
    codeDossier,
    codeMission,
    typeRapport,
    validation,
    nomPersonneAExaminer: trimToUndefined(dataConclusionForm.nomPersonneAExaminer),
    prenomPersonneAExaminer: trimToUndefined(dataConclusionForm.prenomPersonneAExaminer),
    dateNaissancePersonneAExaminer: formatDateISO(dataConclusionForm.dateNaissancePersonneAExaminer),
    lieuSignature: trimToUndefined(dataConclusionForm.lieuSignature),
    dateSignature: formatDateISO(dataConclusionForm.dateSignature),
    consentement: dataConclusionForm.consentement,
    refConsentement: trimToUndefined(dataConclusionForm.refConsentement),
    signataire: trimToUndefined(dataConclusionForm.signataire),
    commentaireNouvelExamen: trimToUndefined(dataConclusionForm.commentaireNouvelExamen),
    commentaire: trimToUndefined(dataConclusionForm.commentaire),
    commentaireRapportConseil: rapportConseilActive
      ? trimToUndefined(dataConclusionForm.commentaireRapportConseil)
      : undefined,
    dateConsolidationPro: formatDateISO(dataConclusionForm.dateConsolidationPro),
    dateConsolidationDef: formatDateISO(dataConclusionForm.dateConsolidationDef),
    delaiNouvelExamen: trimToUndefined(dataConclusionForm.delaiNouvelExamen),
    dateExamen: formatDateISO(dataConclusionForm.dateExamen),
    lesions: dataConclusionForm.lesions?.map((lesion: LesionRequest) => trimToUndefined(lesion?.code)) || [],
    lesionSequelles:
      dataConclusionForm.lesionSequelles?.map((lesionSequelle: LesionSequelleRequest) => {
        return {
          lesion:
            typeof lesionSequelle?.lesion === 'string'
              ? lesionSequelle?.lesion
              : trimToUndefined(lesionSequelle?.lesion?.code),
          sequelle:
            typeof lesionSequelle?.sequelle === 'string'
              ? lesionSequelle?.sequelle
              : trimToUndefined(lesionSequelle?.sequelle?.code),
        } as LesionSequelleRequest
      }) || [],
    dommages: Object.fromEntries(
      Object.keys(dommages)
        .filter((code) => dommages[code])
        .map((code) => convertToDommage(rapportConseilActive, typeRapport, dommages[code], parametrage))
        .map((df) => [df?.code, df])
    ) as Dommages,
    motifCarence: trimToUndefined(dataConclusionForm.motifCarence),
    remissionnementPreconise: dataConclusionForm.remissionnementPreconise,
    handicapGrave: !showHandicapGrave ? undefined : dataConclusionForm.handicapGrave,
  }
}

import React from 'react'
import { Alert, Grid } from '@mui/material'
import { InfiniteScrollComponent, ResultatRechercheBarCard } from 'plateforme/components'
import { SortParam, SortOption } from 'plateforme/store/types/pageRecherche'
import EntrepriseAdmin, { ReferentielEntrepriseSortType } from 'admin/store/types/profilEntrepriseAdmin'
import { toTelechargementExportEntrepriseHref } from 'plateforme/App'
import { AuthDownloadAction } from 'plateforme/store/types/utilisateur'
import { getEntrepriseSort, setEntrepriseSort } from 'admin/store/slices/sortSlice/sortSlice'
import EntreprisesReferencesCard from './EntreprisesReferencesCard'

type ListeEntrepriseReferencesPartProps = {
  entreprises: EntrepriseAdmin[]
  total?: number
  readMore: VoidFunction
  sortBy: (param: SortParam<ReferentielEntrepriseSortType>) => void
  initSort: VoidFunction
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  isSuccess: boolean
  exportParams?: string
}

const sortOptions: SortOption<ReferentielEntrepriseSortType>[] = [
  {
    sortType: ReferentielEntrepriseSortType.DATE_INSCRIPTION_SORT,
    label: `Date d'inscription`,
  },
  { sortType: ReferentielEntrepriseSortType.RAISON_SOCIALE_SORT, label: `Raison sociale` },
  { sortType: ReferentielEntrepriseSortType.IDENTIFIANT_PLATEFORME_SORT, label: 'Identifiant plateforme' },
]

export default function ListeEntrepriseReferencesPart({
  entreprises,
  total = 0,
  readMore,
  sortBy,
  initSort,
  isError,
  isLoading,
  isFetching,
  isSuccess,
  exportParams,
}: ListeEntrepriseReferencesPartProps) {
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      sx={{ '& .infinite-scroll-component__outerdiv': { width: '100%' } }}
    >
      <Grid item container xs={12} paddingBottom={2.5}>
        <ResultatRechercheBarCard
          title="Liste des entreprises référencées"
          total={total}
          loading={isLoading || isFetching}
          sortBy={sortBy}
          sortOptions={sortOptions}
          initSort={initSort}
          withSorting
          withExportCSV
          hrefExportCSV={toTelechargementExportEntrepriseHref(exportParams)}
          requestExportCSV={{ action: AuthDownloadAction.TELECHARGER_CSV_ENTREPRISE }}
          setSortSlice={setEntrepriseSort}
          getSortSlice={getEntrepriseSort}
        />
      </Grid>
      {(isError && !isSuccess && !isLoading && !isFetching) || total === 0 ? (
        <Alert severity="info" sx={{ width: '100%' }}>
          Aucune entreprise remontée
        </Alert>
      ) : (
        <InfiniteScrollComponent
          dataLength={entreprises?.length}
          next={readMore}
          hasMore={entreprises.length < (total ?? 0)}
          isLoading={isLoading || isFetching}
          xs={12}
        >
          <Grid container item xs={12}>
            {entreprises?.map((entreprise) => (
              <Grid item xs={12} key={entreprise.code}>
                <EntreprisesReferencesCard entreprise={entreprise} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScrollComponent>
      )}
    </Grid>
  )
}

import React from 'react'
import { Stack } from '@mui/material'
import NavigationPart from 'plateforme/parts/NavigationPart'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'
import { useGetListeFluxTechniquesEntrepriseQuery } from 'assureur/store/apis/fluxTechniqueEntrepriseApi'
import RechercheFluxTechniquesEntreprisePart from 'assureur/parts/rechercheFluxTechnique/RechercheFluxTechniquesEntreprisePart'
import ListeFluxTechniquesEntreprisePart from 'assureur/parts/rechercheFluxTechnique/ListeFluxTechniquesEntreprisePart'
import { useSelector } from 'react-redux'
import { getFluxTechniqueCriteria } from 'assureur/store/slices/searchCriteriaSlice/searchCriteriaEntrepriseSlice'
import { FluxTechniqueEntrepriseFilter } from 'assureur/store/types/fluxTechniqueEntreprise'
import { getFluxTechniquesSort } from 'assureur/store/slices/sortSlice/sortSlice'
import useErrorSnackBar from 'plateforme/hooks/useErrorSnackBar'

export default function RechercheFluxTechniquesEntreprisePage() {
  // get default values of filter and sort params from slice
  const defaultFluxTechniqueCriteria = useSelector(getFluxTechniqueCriteria) as FluxTechniqueEntrepriseFilter
  const defaultFluxTechniqueSort = useSelector(getFluxTechniquesSort)

  const {
    combinedData,
    readMore,
    remoteTotal,
    search,
    initSort,
    sortBy,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetListeFluxTechniquesEntrepriseQuery, {
    perPage: 30,
    defaultCriteria: defaultFluxTechniqueCriteria,
    defaultSort: defaultFluxTechniqueSort,
  })

  useErrorSnackBar(error, isError, 'La Recherche des échanges techniques TransMED a échoué')

  return (
    <Stack>
      <NavigationPart label="Échanges techniques TransMED" />
      <RechercheFluxTechniquesEntreprisePart search={search} searchError={error} />
      <ListeFluxTechniquesEntreprisePart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        combinedData={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        isSuccess={isSuccess}
      />
    </Stack>
  )
}

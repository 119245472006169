import React from 'react'
import { Box, FormControl, FormHelperText, Grid, Stack } from '@mui/material'
import { AjouterIconButton, BluePaper, DividerFormButton, SupprimerIconButton } from 'plateforme/components'
import { Controller, useFormContext, useFieldArray } from 'react-hook-form'
import AutocompleteLesion from 'medecin/components/AutocompleteLesion'
import AutocompleteSequelle from 'medecin/components/AutocompleteSequelle'

interface LesionSequellesFieldArrayProps {
  readOnly?: boolean
}

export default function LesionSequellesFieldArray({ readOnly }: LesionSequellesFieldArrayProps) {
  const { control, getFieldState, formState, clearErrors, resetField } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'lesionSequelles',
  })

  const max = 4
  const tooltip =
    fields.length >= max ? `Le nombre maximum de lésion / séquelle est de ${max}` : 'Ajouter un code lésion / séquelle'

  const { error: groupError } = getFieldState('lesionSequelles', formState)

  function addTooltip(index: number) {
    if (fields.length >= max) {
      return `Le nombre maximum de lésion / séquelle est de ${max}`
    }
    return index === fields.length - 1 ? 'Ajouter un code lésion / séquelle' : ''
  }

  function addDisabled() {
    return fields.length >= max
  }

  function addOnClick() {
    return () => {
      if (fields.length === 0) {
        clearErrors('lesionSequelles')
        resetField('lesionSequelles')
      }
      append({ lesion: null, sequelle: null })
    }
  }

  function removeOnClick(index: number) {
    return () => {
      remove(index)
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <DividerFormButton
          title="Codes lésions / séquelles"
          tooltip={tooltip}
          addAction={addOnClick()}
          disabled={addDisabled()}
          readOnly={readOnly}
        />
      </Grid>
      <Grid item xs={12} marginBottom={3}>
        <>
          {fields.length === 0 ? (
            <BluePaper sx={{ fontStyle: 'italic', paddingTop: 3.5, textAlign: 'center' }}>
              Aucune lésion / séquelle
            </BluePaper>
          ) : (
            <BluePaper>
              <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                {fields.map((item, index) => {
                  return (
                    <li key={item.id}>
                      <Stack direction="row" spacing={3.5} marginBottom={index === fields.length - 1 ? 0 : 2.5}>
                        <Controller
                          name={`lesionSequelles.${index}.lesion`}
                          control={control}
                          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                            <AutocompleteLesion
                              readOnly={readOnly}
                              id="codes-lesion"
                              label="Code lésion"
                              value={value}
                              onBlur={onBlur}
                              onChange={onChange}
                              fullWidth
                              fieldError={error}
                            />
                          )}
                        />
                        <Controller
                          name={`lesionSequelles.${index}.sequelle`}
                          control={control}
                          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                            <AutocompleteSequelle
                              readOnly={readOnly}
                              id="codes-sequelle"
                              label="Code séquelle"
                              value={value}
                              onBlur={onBlur}
                              onChange={onChange}
                              fullWidth
                              withNoSelectionItem
                              fieldError={error}
                            />
                          )}
                        />
                        {!readOnly && (
                          <Box height="60px" paddingTop="12px" marginLeft="8px !important">
                            <SupprimerIconButton onClick={removeOnClick(index)} tooltip="Retirer" />
                          </Box>
                        )}
                        {!readOnly && (
                          <Box height="60px" minWidth="40px" paddingTop="10px" marginLeft="8px !important">
                            {index === fields.length - 1 ? (
                              <AjouterIconButton
                                title="Ajouter"
                                onClick={addOnClick()}
                                disabled={addDisabled()}
                                tooltip={addTooltip(index)}
                              />
                            ) : (
                              ' '
                            )}
                          </Box>
                        )}
                      </Stack>
                    </li>
                  )
                })}
              </ul>
            </BluePaper>
          )}
          {groupError?.message && (
            <FormControl
              disabled={readOnly}
              error={!!groupError}
              variant="standard"
              sx={{ display: 'flex', alignItems: 'flex-start' }}
            >
              <FormHelperText sx={{ marginLeft: '14px' }}>{groupError.message}</FormHelperText>
            </FormControl>
          )}
        </>
      </Grid>
    </>
  )
}

import { Box } from '@mui/material'
import React, { SyntheticEvent } from 'react'

interface EntrepriseLogoProps {
  width: number
  height: number
  codeEntreprise?: string
}

export default function EntrepriseLogo({ width, height, codeEntreprise = 'default' }: EntrepriseLogoProps) {
  return (
    <Box
      sx={{
        width,
        height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <Box
        component="img"
        src={`/extranet/logos/${codeEntreprise}.png`}
        onError={(e: SyntheticEvent) => {
          // si il y a une erreur dans le recuperation de l'image on charge le logo par defaut
          const target = e.target as HTMLImageElement
          target.onerror = null
          target.src = `/extranet/images/default.png`
        }}
        sx={{
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain',
        }}
      />
    </Box>
  )
}

import { formatISO, subWeeks } from 'date-fns'
import { MessageMedecinFilter } from 'medecin/store/types/messageMedecin'

// NOTE : Attention de ne pas mettre l'initialisation de dateCreationDebut au niveau des fonction xxxxxxxFiltre()
const dateDebut = formatISO(subWeeks(new Date(), 1))

// Filtre des dérniers notifications non lues
export const dernieresNotificationsNonLueFiltre = () =>
  ({
    lu: 0,
    typesExclus: ['MEL_MED', 'REL_FAC'],
    dateDebut,
  } as MessageMedecinFilter)

// Filtre des dérniers notifications non lues
export const dernieresNotificationsFiltre = () =>
  ({
    typesExclus: ['MEL_MED', 'REL_FAC'],
    dateDebut,
  } as MessageMedecinFilter)

// Filtre des dérniers notifications messages
export const dernieresNotificationsMessagesFiltre = () =>
  ({ types: ['MEL_MED', 'REL_FAC'], tous: 1, dateDebut } as MessageMedecinFilter)

// Filtre des dérniers notifications non lues par dossier
export const dernieresNotificationsNonLuesParDossierFiltre = (codeDossier: string) =>
  ({
    codeDossier,
    lu: 0,
    typesExclus: ['MEL_MED', 'REL_FAC'],
  } as MessageMedecinFilter)

// Filtre des messages de type message mission
export const messagesMissionsParDossierFiltre = (codeDossier: string) =>
  ({
    codeDossier,
    types: ['MEL_MIS'],
    tous: 1,
  } as MessageMedecinFilter)

export const messagesMissionsNonLusParDossierFiltre = (codeDossier: string, codeMedecin: string) =>
  ({
    codeDossier,
    codeMedecin,
    types: ['MEL_MIS'],
    tous: 0,
    lu: 0,
  } as MessageMedecinFilter)

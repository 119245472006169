import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useErrorHandler } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'
import { Alert, AlertTitle, Box, Stack, Typography, useTheme } from '@mui/material'
import { AnnulerButton, AreaLoading, ButtonsStack, FallbackError, SauvegarderButton } from 'plateforme/components'
import NavigationPart from 'plateforme/parts/NavigationPart'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { errorStatutIllegalNarrowed, trimToUndefined } from 'plateforme/services/utils'
import { StatutMission } from 'plateforme/store/types/mission'
import { useGetReferentielQuery } from 'plateforme/store/apis/referentielApi'
import { determineShowPreconisationHandicapGrave } from 'plateforme/services/mission.services'
import LieuExpertisePart from 'plateforme/parts/DonneesDetailleesParts/LieuExpertisePart'
import { useGetDossierByIdQuery, usePostCreerMissionMutation } from 'assureur/store/apis/dossierAssureurApi'
import ExpertisePart from 'assureur/parts/creationMission/ExpertisePart'
import IdentificationDossierPart from 'assureur/parts/creationMission/IdentificationDossierPart'
import ParametresMissionPart from 'assureur/parts/creationMission/ParametresMissionPart'
import { MissionRequest } from 'assureur/store/types/missionEntreprise'
import ChoixMedecinPart from 'assureur/parts/creationMission/ChoixMedecinPart'
import { getSelectedMedecin } from 'assureur/store/slices/selectedMedecin/selectedMedecinSlice'
import { useGetProfilEntrepriseQuery } from 'assureur/store/apis/profilEntrepriseApi'
import { consultationDossierHref } from 'assureur/EntrepriseApp'
import isMissionPrecedenteValide from 'assureur/services/missionEntreprise.service'
import { useGetMedecinsPartenaireQuery } from 'assureur/store/apis/medecinEntrepriseApi'
import ChoixMissionRDVPart from 'assureur/parts/creationMission/ChoixMissionRDV/ChoixMissionRDVPart'
import {
  getSelectedMissionRDV,
  setSelectedMissionRDV,
} from 'assureur/store/slices/selectedMissionRDV/selectedMissionRDVSlice'
import { TypeLieuExpertise } from 'assureur/store/types/dossierEntreprise'
import { StatutsHabilitation } from 'plateforme/store/types/habilitation'
import { StatutMedecin } from 'plateforme/services/medecin.services'

export default function CreationMissionEntreprisePage() {
  const { codeDossier } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [postCreerMission, { error: errorSauvegarder, isLoading: isSauvegarderLoading }] = usePostCreerMissionMutation()
  const selectedMedecin = useSelector(getSelectedMedecin)
  const selectedMissionRDV = useSelector(getSelectedMissionRDV)
  const navigate = useNavigate()
  const handleError = useErrorHandler()
  const theme = useTheme()
  const dispatch = useDispatch()

  const {
    data: medecins,
    error: medecinsError,
    isError: isMedecinsError,
    isLoading: isMedecinsLoading,
    isFetching: isMedecinsFetching,
  } = useGetMedecinsPartenaireQuery({
    statutsMedecin: [StatutMedecin.ACTIF],
    statutsHabilitation: [StatutsHabilitation.ACTIVE],
  })

  if (codeDossier === undefined) {
    throw new Error('Aucun code dossier défini')
  }

  const {
    data: profilEntreprise,
    isError: isErrorGetProfilEntreprise,
    error: errorGetProfilEntreprise,
    isLoading: loadingProfilEntreprise,
    isFetching: fetchingProfilEntreprise,
  } = useGetProfilEntrepriseQuery()

  const {
    data: referentiel,
    isError: isErrorReferentiel,
    error: errorReferentiel,
    isLoading: isLoadingReferentiel,
    isFetching: isFetchingReferentiel,
  } = useGetReferentielQuery()

  if (codeDossier === undefined) {
    throw new Error('Aucun code dossier défini')
  }

  const { data: dossier, isLoading, isFetching } = useGetDossierByIdQuery({ codeDossier })

  if (isMedecinsError) {
    handleError({
      errorApi: medecinsError,
      title: 'Erreur chargement des médecins éligibles',
      api: 'GetMedecinsPartenaire',
    } as FallbackError)
  }

  if (isErrorGetProfilEntreprise) {
    handleError({
      errorApi: errorGetProfilEntreprise,
      title: 'Erreur chargement des données profil entreprise',
      api: 'GetProfilEntreprise',
    } as FallbackError)
  }

  // handle error of useGetReferentielQuery
  if (isErrorReferentiel) {
    handleError({
      errorApi: errorReferentiel,
      api: 'GetReferentiel',
      title: 'Erreur chargement des données de référentiel',
    } as FallbackError)
  }

  useEffect(() => {
    dispatch(setSelectedMissionRDV(undefined))
  }, [dispatch])

  const missionPrecedente = dossier?.missions?.find((mission) => mission.statut !== StatutMission.REFUSEE)
  const rapportPrecedent = dossier?.missions?.find((mission) => mission.rapport)?.rapport
  // Get code medecin from : mission precedente if checked or selected medecin if not checked

  const showHandicapGrave = determineShowPreconisationHandicapGrave(profilEntreprise, dossier)

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      code: dossier?.code,
      refMissionEntreprise: '',
      refMissionAnterieure: '',
      precisions: '',
      medecinPrecedent:
        (missionPrecedente?.rapport?.remissionnementPreconise &&
          isMissionPrecedenteValide(missionPrecedente, medecins)) ??
        false,
      generationOrdreMission: true,
      handicapGrave: showHandicapGrave ? rapportPrecedent?.handicapGrave ?? false : false,
      rdv: missionPrecedente?.rdv,
    },
  })

  const {
    getValues,
    reset,
    setError,
    watch,
    formState: { isValid },
  } = methods

  // Mapping Errors in form:
  useErrorFormMapper(errorSauvegarder as IQueryErrorResponse, setError, getValues)

  const codeMedecinSelectionne = trimToUndefined(
    watch('medecinPrecedent') ? missionPrecedente?.medecin?.code : selectedMedecin?.code
  )

  const formId = 'form-missionner-medecin'

  const handleAnnuler = () => {
    reset()
    navigate(consultationDossierHref(codeDossier))
  }

  const onSubmit = async () => {
    const formValues = getValues()
    await postCreerMission({
      codeDossier,
      codeMedecin: codeMedecinSelectionne,
      generationOrdreMission: profilEntreprise?.parametrage?.lettreMission ? formValues.generationOrdreMission : false,
      handicapGrave: showHandicapGrave ? formValues.handicapGrave : undefined,
      refMissionEntreprise: trimToUndefined(formValues.refMissionEntreprise),
      refMissionAnterieure: trimToUndefined(formValues.refMissionAnterieure),
      precisions: trimToUndefined(formValues.precisions),
      rdv: selectedMissionRDV
        ? {
            adresse1: selectedMissionRDV.adresse1,
            adresse2: selectedMissionRDV.adresse2,
            adresse3: selectedMissionRDV.adresse3,
            codePostal: selectedMissionRDV.codePostal,
            commune: selectedMissionRDV.commune,
            pays: selectedMissionRDV.pays,
          }
        : undefined,
    } as MissionRequest)
      .unwrap()
      .then(() => {
        enqueueSnackbar(`La mission a été créée avec succès`, { variant: 'success' })
        navigate(consultationDossierHref(codeDossier))
      })
      .catch((error: FetchBaseQueryError | SerializedError | undefined) => {
        const errorValiderNarrowed = errorStatutIllegalNarrowed(true, error)
        if (errorValiderNarrowed) {
          enqueueSnackbar(`La création de la mission a échoué : ${errorValiderNarrowed.message}`, { variant: 'error' })
        } else {
          enqueueSnackbar('La création de la mission a échoué', { variant: 'error' })
        }
      })
  }
  if (
    isLoading ||
    isFetching ||
    loadingProfilEntreprise ||
    fetchingProfilEntreprise ||
    isMedecinsLoading ||
    isMedecinsFetching ||
    isLoadingReferentiel ||
    isFetchingReferentiel
  ) {
    return <AreaLoading height={351} />
  }

  const title = `Missionner un médecin`
  const showChoixMissionRDV =
    codeMedecinSelectionne &&
    dossier?.expertise?.typeLieuExpertise === TypeLieuExpertise.CABINET &&
    profilEntreprise?.parametrage?.rdv

  return (
    <>
      <NavigationPart label={title} />
      <Box marginTop={2}>
        <FormProvider {...methods}>
          <form id={formId} name={formId}>
            <Stack>
              <IdentificationDossierPart dossier={dossier} profilEntreprise={profilEntreprise} />
              <ExpertisePart dossier={dossier} profilEntreprise={profilEntreprise} />
              <LieuExpertisePart dossier={dossier} loading={isLoading} withModification />
              <ChoixMedecinPart dossier={dossier} missionPrecedente={missionPrecedente} medecins={medecins} />
              {showChoixMissionRDV && <ChoixMissionRDVPart codeMedecin={codeMedecinSelectionne} />}
              <ParametresMissionPart
                dossier={dossier}
                codeMedecin={codeMedecinSelectionne}
                rapportPrecedent={rapportPrecedent}
                referentiel={referentiel}
                profilEntreprise={profilEntreprise}
              />
              <Alert
                severity="info"
                elevation={1}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiAlert-icon': {
                    marginTop: 1,
                  },
                  '& .MuiAlert-message': {
                    width: '100%',
                  },
                }}
              >
                <AlertTitle sx={{ display: 'block', marginBottom: 0 }}>
                  <Box display="flex" flexWrap="wrap" rowGap={1} columnGap={2}>
                    <Typography display="flex" marginTop={1}>
                      Nouvelle mission n°{(dossier?.missions?.length ?? 0) + 1}
                    </Typography>
                    <ButtonsStack alignSelf="flex-end" marginLeft="auto" marginTop="2px">
                      <AnnulerButton
                        id="annuler"
                        onClick={() => handleAnnuler()}
                        color="error"
                        sx={{ bgcolor: theme.palette.tile.main }}
                      >
                        Annuler la création de mission
                      </AnnulerButton>
                      <SauvegarderButton
                        id="sauvegarder"
                        onClick={onSubmit}
                        disabled={!isValid || !codeMedecinSelectionne}
                        loading={isSauvegarderLoading}
                      >
                        Envoyer la mission au médecin
                      </SauvegarderButton>
                    </ButtonsStack>
                  </Box>
                </AlertTitle>
              </Alert>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </>
  )
}

import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryGlobal } from 'plateforme/security/keycloak'
import PageRecherche, { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import { OPTION_GROUPE } from 'plateforme/constantes'
import {
  AcquitterMessageEntrepriseRequest,
  MessageEntreprise,
  MessageEntrepriseFilter,
  MessageEntrepriseSortType,
  MessageMissionEntreprise,
  MessageMissionEntrepriseFilter,
  MessageMissionRequest,
  PageRechercheMessageMissionRequest,
} from '../types/messageEntreprise'

export const messageEntrepriseApi = createApi({
  reducerPath: 'messageEntrepriseApi',
  baseQuery: baseQueryGlobal,
  tagTypes: ['MessageEntreprise', 'MessageMissionEntreprise'],
  endpoints: (builder) => ({
    getListeMessages: builder.query<
      PageRecherche<MessageEntreprise>,
      PageRechercheRequest<MessageEntrepriseFilter, MessageEntrepriseSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal, headerGroupe }) => {
        return {
          url: 'entreprise/message',
          extraOptions: !headerGroupe || OPTION_GROUPE,
          headers: headerGroupe !== undefined ? { 'X-Profile-Id': headerGroupe } : undefined,
          params: {
            ...filter,
            ...sort,
            perPage,
            page,
            onlyTotal,
          },
        }
      },
      providesTags: ['MessageEntreprise'],
    }),
    getMessageByCode: builder.query<MessageEntreprise, string>({
      query: (codeMessage) => `entreprise/message/${codeMessage}`,
      providesTags: ['MessageEntreprise'],
    }),
    getListMessageMission: builder.query<
      PageRecherche<MessageMissionEntreprise>,
      PageRechercheMessageMissionRequest<MessageMissionEntrepriseFilter, MessageEntrepriseSortType>
    >({
      query: ({ codeDossier, codeMission, perPage, page, filter, sort }) => {
        return {
          url: `entreprise/dossier/${codeDossier}/mission/${codeMission}/message`,
          params: {
            ...filter,
            ...sort,
            perPage,
            page,
          },
        }
      },
      providesTags: ['MessageMissionEntreprise'],
    }),

    postMessageMission: builder.mutation<void, MessageMissionRequest>({
      query: ({ codeDossier, codeMission, ...body }) => {
        return {
          url: `entreprise/dossier/${codeDossier}/mission/${codeMission}/message`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result, error) => (error ? [] : ['MessageMissionEntreprise', 'MessageEntreprise']),
    }),
    postAcquitterMessage: builder.mutation<void, AcquitterMessageEntrepriseRequest>({
      query: (body) => {
        return {
          url: `entreprise/message/acquittement`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result, error) => (error ? [] : ['MessageEntreprise']),
    }),
  }),
})

export const {
  useGetListeMessagesQuery,
  useLazyGetListeMessagesQuery,
  useGetMessageByCodeQuery,
  useGetListMessageMissionQuery,
  usePostMessageMissionMutation,
  usePostAcquitterMessageMutation,
} = messageEntrepriseApi

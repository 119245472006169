import React from 'react'
import { Alert, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { DocumentsAdmin } from 'admin/store/types/documentsAdmin'
import {
  AreaLoading,
  FichierIcon,
  InfiniteScrollComponent,
  NoRowsOverlay,
  ResultatRechercheBarCard,
} from 'plateforme/components'
import {
  NotificationAdmin,
  NotificationAdminSortType,
  NotificationMessageAdminSortType,
} from 'admin/store/types/notificationsAdmin'
import { formatDateTimeFR } from 'plateforme/services/dates.services'
import { SortOption, SortParam } from 'plateforme/store/types/pageRecherche'
import { makeEllipsis } from 'plateforme/services/utils'
import { getNotificationMessageSort, setNotificationMessageSort } from 'admin/store/slices/sortSlice/sortSlice'

type ListeNotificationsMessageAdminPartProps = {
  combinedData: Array<NotificationAdmin>
  total?: number
  readMore: VoidFunction
  sortBy: (param: SortParam<NotificationAdminSortType>) => void
  initSort: VoidFunction
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  isSuccess: boolean
}

const getDocuments = (documents: DocumentsAdmin[] | undefined) => {
  return documents && documents.length > 0 ? <FichierIcon sx={{ fontSize: 32 }} /> : ''
}

export default function ListeNotificationsMessageAdminPart({
  combinedData,
  total,
  readMore,
  sortBy,
  initSort,
  isError,
  isLoading,
  isFetching,
  isSuccess,
}: ListeNotificationsMessageAdminPartProps) {
  const sortOptions: SortOption<NotificationMessageAdminSortType>[] = [
    { sortType: NotificationMessageAdminSortType.OBJET, label: 'Objet' },
    { sortType: NotificationMessageAdminSortType.EXPEDITEUR, label: 'Expéditeur' },
    { sortType: NotificationMessageAdminSortType.DESTINATAIRE, label: 'Destinataire' },
    { sortType: NotificationMessageAdminSortType.DATE_CREATION, label: 'Date' },
  ]

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      sx={{ '& .infinite-scroll-component__outerdiv': { width: '100%' } }}
    >
      <Grid item container xs={12} paddingBottom={2.5}>
        <ResultatRechercheBarCard
          title="Liste des messages"
          total={total}
          sortBy={sortBy}
          sortOptions={sortOptions}
          initSort={initSort}
          withSorting
          withExportCSV={false}
          setSortSlice={setNotificationMessageSort}
          getSortSlice={getNotificationMessageSort}
        />
      </Grid>
      {isError && !isSuccess && !isLoading && !isFetching ? (
        <Alert severity="info" sx={{ width: '100%' }}>
          Aucune donnée
        </Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '28%' }}>Objet</TableCell>
                <TableCell sx={{ width: '18%' }}>Expéditeur</TableCell>
                <TableCell sx={{ width: '18%' }}>Destinataire</TableCell>
                <TableCell sx={{ width: '18%' }}>Pièce jointe</TableCell>
                <TableCell sx={{ width: '18%' }}>Date/Heure</TableCell>
              </TableRow>
            </TableHead>
          </Table>
          {total && total > 0 ? (
            <InfiniteScrollComponent
              dataLength={combinedData?.length}
              next={readMore}
              hasMore={combinedData.length < (total ?? 0)}
              isLoading={isLoading || isFetching}
              areaLoading={<AreaLoading height={90} />}
              xs={12}
            >
              {combinedData?.map((na: NotificationAdmin) => (
                <Table key={na.code}>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ width: '28%' }}>{makeEllipsis(na.objet, 40)}</TableCell>
                      <TableCell sx={{ width: '18%' }}>{na.medecin?.libelle ?? ''}</TableCell>
                      <TableCell sx={{ width: '18%' }}>{na.entrepriseDest?.libelle ?? ''}</TableCell>
                      <TableCell sx={{ width: '18%' }}>{getDocuments(na.documents)}</TableCell>
                      <TableCell sx={{ width: '18%' }}>{formatDateTimeFR(na.date)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ))}
            </InfiniteScrollComponent>
          ) : (
            <Table>
              <NoRowsOverlay />
            </Table>
          )}
        </TableContainer>
      )}
    </Grid>
  )
}

import * as React from 'react'
import { IconButton, IconButtonProps, Tooltip } from '@mui/material'
import { RemoveRedEye } from '@mui/icons-material'

export interface VoirButtonProps extends IconButtonProps {
  href?: string
  tooltip?: NonNullable<React.ReactNode>
  tooltipPlacement?: 'right' | 'left'
  disabled?: boolean
}

export default function VoirIconButton(props: VoirButtonProps) {
  const { disabled, tooltip, tooltipPlacement, 'aria-label': label, sx: otherSx, ...other } = props
  const placement = tooltipPlacement ?? 'right'
  const tooltipMargin =
    placement === 'right'
      ? { marginLeft: '-8px !important', maxWidth: '100px !important' }
      : { marginRight: '-8px !important' }
  return (
    <Tooltip
      title={tooltip === true ? 'Modifier' : tooltip ?? false}
      placement={placement}
      open={tooltip ? undefined : false}
      PopperProps={{ sx: { '& .MuiTooltip-tooltip': tooltipMargin } }}
    >
      <div>
        <IconButton
          disabled={disabled}
          aria-label={label ?? 'Voir'}
          sx={{ minWidth: '46px !important', ...otherSx }}
          {...other}
        >
          <RemoveRedEye color={disabled !== undefined && disabled ? `disabled` : `secondary`} />
        </IconButton>
      </div>
    </Tooltip>
  )
}

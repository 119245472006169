import React from 'react'
import { Box, Card, CardActionArea, CardContent, Checkbox, Grid, Stack, Typography } from '@mui/material'
import { formatDateFR } from 'plateforme/services/dates.services'
import { formatMontant } from 'plateforme/services/montant.services'
import useTabNavigate from 'plateforme/hooks/useTabNavigate'
import Facture from 'medecin/store/types/factureMedecin'
import { consultationDossierHref } from 'medecin/MedecinApp'
import { TabsConsultationDossierMedecinPartTabId } from 'medecin/parts/consultationDossier/TabsConsultationDossierMedecinPart'
import { formatStatutFacture } from 'plateforme/services/factures.services'
import StatutFactureChip from './StatutFactureChip'

interface CardFactureProps {
  facture: Facture
}

function ValueLabel({ icon, label, value }: { icon?: React.ReactNode; label?: string; value?: string | number }) {
  return (
    <Stack direction="row" alignItems="center">
      {icon}
      <Box
        sx={{
          flexDirection: 'column',
        }}
      >
        <Typography variant="body2" color="readOnly.main">
          {label}
        </Typography>
        <Typography variant="body2" color="text.primary" gutterBottom>
          {value}
        </Typography>
      </Box>
    </Stack>
  )
}

export default React.memo(FactureMedecinCard)

function FactureMedecinCard({ facture }: CardFactureProps) {
  const { numeroFacture, dateFacture, dossier, mission, montantHonoraires, montantFrais } = facture

  const { code: codeDossier, entreprise, nomPersonneAExaminer, prenomPersonneAExaminer } = dossier || {}
  const { libelle: libelleMission } = mission || {}

  const { navigateTab } = useTabNavigate()
  const navigateFacture = () =>
    navigateTab(
      TabsConsultationDossierMedecinPartTabId.FACTURES_TAB,
      consultationDossierHref(codeDossier ?? 'undefined')
    )

  return (
    <Card sx={{ width: '100%' }}>
      <CardActionArea>
        <CardContent sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Box sx={{ display: 'flex', width: '80px', alignItems: 'center' }}>
            <Checkbox color="default" />
          </Box>
          <Grid container spacing={2} width="100%" onClick={navigateFacture}>
            <Grid item xs={6} md={8}>
              <Typography align="left" variant="h3" sx={{ fontWeight: 'bold' }} color="text.primary">
                {libelleMission}
              </Typography>
              <Typography align="left" variant="subtitle2" color="text.secondary" gutterBottom>
                {mission?.refMissionMedecin ? `Ma référence ${mission?.refMissionMedecin}` : ''}
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <StatutFactureChip statutFacture={formatStatutFacture(facture)} />
            </Grid>
            <Grid item xs={4} lg={3}>
              <ValueLabel label="Numéro facture" value={numeroFacture} />
            </Grid>
            <Grid item xs={4} lg={3}>
              <ValueLabel
                label="Personne à examiner"
                value={`${nomPersonneAExaminer || ''} ${prenomPersonneAExaminer || ''}`}
              />
            </Grid>
            <Grid item xs={4} lg={3}>
              <ValueLabel label="Date facture" value={formatDateFR(dateFacture)} />
            </Grid>
            <Grid item xs={0} lg={3} display={{ xs: 'none !important', lg: 'flex !important' }}>
              &nbsp;
            </Grid>
            <Grid item xs={4} lg={3}>
              <ValueLabel label="Honoraires (TTC)" value={formatMontant(montantHonoraires?.montantTTC)} />
            </Grid>
            <Grid item xs={4} lg={3}>
              <ValueLabel label="Frais (TTC)" value={formatMontant(montantFrais?.montantTTC)} />
            </Grid>
            <Grid
              item
              xs={4}
              lg={6}
              display="flex"
              alignItems="flex-end"
              justifyContent="flex-end"
              paddingRight={2.5}
              paddingBottom={0.5}
            >
              <Typography align="right" variant="h3" sx={{ fontWeight: 'bold' }} color="text.primary">
                {entreprise?.libelle}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

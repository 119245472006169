import React, { useEffect, useState } from 'react'
import { Alert, Box, CircularProgress, Container, Grid, Typography } from '@mui/material'
import { InfiniteScrollComponent, ResultatRechercheBarCard } from 'plateforme/components'
import { SortOption, SortParam } from 'plateforme/store/types/pageRecherche'
import MedecinEntreprise, { MedecinEntrepriseSortType } from 'assureur/store/types/medecinEntreprise'
import { useSnackbar } from 'notistack'
import { getSearchMedecin } from 'assureur/store/slices/searchMedecin/searchMedecinSlice'
import { useSelector } from 'react-redux'
import { useGetEntreprisesGroupeQuery } from 'assureur/store/apis/profilEntrepriseApi'
import { getMedecinGroupeSort, setMedecinGroupeSort } from 'assureur/store/slices/sortSlice/sortSlice'
import MedecinEntrepriseGroupeCard from '../MedecinEntrepriseGroupeCard'

type ListeMedecinsPartProps = {
  isError: boolean
  isLoading: boolean
  sortFrontFunction: (param: SortParam<string>, medecins: MedecinEntreprise[]) => MedecinEntreprise[]
}

export default function ListeMedecinsEntrepriseGroupePart({
  isError,
  isLoading,
  sortFrontFunction,
}: ListeMedecinsPartProps) {
  // les options de tri disponibles a passer au composant SortComponentMenu
  const sortOptions: SortOption<MedecinEntrepriseSortType>[] = [
    { sortType: MedecinEntrepriseSortType.LIBELLE_SORT, label: 'Nom du médecin' },
    { sortType: MedecinEntrepriseSortType.CODE_MEDECIN_SORT, label: 'Identifiant médecin plateforme' },
  ]

  const {
    data: entreprisesGroupe,
    isLoading: isLoadingGroupe,
    isFetching: isFetchingGroupe,
  } = useGetEntreprisesGroupeQuery()
  const { enqueueSnackbar } = useSnackbar()
  const medecins = useSelector(getSearchMedecin) ?? []
  const initalItems = medecins.slice(0, 10)
  const perPage = 10
  const [displayedMedecins, setDisplayedMedecins] = useState([...initalItems])

  useEffect(() => {
    if (isError) {
      enqueueSnackbar('La recherche des médecins a échoué.', { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  useEffect(() => {
    setDisplayedMedecins([...initalItems])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medecins])

  if (isLoading || isLoadingGroupe || isFetchingGroupe) {
    return (
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          height: '500px',
          maxWidth: 'none !important',
          width: '100%',
          backgroundColor: 'white',
        }}
      >
        <CircularProgress size={40} thickness={4} />
        <Box>
          <Typography variant="h5" gutterBottom>
            Chargement des médecins en cours...
          </Typography>
          <Typography variant="body1" color="text.secondary">
            La chargement des médecins groupe peut prendre quelques instants.
          </Typography>
        </Box>
      </Container>
    )
  }

  const expertiseSurPieceEnable = entreprisesGroupe?.every(
    (entreprise) => entreprise.parametrage?.expertiseSurPiece === true
  )

  const readMore = () => {
    const currentLength = displayedMedecins?.length
    const newMedecins = medecins?.slice(currentLength, currentLength + perPage)
    setDisplayedMedecins([...displayedMedecins, ...newMedecins])
  }

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      sx={{ '& .infinite-scroll-component__outerdiv': { width: '100%' } }}
    >
      <Grid item container xs={12} paddingBottom={2.5}>
        <ResultatRechercheBarCard
          title="Liste des médecins"
          total={medecins?.length}
          loading={isLoading}
          sortOptions={sortOptions}
          withSorting
          sortFront
          sortFrontFunction={sortFrontFunction}
          data={medecins}
          withExportCSV={false}
          setSortSlice={setMedecinGroupeSort}
          getSortSlice={getMedecinGroupeSort}
        />
      </Grid>
      {(isError && !isLoading) || medecins?.length === 0 ? (
        <Alert severity="info" sx={{ width: '100%' }}>
          Aucun médecin remonté.
        </Alert>
      ) : (
        <InfiniteScrollComponent
          dataLength={medecins?.length ?? 0}
          next={readMore}
          // NOTE : Cette solution est un hack pour forcer le chargement des cartes sur l'événement de défilement, à cause de state isLoading calculé côté front.
          onScroll={readMore}
          hasMore={displayedMedecins.length < (medecins?.length ?? 0)}
          isLoading={isLoading}
          xs={12}
          lg={6}
        >
          <Grid item container xs={12}>
            {displayedMedecins?.map((medecin: MedecinEntreprise) => (
              <Grid item xs={12} lg={6} key={medecin.code}>
                <MedecinEntrepriseGroupeCard
                  key={medecin.code}
                  medecin={medecin}
                  expertiseSurPieceEnable={expertiseSurPieceEnable}
                />
              </Grid>
            ))}
          </Grid>
        </InfiniteScrollComponent>
      )}
    </Grid>
  )
}

import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'

export default function useErrorSnackBar<T>(error: T, isError: boolean, message: string) {
  const { enqueueSnackbar } = useSnackbar()
  const [lastError, setLastError] = useState<T | null>(null)

  useEffect(() => {
    if (isError && error !== lastError) {
      enqueueSnackbar(message, { variant: 'error' })
      setLastError(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])
}

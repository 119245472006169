import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'plateforme/store'
import { SortParam } from 'plateforme/store/types/pageRecherche'

interface SortCriteria<SortType> {
  dossierSort?: SortParam<SortType>
  notificationMessageSort?: SortParam<SortType>
  notificationDossierSort?: SortParam<SortType>
  notificationMedecinSort?: SortParam<SortType>
  medecinSort?: SortParam<SortType>
  habilitationSort?: SortParam<SortType>
  fluxTechniquesSort?: SortParam<SortType>
  entrepriseSort?: SortParam<SortType>
  habilitationMedecinSort?: SortParam<SortType>
  habilitationEntrepriseSort?: SortParam<SortType>
}

export const sortSliceName = 'sortSliceAdmin'

const sortSliceAdmin = createSlice({
  name: sortSliceName,
  initialState: {
    dossierSort: undefined,
    notificationMessageSort: undefined,
    notificationDossierSort: undefined,
    notificationMedecinSort: undefined,
    medecinSort: undefined,
    fluxTechniquesSort: undefined,
    entrepriseSort: undefined,
    habilitationMedecinSort: undefined,
    habilitationEntrepriseSort: undefined,
  } as SortCriteria<string>,
  reducers: {
    setDossierSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.dossierSort = action.payload
    },
    setNotificationMessageSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.notificationMessageSort = action.payload
    },
    setNotificationDossierSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.notificationDossierSort = action.payload
    },
    setNotificationMedecinSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.notificationMedecinSort = action.payload
    },
    setMedecinSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.medecinSort = action.payload
    },
    setFluxTechniquesSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.fluxTechniquesSort = action.payload
    },
    setEntrepriseSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.entrepriseSort = action.payload
    },
    setHabilitationEntrepriseSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.habilitationEntrepriseSort = action.payload
    },
    setHabilitationMedecinSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.habilitationMedecinSort = action.payload
    },
  },
})

export const {
  setDossierSort,
  setNotificationMessageSort,
  setNotificationDossierSort,
  setNotificationMedecinSort,
  setMedecinSort,
  setFluxTechniquesSort,
  setEntrepriseSort,
  setHabilitationMedecinSort,
  setHabilitationEntrepriseSort,
} = sortSliceAdmin.actions

export const getDossierSort = (state: RootState) => state?.sortSliceAdmin.dossierSort
export const getNotificationMessageSort = (state: RootState) => state?.sortSliceAdmin.notificationMessageSort
export const getNotificationDossierSort = (state: RootState) => state?.sortSliceAdmin.notificationDossierSort
export const getNotificationMedecinSort = (state: RootState) => state?.sortSliceAdmin.notificationMedecinSort
export const getMedecinSort = (state: RootState) => state?.sortSliceAdmin.medecinSort
export const getFluxTechniquesSort = (state: RootState) => state?.sortSliceAdmin.fluxTechniquesSort
export const getEntrepriseSort = (state: RootState) => state?.sortSliceAdmin.entrepriseSort
export const getHabilitationMedecinSort = (state: RootState) => state?.sortSliceAdmin.habilitationMedecinSort
export const getHabilitationEntrepriseSort = (state: RootState) => state?.sortSliceAdmin.habilitationEntrepriseSort

export default sortSliceAdmin.reducer

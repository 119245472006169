// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// nécessaire afin de manipuler les dates qui sont soit de type Date ou string
import {
  differenceInMonths,
  format,
  formatDistanceToNowStrict,
  isAfter,
  isBefore,
  isValid,
  setDefaultOptions,
  parse,
  isEqual,
} from 'date-fns'
import frLocale from 'date-fns/locale/fr'
import { formatInTimeZone } from 'date-fns-tz'
import dayjs from 'dayjs'

export const isDateValide = (date: Date | null) => {
  // NB : on considère que date inferieure a 1900-01-01 est invalide
  if (date !== null && isBefore(new Date(date), new Date(1900, 1, 1))) {
    return false
  }
  // NB: on considere que la date null est valide
  return date === null ? true : isValid(new Date(date))
}
export const isDateAfterNow = (date?: Date | null) => {
  if (date === null || date === undefined) {
    return true
  }
  const myDate = new Date(date).setHours(0, 0, 0, 0)
  const now = new Date()
  return isAfter(myDate, now.setHours(0, 0, 0, 0))
}

export const isDateAfterOrNow = (date?: Date | null) => {
  if (date === null || date === undefined) {
    return true
  }
  const myDate = new Date(date).setHours(0, 0, 0, 0)
  const now = new Date()
  if (isEqual(now.setHours(0, 0, 0, 0), myDate)) return true

  return isAfter(myDate, now.setHours(0, 0, 0, 0))
}

export const isDateBeforeNow = (date?: Date | null) => {
  if (date === null || date === undefined) {
    return true
  }
  const myDate = new Date(date).setHours(0, 0, 0, 0)
  const now = new Date()
  return isBefore(myDate, now.setHours(0, 0, 0, 0))
}

export const formatDurationFromNow = (date?: Date | null): string | undefined => {
  if (!date) {
    return undefined
  }

  return isValid(date)
    ? formatDistanceToNowStrict(date, { addSuffix: false, locale: frLocale })
    : 'error: durée invalide'
}

export const formatDurationFromNowInMonths = (date?: Date | null): number => {
  if (!date) {
    return -1
  }
  return isValid(date) ? differenceInMonths(date, new Date()) : -1
}

setDefaultOptions({ locale: frLocale, weekStartsOn: 1 })

export const formatDateISO = (date?: Date | null): string | undefined => {
  if (!date) {
    return undefined
  }
  if (isDateValide(date)) {
    return dayjs(date).format('YYYY-MM-DD')
  }
  return '#invalid#'
}

export const formatDateTimeISO = (date?: Date | null): string | undefined => {
  if (!date) {
    return undefined
  }
  return formatInTimeZone(date, 'Europe/Paris', `yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX`)
}

export const formatDateFR = (date?: Date | null): string | undefined => {
  if (!date) {
    return undefined
  }
  if (date.toString() === '••••••••') {
    return '••••••••'
  }
  return formatAllDate(date)
}

export const isBrowserParis = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone === 'Europe/Paris'
}

export const formatDateStringParis = (date: string) => {
  if (!isBrowserParis()) {
    return `${date} (Paris)`
  }
  return date
}

export const formatLocalDate = (date?: Date | string): Date | null => {
  return parse(date, 'yyyy-MM-dd', new Date(0))
}

export const formatReadOnlyDatePicker = (date?: Date | string | null) => {
  if (!date) {
    return undefined
  }
  if (date.toString() === '••••••••') {
    return '••••••••'
  }

  return formatDateStringParis(dayjs(date).format('DD/MM/YYYY'))
}

export const formatDatePicker = (date?: Date | null): Date | undefined | null => {
  // si la date est de type string et LocalDate, on utilise formatLocalDate
  if (typeof date === 'string' && date.length === 10) {
    return formatLocalDate(date)
  }

  // sinon on garde le date donnée
  return date
}

export const formatAllDate = (date?: Date | string | null): string => {
  // si la date est de type string et LocalDate, on utilise formatLocalDate
  if (typeof date === 'string' && date.length === 10) {
    return formatDateStringParis(formatLocalDate(date).toLocaleDateString())
  }

  // sinon on format la date avec la timezone du serveur (Europe/Paris)
  return formatDateStringParis(formatInTimeZone(date, 'Europe/Paris', 'dd/MM/yyyy'))
}

export const formatDateTimeFR = (
  date?: Date | null,
  timeSperator?: string,
  withSeconds?: boolean
): string | undefined => {
  if (!date) {
    return undefined
  }
  try {
    return formatInTimeZone(
      date,
      'Europe/Paris',
      `dd/MM/yyyy ${timeSperator ? `'${timeSperator}' ` : ''}HH:mm${withSeconds ? ':ss' : ''}`
    )
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('erreur de formattage de date to string', date, e)
  }
  return undefined
}

export const parseDateLocal = (dateStr?: string | null): Date | undefined => {
  if (!dateStr) {
    return undefined
  }

  return new Date(dateStr)
}

export const formatDateLocalISO = (date?: Date | null): string | undefined => {
  if (!date) {
    return undefined
  }
  return format(date, 'yyyy-MM-dd')
}

export const formatDateTimeStringFr = (date?: string | null): string | undefined => {
  const parsedDate = parseDateLocal(date)
  if (!parsedDate) {
    return undefined
  }
  return formatInTimeZone(parsedDate, 'Europe/Paris', `dd/MM/yyyy à HH:mm`)
}

export const dateCompare = (dateA?: Date | null, dateB?: Date | null): number => {
  if (!dateA && !dateB) return 0
  if (!dateA) return -1
  if (!dateB) return 1

  return new Date(dateB).getTime() - new Date(dateA).getTime()
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Stack, Typography } from '@mui/material'
import { DEFAULT_OPTION } from 'plateforme/constantes'
import { AutocompleteSelectOption, AutocompleteWithQuery, AutocompleteWithQueryProps } from 'plateforme/components'
import { intersect, ngrams, trimToUndefined } from 'plateforme/services/utils'

type SelectReferentielProps = Omit<AutocompleteWithQueryProps<any[]>, 'dataTransformer'>

export default function AutocompleteMedecin({ ...restProps }: SelectReferentielProps) {
  const dataTransformer = (data: any[]) => {
    return data.map((ref) => {
      return {
        code: ref.code,
        label: ref.libelle,
        data: { codeRPPS: ref.codeRPPS, codeSIREN: ref.codeSIREN },
      }
    })
  }

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: AutocompleteSelectOption<any>) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <li {...props} key={option.code}>
      {option.code === DEFAULT_OPTION.code ? (
        <Typography align="left" variant="body2" color="text.primary">
          <i>{option.label}</i>
        </Typography>
      ) : (
        <Stack spacing={0} direction="row" justifyContent="space-between" alignItems="start" width="100%">
          <Typography align="left" variant="subtitle2" color="text.primary">
            {option.label ?? ''}
          </Typography>
          <Stack spacing={0} margin={0} width={128}>
            {option.data?.codeRPPS && (
              <Stack spacing={0.5} direction="row">
                <Typography variant="body2" color="readOnly.main">
                  RPPS
                </Typography>
                <Typography variant="body2" color="text.primary">
                  {option.data?.codeRPPS}
                </Typography>
              </Stack>
            )}
            {option.data?.codeSIREN && (
              <Stack spacing={0.5} direction="row">
                <Typography variant="body2" color="readOnly.main">
                  SIREN
                </Typography>
                <Typography variant="body2" color="text.primary">
                  {option.data?.codeSIREN}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
    </li>
  )

  // Note : cette fonction permet de filtrer les options suivant suivant les règles suivantes :
  // si le input est un chiffre on filtre sur : codeRPPS et codeSIREN
  // si c'est une chaine de caractère on filtre sur le label selon l'algorithme ngrams d'ordre 3
  const filterByNgrams3 = (options: AutocompleteSelectOption<any>[], state: { inputValue: string }) => {
    const { inputValue } = state
    const isDigit = /^\d+$/.test(inputValue)

    if (!trimToUndefined(inputValue)) {
      return options
    }

    // si le input saisie par l'utilisateur est un chiffre
    if (isDigit) {
      const reg = new RegExp(inputValue)

      return options.filter(
        (option: AutocompleteSelectOption<any>) =>
          option.data?.codeRPPS?.match(reg) || option.data?.codeSIREN?.match(reg)
      )
    }
    const inputTrigrams = ngrams(inputValue, 3)
    return (
      options
        // itérer sur chaque option et calculer l’intersection (ngramme (inputValue), options)
        .map((option) => {
          const nMatches = intersect(
            inputTrigrams, // ngrams de inputValue
            ngrams(option.label, 3) // ngrams de la valeur cherchée de l'option
          ).length
          return {
            ...option,
            nMatches,
          }
        })
        // filtrer tout ce qui n’avait pas de correspondance
        .filter(({ nMatches }) => nMatches > 0)
        // ici on affiche les 10 meilleurs résultats nous allons les trier par nMatches. en cas d’égalité, le mot le plus court l’emporte.
        .sort((a, b) => {
          const diff = b.nMatches - a.nMatches
          if (diff) {
            return diff
          }
          // si deux elements ont le même nombre de trigrammes correspondants, le plus court l’emporte
          return a.label.length - b.label.length
        })
        // retourner les 10 premiers
        .slice(0, 10)
    )
  }

  // Note : cette fonction permet de filtrer les options suivant suivant les règles suivantes :
  // si le input est un chiffre on filtre sur : codeRPPS et codeSIREN
  // si c'est une chaine de caractère on filtre sur le label
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const filterByMatch = (options: AutocompleteSelectOption<any>[], state: { inputValue: string }) => {
    const { inputValue } = state
    const isDigit = /^\d+$/.test(inputValue)
    const reg = new RegExp(inputValue)

    // si le input saisie par l'utilisateur est un chiffre
    if (isDigit) {
      return options.filter(
        (option: AutocompleteSelectOption<any>) =>
          option.data?.codeRPPS?.match(reg) || option.data?.codeSIREN?.match(reg)
      )
    }

    return options.filter(
      (option: AutocompleteSelectOption<any>) => option.data?.libelle?.match(reg) || option.data?.libelle?.match(reg)
    )
  }

  return (
    <AutocompleteWithQuery
      {...restProps}
      orderBy="label"
      placeholder="Nom, RPPS, SIREN"
      useQuery={restProps.useQuery}
      dataTransformer={dataTransformer}
      renderOption={renderOption}
      filterOptions={filterByNgrams3}
    />
  )
}

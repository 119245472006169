/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useMemo, useCallback } from 'react'
import { UseQuery, UseQueryHookResult } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { useSelector } from 'react-redux'
import { getGroupeProfilSelectionne, getIsProfilGroupe } from 'plateforme/store/slices/profilSlice'
import PageRecherche from 'plateforme/store/types/pageRecherche'
import { IQueryErrorResponse } from '../useErrorFormMapper'

export interface IListQueryResponse {
  items: any[]
  totalItems?: number
  currentToken: string
  next: string
}

interface ResultWithItems<T> {
  items: T[]
  [key: string]: any
}

function isResultWithItems<T>(result: T[] | ResultWithItems<T>): result is ResultWithItems<T> {
  return result !== null && 'items' in result && Array.isArray((result as ResultWithItems<T>)?.items)
}

const useFetchByGroupe = (
  useGetDataListQuery: UseQuery<any> | UseQueryHookResult<any, any>,
  { ...queryParameters }
) => {
  const [combinedData, setCombinedData] = useState<any[]>([])
  const [filterState, setFilterState] = useState<any>({ ...queryParameters })
  const headerValues = useSelector(getGroupeProfilSelectionne)
  const isProfilGroupe = useSelector(getIsProfilGroupe) ?? false
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [error, setError] = useState<IQueryErrorResponse | undefined>(undefined)
  const [trigger] = useGetDataListQuery()

  // useMemo garantit que les références aux valeurs mémorisées restent stables tant que les dépendances ne changent pas. Cela est  important pour le cas d'une state de type Array
  const memoizedHeaderValues = useMemo(() => headerValues ?? [], [headerValues])
  const fetchDataByGroupe = async () => {
    if (isProfilGroupe && memoizedHeaderValues.length > 0) {
      setIsLoading(true)
      await Promise.all(
        memoizedHeaderValues.map((headerValue) => {
          return trigger({ headerGroupe: headerValue, ...filterState }, { skip: !isProfilGroupe })
            .unwrap()
            .then((fetchedData: any) => {
              return fetchedData
            })
        })
      )
        .then((responses) => {
          const result = [...responses.filter((response) => response !== undefined)]
          setCombinedData(result)
          setIsError(false)
          setIsLoading(false)
        })
        .catch((errorResponse: IQueryErrorResponse) => {
          setIsError(true)
          setError(errorResponse)
          setIsLoading(false)
        })
    }
  }

  /* Notes

   Le useEffect est essentiel dans ce code pour plusieurs raisons :

  -Exécution du Code Asynchrone : useEffect permet d'exécuter du code asynchrone, comme la fonction fetchData, après le rendu initial du composant. Cela garantit que les données sont récupérées après que le composant est monté.

  -Gestion des Dépendances : En spécifiant [memoizedHeaderValues, memoizedIsProfilGroupe] comme dépendances, useEffect s'assure que fetchData est appelée chaque fois que headerValues ou isProfilGroupe changent. Cela permet de réagir dynamiquement aux changements de ces valeurs.

  -Cycle de Vie du Composant : useEffect s'intègre dans le cycle de vie du composant, permettant de gérer les effets secondaires comme les appels API. Sans useEffect, il serait difficile de synchroniser ces appels avec les changements d'état du composant.

  -Nettoyage : Bien que ce code spécifique n'ait pas de fonction de nettoyage, useEffect permet également de définir des fonctions de nettoyage pour éviter les fuites de mémoire ou annuler les abonnements lorsque le composant est démonté.

  */
  useEffect(() => {
    fetchDataByGroupe()
  }, [memoizedHeaderValues, filterState])

  const items = useMemo(() => {
    if (combinedData.length > 0 && !isLoading && !isError) {
      return combinedData.flatMap((result) => {
        if (isResultWithItems(result)) {
          return result.items
        }
        return result
      })
    }
    return []
  }, [combinedData, filterState])

  const totalItems = useMemo(() => {
    if (combinedData.length > 0 && !isLoading && !isError) {
      return combinedData.reduce((acc, obj: PageRecherche<any>) => {
        return acc + (obj?.totalItems || 0)
      }, 0)
    }
    return 0
  }, [combinedData, isLoading, isError])

  const refetch = useCallback((params: any) => {
    setFilterState({ ...params })
  }, [])

  return {
    combinedData,
    refetch,
    items,
    totalItems,
    isLoading,
    isError,
    error: error as IQueryErrorResponse,
  }
}

export default useFetchByGroupe

import * as React from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import {
  AreaLoading,
  ButtonsStack,
  FallbackError,
  ListeButton,
  NoRowsOverlay,
  ReferentielText,
  VoirIconButton,
} from 'plateforme/components'
import { formatDateTimeFR } from 'plateforme/services/dates.services'
import { LoadingProps, makeEllipsis } from 'plateforme/services/utils'
import PageRecherche from 'plateforme/store/types/pageRecherche'
import useTabNavigate from 'plateforme/hooks/useTabNavigate'
import { isVoirNotificationEnable, tooltipVoirNotification } from 'plateforme/services/notification.services'
import { useGetListeMessagesQuery } from 'medecin/store/apis/messageMedecinApi'
import { setNotificationCriteria } from 'medecin/store/slices/searchCriteriaSlice/searchCriteriaSlice'
import { consultationDossierHref, listeNotificationsDossiersPath, profilHref } from 'medecin/MedecinApp'
import { MessageMedecin, MessageMedecinFilter } from 'medecin/store/types/messageMedecin'
import { dernieresNotificationsFiltre } from 'medecin/services/messageMedecin.services'
import { TabsConsultationDossierMedecinPartTabId } from 'medecin/parts/consultationDossier/TabsConsultationDossierMedecinPart'

export default function DernieresNotificationsMedecinPart({ loading }: LoadingProps) {
  const handleError = useErrorHandler()
  const filter = dernieresNotificationsFiltre()
  const {
    data: paginatedMessages,
    isLoading: isLoadingMessages,
    isError: isErrorMessages,
    error: errorMessages,
  } = useGetListeMessagesQuery({
    filter,
    perPage: 10,
  })
  if (isErrorMessages) {
    handleError({
      errorApi: errorMessages,
      title: 'Erreur chargement des notifications',
      api: 'useGetListeMessagesQuery',
    } as FallbackError)
  }

  if (loading || isLoadingMessages || !paginatedMessages) {
    return <AreaLoading height={350} />
  }
  return <DernieresNotificationsMedecinPartLoaded paginatedMessages={paginatedMessages} filter={filter} />
}

function DernieresNotificationsMedecinPartLoaded({
  paginatedMessages,
  filter,
}: {
  paginatedMessages: PageRecherche<MessageMedecin>
  filter: MessageMedecinFilter
}) {
  const navigate = useNavigate()
  const { navigateTab } = useTabNavigate()
  const dispatch = useDispatch()
  const messages = paginatedMessages.items

  const handleClickNotifications = () => {
    dispatch(setNotificationCriteria(filter))
    navigate(listeNotificationsDossiersPath)
  }

  const handleClickVoirNotification = (message: MessageMedecin) => {
    if (message.type === 'MEL_MIS') {
      return navigateTab(
        TabsConsultationDossierMedecinPartTabId.MESSAGES_TAB,
        consultationDossierHref(message.dossier?.code ?? 'undefined'),
        message?.mission?.code
      )
    }
    if (message.type === 'CREA_HAB') {
      return navigate(profilHref)
    }
    return navigate(consultationDossierHref(message.dossier?.code as string))
  }

  return (
    <Card>
      <CardHeader title="Dernières notifications" />
      <CardContent>
        <ButtonsStack withGutter>
          <ListeButton onClick={handleClickNotifications}>Toutes mes notifications</ListeButton>
        </ButtonsStack>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '10%' }}>Date / heure</TableCell>
                <TableCell sx={{ width: '30%' }}>Type de notification</TableCell>
                <TableCell sx={{ width: '20%' }}>Entreprise</TableCell>
                <TableCell sx={{ width: '30%' }}>Dossier</TableCell>
                <TableCell sx={{ width: '10%' }} align="center" />
              </TableRow>
            </TableHead>
            {messages.length > 0 ? (
              <TableBody>
                {messages.map((m) => (
                  <TableRow key={m.code}>
                    <TableCell sx={{ width: '10%' }}>{formatDateTimeFR(m.date)}</TableCell>
                    <TableCell sx={{ Fwidth: '30%' }}>
                      <ReferentielText referentielName="typeMessageNotification" referentielCode={m.labelType} />
                    </TableCell>
                    <TableCell sx={{ width: '20%' }}>{m.entreprise?.libelle}</TableCell>
                    <TableCell sx={{ width: '30%' }}>
                      {m.dossier ? makeEllipsis(m.dossier?.libelle, 40) : '--'}
                    </TableCell>
                    <TableCell sx={{ width: '10%', paddingRight: 4 }} align="right">
                      <VoirIconButton
                        disabled={!isVoirNotificationEnable(m)}
                        color="tile"
                        onClick={() => handleClickVoirNotification(m)}
                        tooltip={tooltipVoirNotification(m)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <NoRowsOverlay />
            )}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

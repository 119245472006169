import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useErrorHandler } from 'react-error-boundary'
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import {
  AreaLoading,
  ButtonsStack,
  FallbackError,
  FichierIcon,
  ListeButton,
  NoRowsOverlay,
  VoirIconButton,
} from 'plateforme/components'
import { dateCompare, formatDateTimeFR } from 'plateforme/services/dates.services'
import { LoadingProps, makeEllipsis } from 'plateforme/services/utils'
import { useLazyGetListeMessagesQuery } from 'assureur/store/apis/messageEntrepriseApi'
import {
  MessageDuMedecinVersAssureur,
  MessageEntreprise,
  MessageEntrepriseFilter,
} from 'assureur/store/types/messageEntreprise'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import { setNotificationCriteria } from 'assureur/store/slices/searchCriteriaSlice/searchCriteriaEntrepriseSlice'
import { listeNotificationsMessagesGroupeHref } from 'assureur/EntrepriseApp'
import { dernieresNotificationsMessagesFiltre } from 'assureur/services/messageEntreprise.services'
import useFetchByGroupe from 'plateforme/hooks/fetchByGroupe'
import MessageMedecinGroupeDetails from 'assureur/parts/ficheMedecin/MessageMedecinGroupePart/MessageMedecinGroupeDetails'

export default function DernieresNotificationsMessageEntrepriseGroupePart({ loading }: LoadingProps) {
  const handleError = useErrorHandler()
  const filter = dernieresNotificationsMessagesFiltre()
  const {
    isLoading: isLoadingMessages,
    isError: isErrorMessages,
    error: errorMessages,
    items: messages,
  } = useFetchByGroupe(useLazyGetListeMessagesQuery, { perPage: 10, filter })

  if (isErrorMessages) {
    handleError({
      errorApi: errorMessages,
      title: 'Erreur chargement des notifications',
      api: 'useGetListeDerniersMessagesQuery',
    } as FallbackError)
  }

  if (loading || isLoadingMessages) {
    return <AreaLoading height={350} />
  }

  return <DernieresNotificationsMessageEntrepriseGroupePartLoaded paginatedMessages={messages} filter={filter} />
}

function DernieresNotificationsMessageEntrepriseGroupePartLoaded({
  paginatedMessages,
  filter,
}: {
  paginatedMessages: MessageEntreprise[]
  filter: MessageEntrepriseFilter
}) {
  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleClickNotifications = () => {
    dispatch(setNotificationCriteria(filter))
    navigate(listeNotificationsMessagesGroupeHref)
  }

  return (
    <>
      {confirmDialog}
      <Card>
        <CardHeader title="Nouveaux messages" />
        <CardContent>
          <ButtonsStack withGutter>
            <ListeButton onClick={handleClickNotifications}>Tous mes messages</ListeButton>
          </ButtonsStack>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '10%' }}>Date / heure</TableCell>
                  <TableCell sx={{ width: '20%' }}>Médecin</TableCell>
                  <TableCell sx={{ width: '20%' }}>Entreprise</TableCell>
                  <TableCell sx={{ width: '30%' }}>Objet</TableCell>
                  <TableCell sx={{ width: '10%' }} align="center">
                    Pièce jointe
                  </TableCell>
                  <TableCell sx={{ width: '10%' }} align="center" />
                </TableRow>
              </TableHead>
              {paginatedMessages.length > 0 ? (
                <TableBody>
                  {(paginatedMessages as MessageDuMedecinVersAssureur[])
                    ?.sort((a, b) => dateCompare(a.date, b.date))
                    .map((m) => (
                      <TableRow key={m.code}>
                        <TableCell sx={{ width: '10%' }}>{formatDateTimeFR(m.date)}</TableCell>
                        <TableCell sx={{ width: '20%' }}>{m.medecin?.libelle}</TableCell>
                        <TableCell sx={{ width: '20%' }}>{m.entreprise?.libelle}</TableCell>
                        <TableCell sx={{ width: '30%' }}>{makeEllipsis(m.objet, 40)}</TableCell>
                        <TableCell sx={{ width: '10%' }} align="center">
                          {m.documents && m.documents.length > 0 && <FichierIcon fontSize="medium" />}
                        </TableCell>
                        <TableCell sx={{ width: '10%', paddingRight: 4 }} align="right">
                          <VoirIconButton
                            onClick={() =>
                              confirm({
                                maxWidth: 'lg',
                                fullWidth: true,
                                withForm: true,
                                form: (
                                  <MessageMedecinGroupeDetails
                                    codeMessage={m.code}
                                    messageMedecin={m}
                                    onClose={closeConfirmDialog}
                                  />
                                ),
                                confirmMsg: '',
                                title: 'Détail du message du médecin',
                              })
                            }
                            tooltip="Voir le message"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              ) : (
                <NoRowsOverlay />
              )}
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  )
}

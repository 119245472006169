import React from 'react'
import { Alert, Grid } from '@mui/material'
import { InfiniteScrollComponent, ResultatRechercheBarCard } from 'plateforme/components'
import { SortParam, SortOption } from 'plateforme/store/types/pageRecherche'
import HabilitationEntreprise, { HabilitationEntrepriseSortType } from 'assureur/store/types/habilitationEntreprise'
import { toTelechargementExportHabilitationHref } from 'plateforme/App'
import { AuthDownloadAction } from 'plateforme/store/types/utilisateur'
import { getHabilitationSort, setHabilitationSort } from 'assureur/store/slices/sortSlice/sortSlice'
import HabilitationEntrepriseGroupeCard from './HabilitationEntrepriseGroupeCard'

type ListeHabilitationsPartProps = {
  combinedData: Array<HabilitationEntreprise>
  total?: number
  readMore: VoidFunction
  sortBy: (param: SortParam<HabilitationEntrepriseSortType>) => void
  initSort: VoidFunction
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  isSuccess: boolean
  exportParams?: string
}

export default function ListeHabilitationsEntrepriseGroupePart({
  combinedData,
  total,
  readMore,
  sortBy,
  initSort,
  isError,
  isLoading,
  isFetching,
  isSuccess,
  exportParams,
}: ListeHabilitationsPartProps) {
  // les options de tri disponibles a passer au composant SortComponentMenu
  const sortOptions: SortOption<HabilitationEntrepriseSortType>[] = [
    { sortType: HabilitationEntrepriseSortType.DATE_DEBUT_SORT, label: `Date de début d'habilitation` },
    { sortType: HabilitationEntrepriseSortType.NOM_MEDECIN_SORT, label: 'Nom du médecin' },
    { sortType: HabilitationEntrepriseSortType.NUMERO_HABILITATION_SORT, label: `Numéro d'habilitation` },
    { sortType: HabilitationEntrepriseSortType.CODE_MEDECIN_SORT, label: 'Identifiant médecin plateforme' },
  ]

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      sx={{ '& .infinite-scroll-component__outerdiv': { width: '100%' } }}
    >
      <Grid item container xs={12} paddingBottom={2.5}>
        <ResultatRechercheBarCard
          title="Mon réseau de médecins"
          total={total}
          loading={isLoading || isFetching}
          sortBy={sortBy}
          sortOptions={sortOptions}
          initSort={initSort}
          withSorting
          withExportCSV={false}
          hrefExportCSV={toTelechargementExportHabilitationHref(exportParams)}
          requestExportCSV={{ action: AuthDownloadAction.TELECHARGER_CSV_HABILITATION }}
          setSortSlice={setHabilitationSort}
          getSortSlice={getHabilitationSort}
        />
      </Grid>
      {(isError && !isSuccess && !isLoading && !isFetching) || total === 0 ? (
        <Alert severity="info" sx={{ width: '100%' }}>
          Aucune habilitation remontée.
        </Alert>
      ) : (
        <InfiniteScrollComponent
          dataLength={combinedData?.length}
          next={readMore}
          hasMore={combinedData.length < (total ?? 0)}
          isLoading={isLoading || isFetching}
          xs={12}
          lg={6}
        >
          <Grid item container xs={12}>
            {combinedData?.map((habilitation: HabilitationEntreprise) => (
              <Grid item xs={12} xl={6} key={`${habilitation.libelle}${habilitation.dateDebut}`}>
                <HabilitationEntrepriseGroupeCard
                  key={`${habilitation.libelle}${habilitation.dateDebut}`}
                  habilitation={habilitation}
                />
              </Grid>
            ))}
          </Grid>
        </InfiniteScrollComponent>
      )}
    </Grid>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'plateforme/store'
import { SortParam } from 'plateforme/store/types/pageRecherche'

interface SortCriteria<SortType> {
  dossierSort?: SortParam<SortType>
  notificationMessageSort?: SortParam<SortType>
  notificationDossierSort?: SortParam<SortType>
  habilitationSort?: SortParam<SortType>
  factureSort?: SortParam<SortType>
}

export const sortSliceName = 'sortSliceMedecin'

const sortSliceMedecin = createSlice({
  name: sortSliceName,
  initialState: {
    dossierSort: undefined,
    notificationMessageSort: undefined,
    notificationDossierSort: undefined,
    habilitationSort: undefined,
    factureSort: undefined,
  } as SortCriteria<string>,
  reducers: {
    setDossierSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.dossierSort = action.payload
    },
    setNotificationMessageSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.notificationMessageSort = action.payload
    },
    setNotificationDossierSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.notificationDossierSort = action.payload
    },
    setHabilitationSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.habilitationSort = action.payload
    },
    setFactureSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.factureSort = action.payload
    },
  },
})

export const {
  setDossierSort,
  setNotificationMessageSort,
  setNotificationDossierSort,
  setHabilitationSort,
  setFactureSort,
} = sortSliceMedecin.actions

export const getDossierSort = (state: RootState) => state?.sortSliceMedecin.dossierSort
export const getNotificationMessageSort = (state: RootState) => state?.sortSliceMedecin.notificationMessageSort
export const getNotificationDossierSort = (state: RootState) => state?.sortSliceMedecin.notificationDossierSort
export const getHabilitationSort = (state: RootState) => state?.sortSliceMedecin.habilitationSort
export const getFactureSort = (state: RootState) => state?.sortSliceMedecin.factureSort

export default sortSliceMedecin.reducer

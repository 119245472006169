import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { adminRechercheDossiersHref } from 'admin/AdminApp'
import { rechercheDossiersHref as hrefRechercheDossiersEntreprise } from 'assureur/EntrepriseApp'
import { rechercheDossiersHref as hrefRechercheDossiersMedecin } from 'medecin/MedecinApp'
import { LOCALE, MSG_FIELD_REQUIRED, MSG_OPTION_REQUIRED } from 'plateforme/constantes'
import { EstimationData } from '../../medecin/parts/consultationDossier/TabRapportMedecinPart/FicheRapportMedecinForm'

/* eslint-disable @typescript-eslint/no-explicit-any */
export function removeUndefined(obj: any | undefined | null) {
  return obj == null ? undefined : Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined))
}

export function trimToUndefined(str?: string | null, exclude?: string): string | undefined {
  const trimmed = str?.trim()
  if (exclude && exclude !== '' && trimmed === exclude) return undefined
  if (trimmed && trimmed !== '') return trimmed
  return undefined
}

export function numberToUndefined(value?: number | '' | null): number | undefined {
  return value !== null && value !== '' ? value : undefined
}

export function valueToUndefined(value?: number | number[] | '' | null): number | number[] | undefined {
  return value !== null && value !== '' && value !== [] ? value : undefined
}

export function estimationDataToUndefined(estimationData?: EstimationData | null) {
  return estimationData !== null
    ? {
        valeur: valueToUndefined(estimationData?.valeur),
        unite: nullToUndefined(estimationData?.unite),
      }
    : undefined
}

export function trimToRemove(str?: string | null): string | { _op: 'REMOVE' } {
  const trimmed = str?.trim()
  if (trimmed && trimmed !== '') return trimmed

  return { _op: 'REMOVE' }
}

export function emptyToUndefined<T>(value: T[] | undefined | null): T[] | undefined {
  return value !== null && (value?.length ?? 0) > 0 ? value : undefined
}

export function nullToUndefined<T>(value: T | null): T | undefined {
  return value !== null ? value : undefined
}

export function undefinedToNull<T>(value: T | undefined): T | null {
  return value !== undefined ? value : null
}

export type ErrorData = {
  code: string
  message: string
  details: { message: string; fields?: string[] }[]
}

export interface LoadingProps {
  loading: boolean
}

export const errorNarrowed = (isError: boolean, error: FetchBaseQueryError | SerializedError | undefined) =>
  isError && error && 'status' in error && error.status === 400 && (error.data as ErrorData)

export const errorStatutIllegalNarrowed = (
  isError: boolean,
  error: FetchBaseQueryError | SerializedError | undefined
) => isError && error && 'status' in error && error.status === 412 && (error.data as ErrorData)

/* eslint-disable @typescript-eslint/no-explicit-any */
export function toArray(obj: any) {
  if (obj) {
    return Object.keys(obj).map((i) => {
      return { ...obj[i], fieldName: i }
    })
  }
  return []
}

export function objectEmptyToUndefined(object?: object) {
  return Object.values(object ?? {}).every((v) => v === undefined) ? undefined : object
}

export function isEmpty(object?: object) {
  if (object === undefined) {
    return true
  }
  return Object.keys(object).length === 0
}

export function isRapportConseilNotEmpty(object?: object | null) {
  if (object === undefined || object === null) {
    return false
  }
  return Object.values(object ?? {})?.some(
    (r) => r !== undefined && r !== null && r !== '' && !(r instanceof Array && r.every((r2) => r2 === null))
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function clearRapportConseilErrors(
  fieldName: string,
  resetField: (name: any, options?: any) => void,
  watch: any,
  clearErrors: (name?: any) => void
) {
  clearErrors(`${fieldName}.max`)
  clearErrors(`${fieldName}.min`)
  clearErrors(`${fieldName}.commentaire`)
  resetField('dommages', {
    defaultValue: watch('dommages'),
  })
}

export function reloadPage() {
  window.location.reload()
}

export function parseIntSafe(i?: string): number | undefined {
  if (i !== undefined) {
    try {
      const parsed = parseInt(i, 10)
      if (!Number.isNaN(parsed)) {
        return parsed
      }
    } catch (e) {
      // do nothing
    }
  }
  return undefined
}

export function capitalizeFirst(sentence: string) {
  // convert the first letter to uppercase and return as string
  return sentence.charAt(0).toLocaleUpperCase(LOCALE) + sentence.slice(1)
}

// turn a string into n 'grams'. we also lowercase the grams.
export function ngrams(value: string, n: number) {
  const r: string[] = []

  for (let j = 1; j <= n; j += 1) {
    for (let i = 0; i <= value.toLocaleLowerCase(LOCALE).length - j; i += 1) {
      r.push(value.toLocaleLowerCase(LOCALE).substring(i, i + j))
    }
  }

  return r
}

export function intersect(array1: string[], array2: string[]) {
  return array1.filter((value: string) => array2.includes(value))
}

// Necessaire car l'ellipsis css dans une colonne d'un tableau n'est pas optimal
export function makeEllipsis(text: string | undefined, size: number) {
  if (text === undefined) {
    return ''
  }
  if (text.length > size) {
    return `${text.slice(0, size)}...`
  }
  return text
}

export function normalizeHref(href: string) {
  return href.replace('/extranet', '')
}

export function validateRequiredWithCondition(condition: boolean) {
  return { value: condition, message: MSG_FIELD_REQUIRED }
}

export function validateRequiredOptionWithCondition(condition: boolean) {
  return { value: condition, message: MSG_OPTION_REQUIRED }
}
export function isRechercheDossierPageHref(href: string) {
  return [adminRechercheDossiersHref, hrefRechercheDossiersEntreprise, hrefRechercheDossiersMedecin].includes(
    normalizeHref(href)
  )
}

export function supprimerAccents(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

// cette fonction Retourne le nombre d'éléments uniques
export function totalElementsDistincts<T>(tableau: T[]): number {
  const elementsUniques: Set<string> = new Set()

  tableau.forEach((objet: T) => {
    const objetString: string = JSON.stringify(objet)

    elementsUniques.add(objetString)
  })

  return elementsUniques.size
}

import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import {
  capitalize,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import {
  AnnulerButton,
  CommentaireIconButton,
  DatePickerInput,
  EditTextField,
  NoRowsOverlay,
  RefuserButton,
  ReadOnlyTextField,
  TelechargerIconButton,
  ValiderButton,
} from 'plateforme/components'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import { formatDateFR, formatDateISO, formatDateTimeFR } from 'plateforme/services/dates.services'
import { trimToUndefined } from 'plateforme/services/utils'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { AuthDownloadAction, AuthDownloadDocumentDossierRequest } from 'plateforme/store/types/utilisateur'
import { toTelechargementDocumentDossierHref } from 'plateforme/App'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { useGetUtilisateurActifQuery, usePostAuthDownloadMutation } from 'plateforme/store/apis/utilisateurApi'
import dateOfStatutFacture from 'plateforme/services/factures.services'
import { formatMontant } from 'plateforme/services/montant.services'
import Dossier from 'assureur/store/types/dossierEntreprise'
import Facture, { StatutFacture, SuiviFactureEntrepriseAction } from 'assureur/store/types/factureEntreprise'
import { usePostSuiviFactureMutation } from 'assureur/store/apis/dossierAssureurApi'
import { factureTraitable } from 'assureur/services/rolesEntreprise.services'
import ReadOnlyDatePicker from 'plateforme/components/inputs/ReadOnlyDatePicker'

type FactureMissionCardProps = {
  dossier: Dossier
  mission: MissionEntreprise
  readOnly?: boolean
}

export default function FactureMissionEntrepriseCard({ dossier, mission, readOnly = true }: FactureMissionCardProps) {
  const { code: codeDossier } = dossier
  const totalFactures = mission?.factures?.length ?? 0

  const { confirm, confirmDialog, closeConfirmDialog } = useConfirmDialog()
  const [authDownload, { isLoading: isLoadingAuthDownload }] = usePostAuthDownloadMutation()
  const [modifyFacture, { error: errorModification, isLoading: isLoadingModificationFacture }] =
    usePostSuiviFactureMutation()
  const { enqueueSnackbar } = useSnackbar()
  const { data: utilisateur } = useGetUtilisateurActifQuery()
  const profilActif = utilisateur?.profilActif

  const { control, setError, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      commentaire: '',
      dateTraitement: new Date(),
    },
  })

  const errorPost = errorModification as IQueryErrorResponse
  useErrorFormMapper(errorPost, setError, getValues)

  const onDownload: (doc: DocumentTeleverse) => Promise<void> = async (doc: DocumentTeleverse) => {
    if (!codeDossier) {
      throw new Error('erreur inattendu')
    }

    const request: AuthDownloadDocumentDossierRequest = {
      action: AuthDownloadAction.TELECHARGER_DOCUMENT_DOSSIER,
      codeDossier,
      codeDocument: doc.code,
    }

    await authDownload(request)
      .unwrap()
      .then(() => {
        const href = toTelechargementDocumentDossierHref(codeDossier, doc.code)
        window.open(href, '_self')
      })
      .catch(() => {
        enqueueSnackbar(`Le téléchargement de la facture a échoué`, { variant: 'error' })
      })
  }

  const refuserFacture = async (facture: Facture) => {
    if (dossier.code && facture) {
      await modifyFacture({
        codeDossier: dossier.code,
        codeMission: mission.code,
        codeFacture: facture.code,
        action: SuiviFactureEntrepriseAction.DEMANDER_MODIFICATION,
        dateTraitement: formatDateISO(getValues('dateTraitement')),
        commentaire: trimToUndefined(getValues('commentaire')),
      })
        .unwrap()
        .then(() => {
          enqueueSnackbar(`La facture a été refusée avec succès`, {
            variant: 'success',
          })
          closeConfirmDialog()
        })
        .catch(() => {
          enqueueSnackbar(`Le refus de la facture a échoué`, { variant: 'error' })
        })
    }
  }

  const accepterFacture = async (facture: Facture) => {
    if (dossier.code && facture) {
      await modifyFacture({
        codeDossier: dossier.code,
        codeMission: mission.code,
        codeFacture: facture.code,
        action: SuiviFactureEntrepriseAction.ACCEPTER,
        dateTraitement: formatDateISO(getValues('dateTraitement')),
      })
        .unwrap()
        .then(() => {
          enqueueSnackbar(`La facture a été acceptée avec succès`, {
            variant: 'success',
          })
          closeConfirmDialog()
        })
        .catch(() => {
          enqueueSnackbar(`L'acceptation de la facture a échoué`, { variant: 'error' })
        })
    }
  }

  const onModifierFacture = (facture: Facture, refus: boolean) => () => {
    confirm({
      title: refus ? 'Refuser la facture (Demande de modification)' : 'Accepter la facture',
      confirmMsg: ``,
      withForm: true,
      maxWidth: 'md',
      form: (
        <form id="form-save-modification-facture">
          <Grid container paddingTop={0}>
            {refus && (
              <Grid item padding={2} xs={12}>
                <Controller
                  name="commentaire"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      id="commentaire-refus-modification"
                      label="Commentaire"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      fullWidth
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item padding={2} xs={12}>
              <Controller
                name="dateTraitement"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DatePickerInput
                    InputProps={{
                      id: 'date-traitement',
                      fullWidth: true,
                    }}
                    label="Date de traitement entreprise"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>

            <Grid justifyContent="flex-end" container item xs={12} columnSpacing={2}>
              <Grid item>
                <AnnulerButton onClick={closeConfirmDialog}>Annuler</AnnulerButton>
              </Grid>
              {refus ? (
                <Grid item>
                  <RefuserButton onClick={() => refuserFacture(facture)}>Refuser</RefuserButton>
                </Grid>
              ) : (
                <Grid item>
                  <ValiderButton loading={isLoadingModificationFacture} onClick={() => accepterFacture(facture)}>
                    Accepter la facture
                  </ValiderButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      ),
    })
  }

  const showCommentaire = (facture: Facture) => {
    confirm({
      title: 'Commentaire',
      confirmMsg: facture.commentaireDemandeModification,
      withForm: true,
      form: (
        <Grid container paddingTop={0}>
          <Grid justifyContent="flex-end" container item xs={12} columnSpacing={2}>
            <Grid item>
              <AnnulerButton onClick={() => closeConfirmDialog()}>Fermer</AnnulerButton>
            </Grid>
          </Grid>
        </Grid>
      ),
    })
  }

  const montantHonoraire = (value?: number) => (
    <Stack direction="row" margin={0} padding={0} spacing={0}>
      <Typography variant="body2" color="readOnly.main" minWidth={82}>
        {'Honoraires : '}
      </Typography>
      <Typography variant="body2" color="text.primary">
        {`${formatMontant(value)}`}
      </Typography>
    </Stack>
  )

  const montantFrais = (value?: number) =>
    value !== undefined ? (
      <Stack direction="row" margin={0} padding={0} spacing={0}>
        <Typography variant="body2" color="readOnly.main" minWidth={42}>
          {'Frais : '}
        </Typography>
        <Typography variant="body2" color="text.primary">
          {`${formatMontant(value)}`}
        </Typography>
      </Stack>
    ) : (
      ''
    )

  return (
    <>
      {confirmDialog}
      <Card>
        <CardHeader
          title={`Mission ${mission.refMissionEntreprise ?? mission.code} ${
            mission.refMissionMedecin ? ` / ${mission.refMissionMedecin}` : ''
          } ${mission.medecin?.libelle ? ` - ${mission.medecin.libelle}` : ''}`}
        />
        <CardContent>
          <Stack spacing={2}>
            <Grid container paddingBottom={3}>
              <Grid item xs={6}>
                <ReadOnlyDatePicker
                  InputProps={{
                    id: `id-date-acceptation-mission`,
                    fullWidth: true,
                  }}
                  value={mission.dateReponseMedecin ?? null}
                  label="Date d'acceptation de la mission"
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyDatePicker
                  InputProps={{
                    id: `id-date-cloture-mission`,
                    fullWidth: true,
                  }}
                  value={mission.dateValidationMedecin ?? null}
                  label="Date de validation du rapport"
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyTextField
                  fullWidth
                  id="id-ref-mission"
                  value={mission.refMissionEntreprise}
                  label="Référence mission entreprise"
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyTextField
                  fullWidth
                  id="id-type-mission"
                  value={mission.refMissionMedecin}
                  label="Référence mission médecin"
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack spacing={2}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date facture</TableCell>
                    <TableCell>Numéro facture</TableCell>
                    <TableCell>Montant HT</TableCell>
                    <TableCell>Montant TVA</TableCell>
                    <TableCell>Montant TTC</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell sx={{ width: 100 }} />
                  </TableRow>
                </TableHead>
                {totalFactures > 0 ? (
                  <TableBody>
                    {mission?.factures?.map((facture) => (
                      <TableRow key={facture.code}>
                        <TableCell component="th" scope="row">
                          {formatDateFR(facture.dateFacture)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {facture.numeroFacture}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {montantHonoraire(facture.montantHonoraires?.montantHT)}
                          {montantFrais(facture.montantFrais?.montantHT)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {montantHonoraire(facture.montantHonoraires?.montantTVA)}
                          {montantFrais(facture.montantFrais?.montantTVA)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {montantHonoraire(facture.montantHonoraires?.montantTTC)}
                          {montantFrais(facture.montantFrais?.montantTTC)}
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{ maxWidth: '200px' }}>
                          {`${capitalize(facture.labelStatut ?? '')} le ${formatDateTimeFR(
                            dateOfStatutFacture(facture),
                            'à'
                          )} `}
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{ width: '370px' }}>
                          <Grid direction="row" justifyContent="flex-end" container>
                            {!readOnly &&
                              facture.statut === StatutFacture.DEPOSEE &&
                              factureTraitable(mission, profilActif) && (
                                <Grid item>
                                  <ValiderButton
                                    onClick={onModifierFacture(facture, false)}
                                    sx={{ marginLeft: '16px' }}
                                    loading={isLoadingModificationFacture}
                                  >
                                    Accepter
                                  </ValiderButton>
                                  <RefuserButton onClick={onModifierFacture(facture, true)} sx={{ marginLeft: '16px' }}>
                                    Refuser
                                  </RefuserButton>
                                </Grid>
                              )}
                            {facture?.commentaireDemandeModification && (
                              <Grid item>
                                <CommentaireIconButton onClick={() => showCommentaire(facture)} />
                              </Grid>
                            )}
                            <Grid item>
                              <TelechargerIconButton
                                onClick={() => onDownload(facture.document)}
                                loading={isLoadingAuthDownload}
                                tooltip
                              />
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <NoRowsOverlay />
                )}
              </Table>
            </TableContainer>
          </Stack>
        </CardContent>
      </Card>
    </>
  )
}

import React from 'react'
import { Stack } from '@mui/material'
import NavigationPart from 'plateforme/parts/NavigationPart'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'
import { useGetListeFluxTechniquesAdminQuery } from 'admin/store/apis/fluxTechniqueAdminApi'
import RechercheFluxTechniquesAdminPart from 'admin/part/rechercheFluxTechnique/RechercheFluxTechniquesAdminPart'
import ListeFluxTechniquesAdminPart from 'admin/part/rechercheFluxTechnique/ListeFluxTechniquesAdminPart'
import { useSelector } from 'react-redux'
import { getFluxTechniqueCriteria } from 'admin/store/slices/searchCriteriaSlice/searchCriteriaAdminSlice'
import { getFluxTechniquesSort } from 'admin/store/slices/sortSlice/sortSlice'
import useErrorSnackBar from 'plateforme/hooks/useErrorSnackBar'

export default function RechercheFluxTechniquesAdminPage() {
  // get default values of filter and sort params from slice
  const defaultFluxTechniqueCriteria = useSelector(getFluxTechniqueCriteria)
  const defaultFluxTechniqueSort = useSelector(getFluxTechniquesSort)
  const {
    combinedData,
    readMore,
    remoteTotal,
    search,
    initSort,
    sortBy,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetListeFluxTechniquesAdminQuery, {
    perPage: 30,
    defaultCriteria: defaultFluxTechniqueCriteria,
    defaultSort: defaultFluxTechniqueSort,
    hideDefaultResult: true,
  })

  useErrorSnackBar(error, isError, 'La Recherche des échanges techniques TransMED a échoué')

  return (
    <Stack>
      <NavigationPart label="Échanges techniques TransMED" />
      <RechercheFluxTechniquesAdminPart search={search} searchError={error} />
      <ListeFluxTechniquesAdminPart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        combinedData={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        isSuccess={isSuccess}
      />
    </Stack>
  )
}

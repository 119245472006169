import React from 'react'
import { Stack } from '@mui/material'
import NavigationPart from 'plateforme/parts/NavigationPart'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'
import { useGetAdminMedecinsQuery } from 'admin/store/apis/gestionMedecinApi'
import ListeMedecinsAdminPart from 'admin/part/rechercheMedecinAdmin/ListeMedecinsAdminPart'
import RechercheMedecinsAdminPart from 'admin/part/rechercheMedecinAdmin/RechercheMedecinsAdminPart'
import { useSelector } from 'react-redux'
import { getMedecinReferenceCriteria } from 'admin/store/slices/searchCriteriaSlice/searchCriteriaAdminSlice'
import { getMedecinSort } from 'admin/store/slices/sortSlice/sortSlice'
import useErrorSnackBar from 'plateforme/hooks/useErrorSnackBar'

export default function RechercheMedecinsAdminPage() {
  // get default values of filter and sort params from slice
  const defaultMedecinCriteria = useSelector(getMedecinReferenceCriteria)
  const defaultMedecinSort = useSelector(getMedecinSort)
  const {
    combinedData,
    exportParams,
    readMore,
    remoteTotal,
    search,
    initSort,
    sortBy,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetAdminMedecinsQuery, {
    defaultCriteria: defaultMedecinCriteria,
    defaultSort: defaultMedecinSort,
  })

  useErrorSnackBar(error, isError, 'La Recherche des médecins a échoué')

  return (
    <Stack>
      <NavigationPart label="Médecins référencés" />
      <RechercheMedecinsAdminPart search={search} searchError={error} />
      <ListeMedecinsAdminPart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        combinedData={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        isSuccess={isSuccess}
        exportParams={exportParams}
      />
    </Stack>
  )
}

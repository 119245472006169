import React, { useEffect } from 'react'
import { Button, Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Loading, MessageAlert, ReadOnlyDatePicker, ReadOnlyTextField } from 'plateforme/components'
import { MessageDuMedecinVersAssureur } from 'assureur/store/types/messageEntreprise'
import { useGetMessageByCodeQuery, usePostAcquitterMessageMutation } from 'assureur/store/apis/messageEntrepriseApi'
import { getIsProfilGroupe } from 'plateforme/store/slices/profilSlice'
import { useSelector } from 'react-redux'
import PiecesJointesTable from './PiecesJointesTable'

interface MessageMedecinDetailsProps {
  codeMessage: string
  messageMedecin?: MessageDuMedecinVersAssureur
  onClose: VoidFunction
}

export default function MessageMedecinDetails({
  codeMessage,
  messageMedecin: providedMessage,
  onClose,
}: MessageMedecinDetailsProps) {
  const isProfilGroupe = useSelector(getIsProfilGroupe)
  const { data: fetchedMessage, isLoading } = useGetMessageByCodeQuery(codeMessage, {
    skip: providedMessage !== undefined,
  })
  const messageMedecin = (providedMessage ?? fetchedMessage) as MessageDuMedecinVersAssureur

  const [acquitterMessage] = usePostAcquitterMessageMutation()
  useEffect(() => {
    if (!isProfilGroupe && messageMedecin && !messageMedecin.lu) {
      acquitterMessage({
        codesMessages: [messageMedecin.code],
        typesExclus: ['MEL_MIS'],
      })
    }
  }, [acquitterMessage, messageMedecin, isProfilGroupe])

  if (isLoading) {
    return (
      <Grid padding={2} container justifyContent="center">
        <Grid item xs={12}>
          <Loading />
        </Grid>
      </Grid>
    )
  }

  if (!messageMedecin) {
    return (
      <Grid padding={2} container justifyContent="flex-end">
        <Grid item xs={12}>
          <MessageAlert severity="error">Erreur chargement du message</MessageAlert>
        </Grid>
        <Grid item>
          <Button variant="outlined" startIcon={<CloseIcon />} onClick={onClose}>
            Fermer
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid padding={2} container justifyContent="center">
      <Grid item xs={6}>
        <ReadOnlyTextField fullWidth id="expediteur" value={messageMedecin.medecin.libelle} label="Expéditeur" />
      </Grid>
      <Grid item xs={6}>
        <ReadOnlyDatePicker
          InputProps={{
            id: 'date-envoi',
            fullWidth: true,
          }}
          value={messageMedecin.date}
          label="Date d'envoi"
        />
      </Grid>
      <Grid item xs={12}>
        <ReadOnlyTextField id="objet" label="Objet" value={messageMedecin.objet} fullWidth multiline />
      </Grid>
      <Grid item xs={12}>
        <ReadOnlyTextField id="message" label="Message" value={messageMedecin.message} fullWidth multiline />
      </Grid>
      {(messageMedecin?.documents?.length ?? 0) > 0 && (
        <Grid item xs={12}>
          <PiecesJointesTable nbLigneParPage={5} messageMedecin={messageMedecin} />
        </Grid>
      )}
      <Grid justifyContent="flex-end" container item xs={12}>
        <Grid item>
          <Button variant="outlined" startIcon={<CloseIcon />} onClick={onClose}>
            Fermer
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

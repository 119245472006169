import React from 'react'
import ProfilEntreprise, { ReferentielEntreprise } from 'plateforme/store/types/profilEntreprise'
import { useSelector } from 'react-redux'
import { getGroupeProfilSelectionne } from 'plateforme/store/slices/profilSlice'
import { useGetEntreprisesGroupeQuery } from 'assureur/store/apis/profilEntrepriseApi'
import { SelectOption } from './SelectInput'
import SelectInputWithQuery, { SelectInputWithQueryProps } from './SelectInputWithQuery'

type SelectReferentielProps = Omit<SelectInputWithQueryProps<ReferentielEntreprise[]>, 'useQuery' | 'dataTransformer'>

export default function SelectInputEntrepriseGroupe({ ...restProps }: SelectReferentielProps) {
  const headerValues = useSelector(getGroupeProfilSelectionne)
  const memoizedHeaderValues = React.useMemo(() => headerValues ?? [], [headerValues])

  const dataTransformer = (data: ProfilEntreprise[]) => {
    const options = data
      ?.filter((entreprise) =>
        memoizedHeaderValues.some((header) => entreprise.code && header.includes(entreprise.code.slice(2)))
      )
      .map((entreprise) => {
        return {
          code: memoizedHeaderValues.find((header) => entreprise.code && header.includes(entreprise.code.slice(2))),
          label: entreprise.libelle,
        } as SelectOption
      })
    return options
  }

  return (
    <SelectInputWithQuery
      {...restProps}
      orderBy="label"
      useQuery={useGetEntreprisesGroupeQuery}
      dataTransformer={dataTransformer}
    />
  )
}

import React from 'react'
import uuid from 'react-uuid'
import { useNavigate } from 'react-router-dom'
import { Card, CardActionArea, CardContent, Checkbox, Grid, Stack, Typography, Box } from '@mui/material'
import { InformationChip, StatutHabilitationChip } from 'plateforme/components'
import HabilitationEntreprise from 'assureur/store/types/habilitationEntreprise'
import { consultationFicheMedecinHref } from 'assureur/EntrepriseApp'
import { formatDateFR } from 'plateforme/services/dates.services'
import { chipStatutMedecin } from 'plateforme/services/medecin.services'

interface HabilitationEntrepriseCardProps {
  habilitation: HabilitationEntreprise
}

function ValueLabled({ label, value }: { label?: string; value?: string }) {
  return (
    <Box
      sx={{
        flexDirection: 'column',
      }}
    >
      <Typography variant="body2" color="readOnly.main">
        {label}
      </Typography>
      <Typography variant="subtitle2" color="text.primary" gutterBottom>
        {value}
      </Typography>
    </Box>
  )
}

export default React.memo(HabilitationEntrepriseCard)

function HabilitationEntrepriseCard({ habilitation }: HabilitationEntrepriseCardProps) {
  const {
    numeroHabilitation,
    libelle: libelleMedecin,
    expertiseSurPiece,
    intervenantOccasionnel,
    irca,
    codeRPPS,
    codeSIREN,
    lieuxExercice,
    dateDebut,
    dateFin,
    code: codeMedecin,
  } = habilitation

  // hooks:
  const navigate = useNavigate()
  const displayNumeroHabilitation = numeroHabilitation ? `(${numeroHabilitation})` : ''

  return (
    <Card sx={{ width: '100%', height: '270px' }}>
      <CardActionArea>
        <CardContent sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Box sx={{ display: 'flex', width: '80px', alignItems: 'center' }}>
            <Checkbox color="default" />
          </Box>
          <Grid
            container
            direction="column"
            spacing={2.5}
            width="100%"
            onClick={() => navigate(consultationFicheMedecinHref(codeMedecin ?? 'undefined'))}
          >
            <Stack
              direction="row"
              sx={{
                paddingTop: '20px',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }}
            >
              <Stack spacing={0}>
                <Typography
                  margin={0}
                  align="left"
                  variant="h3"
                  sx={{ fontWeight: 'bold' }}
                  color="text.primary"
                  gutterBottom
                >
                  {`${libelleMedecin ?? ''} ${displayNumeroHabilitation}`}
                </Typography>
                <Stack direction="row" spacing={6}>
                  {codeRPPS && <ValueLabled label="RPPS" value={codeRPPS} />}
                  {codeSIREN && <ValueLabled label="SIREN" value={codeSIREN} />}
                </Stack>
              </Stack>
              <StatutHabilitationChip habilitation={habilitation} />
            </Stack>
            <Grid container spacing={2}>
              <Grid item sm={8}>
                <Stack direction="row" padding={1} spacing={1}>
                  {chipStatutMedecin(habilitation.statutMedecin, habilitation.labelStatutMedecin)}
                  {irca && (
                    <InformationChip
                      colorText="white"
                      backgroundColor="#a68cbe"
                      variant="subtitle2"
                      indicateur="Médecin IRCA"
                    />
                  )}
                  {expertiseSurPiece && <InformationChip variant="subtitle2" indicateur="Avis technique sur pièce" />}
                  {intervenantOccasionnel && (
                    <InformationChip variant="subtitle2" indicateur="Intervenant occasionnel" />
                  )}
                </Stack>
              </Grid>
              <Grid item sm={2}>
                <ValueLabled label="Date de début" value={formatDateFR(dateDebut)} />
              </Grid>
              <Grid item sm={2}>
                {dateFin && <ValueLabled label="Date de fin" value={formatDateFR(dateFin)} />}
              </Grid>
            </Grid>
            <Stack spacing={0}>
              <Typography variant="body2" color="readOnly.main">
                Établissements
              </Typography>
              <Stack direction="row" padding={1} spacing={1}>
                {lieuxExercice?.map((lieuExerice) => (
                  <InformationChip
                    key={uuid()}
                    variant="body2"
                    indicateur={`${lieuExerice.adresseComplete.split('\n').pop()}`}
                  />
                ))}
              </Stack>
            </Stack>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Card, CardContent, CardHeader, Grid, Snackbar } from '@mui/material'
import {
  ButtonsStack,
  ChercherButton,
  DateTimePickerInput,
  EditTextField,
  RechargerButton,
  SelectInput,
  SelectInputReferentiel,
  SelectOption,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { formatDateTimeISO } from 'plateforme/services/dates.services'
import { emptyToUndefined, trimToUndefined } from 'plateforme/services/utils'
import { ReferentielTypeMessageNotification, TypeMessageNotification } from 'plateforme/store/types/referentiel'
import determineLuNonLuInitial from 'plateforme/services/notification.services'
import { NotificationMedecinFilter } from 'medecin/store/types/notificationsMedecin'
import {
  getNotificationCriteria,
  setNotificationCriteria,
} from 'medecin/store/slices/searchCriteriaSlice/searchCriteriaSlice'
import { setNotificationDossierSort } from 'medecin/store/slices/sortSlice/sortSlice'
import AutocompleteEntreprise from 'medecin/components/AutocompleteEntreprise'
import { useGetReferentielEntrepriseQuery } from 'medecin/store/apis/referentielEntrepriseMedecinApi'

type SearchNotificationsProps = {
  search: (criteria: NotificationMedecinFilter) => void
  searchError: IQueryErrorResponse
}

export interface NotificationsMedecinFilterData {
  motCle?: string | null
  dateDebut: Date | null
  dateFin: Date | null
  types: TypeMessageNotification[] | null
  typesExclus: TypeMessageNotification[] | null
  codeEntreprise: SelectOption | null
  lu: string[]
}

export default function RechercheNotificationsDossierMedecinPart({ search, searchError }: SearchNotificationsProps) {
  // get default values from localStorage
  const defaultNotificationCriteria = useSelector(getNotificationCriteria)
  const dispatch = useDispatch()

  // Les valeurs initiales du formulaire :
  const initialValues: NotificationsMedecinFilterData = {
    motCle: defaultNotificationCriteria?.motCle ?? '',
    dateDebut: defaultNotificationCriteria?.dateDebut ? new Date(defaultNotificationCriteria?.dateDebut) : null,
    dateFin: defaultNotificationCriteria?.dateFin ? new Date(defaultNotificationCriteria?.dateFin) : null,
    types: defaultNotificationCriteria?.types ?? null,
    typesExclus: ['MEL_MED', 'REL_FAC'],
    codeEntreprise: defaultNotificationCriteria?.codeEntreprise
      ? ({
          code: defaultNotificationCriteria?.codeEntreprise,
          label: defaultNotificationCriteria?.labelEntreprise,
        } as SelectOption)
      : null,
    lu: determineLuNonLuInitial(defaultNotificationCriteria?.lu),
  }

  const resetValues: NotificationsMedecinFilterData = {
    motCle: '',
    dateDebut: null,
    dateFin: null,
    types: null,
    codeEntreprise: null,
    typesExclus: ['MEL_MED', 'REL_FAC'],
    lu: ['lue', 'nonLue'],
  }

  const {
    handleSubmit,
    control,
    setError,
    getValues,
    formState: { isValid },
    reset,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })
  useErrorFormMapper(searchError, setError, getValues)

  // state management
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const onSubmit = async (data: NotificationsMedecinFilterData) => {
    const filtre = {
      motCle: trimToUndefined(data.motCle),
      dateDebut: formatDateTimeISO(data.dateDebut),
      dateFin: formatDateTimeISO(data.dateFin),
      types: emptyToUndefined(data.types),
      typesExclus: ['MEL_MED', 'REL_FAC'],
      codeEntreprise: trimToUndefined(data.codeEntreprise?.code),
      lu: determineLuNonLuValue(data),
      tous: determineTousValue(data),
    } as NotificationMedecinFilter

    search(filtre)
    dispatch(setNotificationCriteria({ ...filtre, labelEntreprise: trimToUndefined(data.codeEntreprise?.label) }))
  }

  const handleClickInitSearch = () => {
    const filtre = {
      motCle: undefined,
      dateDebut: undefined,
      dateFin: undefined,
      types: undefined,
      typesExclus: ['MEL_MED', 'REL_FAC' as TypeMessageNotification],
      codeEntreprise: undefined,
      lu: undefined,
      tous: undefined,
    } as NotificationMedecinFilter

    reset(resetValues)
    dispatch(setNotificationCriteria(filtre))
    dispatch(setNotificationDossierSort({}))
    search(filtre)
  }

  // Snackbar handlers :
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const typeNotificationFilter = (ref: ReferentielTypeMessageNotification) => ref.tags.includes('MEDECIN')

  const determineLuNonLuValue = (data: NotificationsMedecinFilterData) => {
    if (data.lu.length === (0 || 2)) {
      return undefined
    }
    return data.lu[0] === 'lue' ? 1 : 0
  }

  const determineTousValue = (data: NotificationsMedecinFilterData) => {
    if (data.types !== null && data.types.length > 0) {
      return 1
    }
    return undefined
  }

  return (
    <Card>
      <CardHeader title="Recherche avancée" />
      <CardContent sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-notifications">
          <Grid container>
            <Grid item xs={12}>
              <Controller
                name="motCle"
                control={control}
                defaultValue={initialValues.motCle}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="motCle"
                    label="Mots Clés"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Controller
                name="types"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectInputReferentiel
                    id="types"
                    label="Type de notification"
                    value={value}
                    onBlur={onBlur}
                    onChangeMultiple={onChange}
                    multiple
                    referentielName="typeMessageNotification"
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                    dataFilter={typeNotificationFilter}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Controller
                name="codeEntreprise"
                control={control}
                defaultValue={initialValues.codeEntreprise}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <AutocompleteEntreprise
                    id="entreprise"
                    label="Entreprise"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    useQuery={useGetReferentielEntrepriseQuery}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Controller
                name="lu"
                control={control}
                defaultValue={initialValues.lu}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInput
                    multiple
                    fullWidth
                    id="statut"
                    label="Statut"
                    withAllSelectionItem
                    options={[
                      { code: 'lue', label: 'Lue' },
                      { code: 'nonLue', label: 'Non lue' },
                    ]}
                    value={value}
                    onBlur={onBlur}
                    onChangeMultiple={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="dateDebut"
                control={control}
                defaultValue={initialValues.dateDebut}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DateTimePickerInput
                    InputProps={{
                      id: 'date-debut',
                      fullWidth: true,
                    }}
                    label="Date de début"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="dateFin"
                control={control}
                defaultValue={initialValues.dateFin}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DateTimePickerInput
                    InputProps={{
                      id: 'date-fin',
                      fullWidth: true,
                    }}
                    label="Date de fin"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <ButtonsStack>
                <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                  Réinitialiser
                </RechargerButton>
                <ChercherButton
                  variant="contained"
                  type="submit"
                  form="form-recherche-notifications"
                  disabled={!isValid}
                >
                  Rechercher
                </ChercherButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={openSnackbar} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            Erreur serveur lors de la recherche des habilitations
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  )
}

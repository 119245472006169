export const LOCALE = 'fr-FR'

export const PAGE_PADDING_Y = 2
export const PAGE_PADDING_X = 2
export const PAGE_LARGE_PADDING_X = 8

export const MSG_ERROR_CHARGEMENT_DES_DONNEES = 'Une erreur s’est produite lors du chargement des données'

export const MAX_SNACKBAR = 4

export const MAX_UPLOAD_SIZE = 20 * 1024 * 1024
export const ALLOWED_UPLOAD_FORMATS = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'image/bmp': ['.bmp'],
  'image/tiff': ['.tif', '.tiff'],
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/octet-stream': [],
}

export const DOMMAGES_SEPTIEME_MARKS = [
  {
    value: 0.5,
    label: '0.5°',
  },
  {
    value: 1,
    label: '1°',
  },
  {
    value: 2,
    label: '2°',
  },
  {
    value: 3,
    label: '3°',
  },
  {
    value: 4,
    label: '4°',
  },
  {
    value: 5,
    label: '5°',
  },
  {
    value: 6,
    label: '6°',
  },
  {
    value: 7,
    label: '7°',
  },
]

export const MAIL_ASSISTANCE = 'assistance-transmed@agira.asso.fr'

// La duree maximale du cache des endoinds du tableau de bord en seconde :
export const TABLEAU_DE_BORD_MAX_TIME_CACHE = 30
// La duree maximale du cache des endoinds des historiques des dossiers en seconde :
// TODO a remplacer par un reset du tag
export const HISTORIQUE_DOSSIERS_MAX_TIME_CACHE = 3

// Le code de l'option aucune selection
export const NO_OPTION_CODE = ''

// Le code de l'option tout selectionner
export const ALL_OPTION_CODE = 'ALL'

// Le code de l'option affectation centrale pour la recherche dossier
export const AFFECTATION_CENTRALE_CODE = 'AFFECTATION_CENTRALE'

// Liste de couleurs claires pour police d'ecriture de couleur noir
export const BACKGROUND_COLORS = [
  '#ef9a9a',
  '#f48fb1',
  '#ce93d8',
  '#b39ddb',
  '#9fa8da',
  '#90caf9',
  '#81d4fa',
  '#80deea',
  '#80cbc4',
  '#a5d6a7',
  '#c5e1a5',
  '#e6ee9c',
  '#fff59d',
  '#ffe082',
  '#ffcc80',
  '#ffab91',
  '#bcaaa4',
  '#eeeeee',
  '#b0bec5',
]

export const AFFECTATION_CENTRALE_LABEL = 'Affectation centrale'

export const MSG_FIELD_REQUIRED = 'Doit être renseigné'

export const MSG_OPTION_REQUIRED = `La sélection d'une option est obligatoire.`

export const DEFAULT_OPTION = {
  code: NO_OPTION_CODE,
  label: 'Aucune sélection',
}

export const DEFAULT_OPTION_WITHOUT_LABEL = {
  code: NO_OPTION_CODE,
  label: '',
}

export const ALL_OPTION = {
  code: ALL_OPTION_CODE,
  label: 'Tout sélectionner',
}

export const AFFECTATION_CENTRALE_VALUE_OPTION = {
  code: AFFECTATION_CENTRALE_CODE,
  label: AFFECTATION_CENTRALE_LABEL,
}

export const DEFAULT_MAX_PERIODES = 5

export const DOMMAGES_PERIODES_MAX_ELIGIBLES = [
  'DATES_HOSPITALISATION_IMPUTABLES',
  'GENE_TEMPORAIRE_TOTALE',
  'DATES_ARRETS_DE_TRAVAIL_IMPUTABLES',
  'TIERCE_PERSONNE_TEMPORAIRE',
  'TIERCE_PERSONNE_DEFINITIVE',
  'GENE_TEMPORAIRE_PARTIELLE_CLASSE_1',
  'GENE_TEMPORAIRE_PARTIELLE_CLASSE_2',
  'GENE_TEMPORAIRE_PARTIELLE_CLASSE_3',
  'GENE_TEMPORAIRE_PARTIELLE_CLASSE_4',
]

export const DOMMAGES_COMMENTAIRES_MAX_ELIGIBLES = [
  'DATES_HOSPITALISATION_IMPUTABLES',
  'DATES_ARRETS_DE_TRAVAIL_IMPUTABLES',
]

export const OPTION_GROUPE = 'groupe'

// cette liste de notification silencieuse doit etre amélioré avec le ticket : TRANSMED-1797
// elle doit provenir du back afin d'assurer le synchronisation des types de notification silencieuse coté back et front
export const NOTIFICATIONS_SILENCIEUSES = [
  'CLO_DOS',
  'CREA_DOS',
  'VAL_DOS',
  'ANN_DOS',
  'ROUV_DOS',
  'CREA_HAB',
  'PUR_DOS',
  'MAJ_DOS',
  'MAJ_FAC',
  'SS_SUI_MIS',
  'CLO_MIS',
  'ARR_MIS',
  'ANN_MIS',
  'PROP_MIS',
  'SUP_DOC_ENT',
  'DMD_MAJ_CONC',
  'DEP_DOC_ENT',
]

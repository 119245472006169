import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { Chip, Grid, IconButton, Stack } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { useAppDispatch } from 'plateforme/store/hooks/hooks'
import { UploadState } from 'plateforme/store/slices/uploadSlice'
import { CircularProgressWithLabel, ReadOnlyTextField } from 'plateforme/components'
import { DocumentUpload } from 'plateforme/store/types/documentUpload'
import {
  removeDocumentMessageUpload,
  setDocumentMessageUploadValid,
} from 'medecin/store/slices/document/documentMessageUploadSlice'

export default React.memo(UploadRowMessagePJ)

type UploadRowMessagePJProps = {
  documentUpload: DocumentUpload
  disabled?: boolean
}

function UploadRowMessagePJ({ documentUpload, disabled: disabledFromParent }: UploadRowMessagePJProps) {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { getFieldState, clearErrors } = useFormContext()
  const isNomDocumentMessage = getFieldState('nomsFichiers').error === undefined

  useEffect(() => {
    dispatch(setDocumentMessageUploadValid({ key: documentUpload.key, isValid: isNomDocumentMessage }))
  }, [dispatch, documentUpload.key, isNomDocumentMessage])

  useEffect(() => {
    const status = documentUpload?.error?.status
    if (status === 500) {
      enqueueSnackbar(`${documentUpload?.error?.message}`, {
        variant: 'error',
      })
    }
  }, [documentUpload?.error, enqueueSnackbar])

  const disabled = disabledFromParent || documentUpload.state === UploadState.DONE

  const onRemove = () => {
    dispatch(removeDocumentMessageUpload(documentUpload.key))
    clearErrors('nomsFichiers')
  }

  return (
    <Grid container>
      <Grid item xs={10}>
        <ReadOnlyTextField fullWidth label="Nom du fichier" value={documentUpload.nomFichier} disabled={disabled} />
      </Grid>
      <Grid item xs={2} alignItems="center">
        <Stack direction="row" alignItems="top" marginTop={1.5}>
          {!disabled && documentUpload.state === UploadState.IDLE && (
            <IconButton color="error" onClick={onRemove}>
              <Delete />
            </IconButton>
          )}
          {documentUpload.state === UploadState.IN_PROGRESS && (
            <CircularProgressWithLabel value={documentUpload.progress} />
          )}
          {documentUpload.state === UploadState.DONE && !documentUpload.error && (
            <Chip label="succès" color="success" />
          )}
          {documentUpload.error && <Chip label="erreur" color="error" />}
        </Stack>
      </Grid>
    </Grid>
  )
}

import React from 'react'
import { Stack } from '@mui/material'
import NavigationPart from 'plateforme/parts/NavigationPart'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'
import { useSelector } from 'react-redux'
import { useGetRechercheDossierAdminQuery } from 'admin/store/apis/dossierAdminApi'
import RechercheDossierAdminPart from 'admin/part/rechercheDossierAdmin/RechercheDossierAdminPart'
import ListeDossierAdminPart from 'admin/part/rechercheDossierAdmin/ListeDossierAdminPart'
import { getDossierCriteria } from 'admin/store/slices/searchCriteriaSlice/searchCriteriaAdminSlice'
import { getDossierSort } from 'admin/store/slices/sortSlice/sortSlice'
import useErrorSnackBar from 'plateforme/hooks/useErrorSnackBar'

export default function RechercheDossierAdminPage() {
  // get default values of filter and sort params from slice
  const defaultDossierCriteria = useSelector(getDossierCriteria)
  const defaultDossierSort = useSelector(getDossierSort)
  const {
    combinedData,
    exportParams,
    readMore,
    remoteTotal,
    search,
    initSort,
    sortBy,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetRechercheDossierAdminQuery, {
    defaultCriteria: defaultDossierCriteria,
    defaultSort: defaultDossierSort,
  })

  useErrorSnackBar(error, isError, 'La Recherche des dossiers a échoué')

  return (
    <Stack>
      <NavigationPart label="Liste des dossiers " />
      <RechercheDossierAdminPart search={search} searchError={error} />
      <ListeDossierAdminPart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        dossiers={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        isSuccess={isSuccess}
        exportParams={exportParams}
      />
    </Stack>
  )
}

import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Card, CardContent, CardHeader, Grid, Snackbar } from '@mui/material'
import {
  ButtonsStack,
  ChercherButton,
  DateTimePickerInput,
  EditTextField,
  RechargerButton,
  SelectInputReferentiel,
  SelectOption,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { formatDateTimeISO } from 'plateforme/services/dates.services'
import { emptyToUndefined, trimToUndefined } from 'plateforme/services/utils'
import { ReferentielTypeMessageNotification, TypeMessageNotification } from 'plateforme/store/types/referentiel'
import { NotificationAdminFilter } from 'admin/store/types/notificationsAdmin'
import {
  getNotificationCriteria,
  setNotificationCriteria,
} from 'admin/store/slices/searchCriteriaSlice/searchCriteriaAdminSlice'
import { setNotificationDossierSort } from 'admin/store/slices/sortSlice/sortSlice'
import AutocompleteEntreprise from 'medecin/components/AutocompleteEntreprise'
import { useGetReferentielEntrepriseAdminQuery } from 'admin/store/apis/gestionEntrepriseApi'
import { useGetReferentielAdminMedecinQuery } from 'admin/store/apis/gestionMedecinApi'
import AutocompleteMedecin from 'assureur/components/AutocompleteMedecin'

type SearchNotificationsProps = {
  search: (criteria: NotificationAdminFilter) => void
  searchError: IQueryErrorResponse
}

export interface NotificationsFilterData {
  motCle: string | null
  dateDebut: Date | null
  dateFin: Date | null
  typeNotification: TypeMessageNotification[] | null
  codeEntreprise: SelectOption | null
  codeMedecin: SelectOption | null
}

export default function RechercheNotificationsDossierAdminPart({ search, searchError }: SearchNotificationsProps) {
  // get default values from localStorage
  const defaultNotificationCriteria = useSelector(getNotificationCriteria)
  const dispatch = useDispatch()

  // Les valeurs initiales du formulaire :
  const initialValues: NotificationsFilterData = {
    motCle: defaultNotificationCriteria?.motCle ?? '',
    dateDebut: defaultNotificationCriteria?.dateDebut ? new Date(defaultNotificationCriteria?.dateDebut) : null,
    dateFin: defaultNotificationCriteria?.dateFin ? new Date(defaultNotificationCriteria?.dateFin) : null,
    typeNotification: defaultNotificationCriteria?.types ?? null,
    codeEntreprise: defaultNotificationCriteria?.codeEntreprise
      ? ({
          code: defaultNotificationCriteria?.codeEntreprise,
          label: defaultNotificationCriteria?.labelEntreprise,
        } as SelectOption)
      : null,
    codeMedecin: defaultNotificationCriteria?.codeMedecin
      ? ({
          code: defaultNotificationCriteria?.codeMedecin,
          label: defaultNotificationCriteria?.labelMedecin,
        } as SelectOption)
      : null,
  }

  const resetValues: NotificationsFilterData = {
    motCle: '',
    dateDebut: null,
    dateFin: null,
    typeNotification: null,
    codeEntreprise: null,
    codeMedecin: null,
  }

  const {
    handleSubmit,
    control,
    setError,
    getValues,
    formState: { isValid },
    reset,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })
  useErrorFormMapper(searchError, setError, getValues)

  // state management
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const onSubmit = async (data: NotificationsFilterData) => {
    const filtre = {
      motCle: trimToUndefined(data.motCle),
      dateDebut: formatDateTimeISO(data.dateDebut),
      dateFin: formatDateTimeISO(data.dateFin),
      types: emptyToUndefined(data.typeNotification),
      typesExclus: ['MEL_MED', 'ACTIV_MED', 'SUP_MED', 'SUSP_MED'],
      codeMedecin: trimToUndefined(data.codeMedecin?.code),
      codeEntreprise: trimToUndefined(data.codeEntreprise?.code),
    } as NotificationAdminFilter
    search(filtre)
    dispatch(
      setNotificationCriteria({
        ...filtre,
        labelEntreprise: trimToUndefined(data.codeEntreprise?.label),
        labelMedecin: trimToUndefined(data.codeMedecin?.label),
      })
    )
  }

  const handleClickInitSearch = () => {
    const filtre = {
      motCle: undefined,
      dateDebut: undefined,
      dateFin: undefined,
      typesExclus: ['MEL_MED', 'ACTIV_MED', 'SUP_MED', 'SUSP_MED' as TypeMessageNotification],
      codeMedecin: undefined,
      codeEntreprise: undefined,
    } as NotificationAdminFilter

    reset(resetValues)
    dispatch(setNotificationCriteria(filtre))
    dispatch(setNotificationDossierSort({}))
    search(filtre)
  }

  // Snackbar handlers :
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const typeNotificationFilter = (ref: ReferentielTypeMessageNotification) =>
    !['MEL_MED', 'ACTIV_MED', 'SUP_MED', 'SUSP_MED'].includes(ref.code)

  return (
    <Card>
      <CardHeader title="Recherche avancée" />
      <CardContent sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-notifications">
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="motCle"
                control={control}
                defaultValue={initialValues.motCle}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="motCle"
                    label="Mots clés"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="dateDebut"
                control={control}
                defaultValue={initialValues.dateDebut}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DateTimePickerInput
                    InputProps={{
                      id: 'date-debut',
                      fullWidth: true,
                    }}
                    label="Date de début"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="dateFin"
                control={control}
                defaultValue={initialValues.dateFin}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DateTimePickerInput
                    InputProps={{
                      id: 'date-fin',
                      fullWidth: true,
                    }}
                    label="Date de fin"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="typeNotification"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectInputReferentiel
                    id="type-Notification"
                    label="Type de notification"
                    value={value}
                    onBlur={onBlur}
                    onChangeMultiple={onChange}
                    multiple
                    referentielName="typeMessageNotification"
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                    dataFilter={typeNotificationFilter}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="codeEntreprise"
                control={control}
                defaultValue={initialValues.codeEntreprise}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <AutocompleteEntreprise
                    id="entreprise"
                    label="Entreprise notifiée"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    useQuery={useGetReferentielEntrepriseAdminQuery}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="codeMedecin"
                control={control}
                defaultValue={initialValues.codeMedecin}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <AutocompleteMedecin
                    id="medecin"
                    label="Médecin notifié"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    useQuery={useGetReferentielAdminMedecinQuery}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonsStack>
                <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                  Réinitialiser
                </RechargerButton>
                <ChercherButton
                  variant="contained"
                  type="submit"
                  form="form-recherche-notifications"
                  disabled={!isValid}
                >
                  Rechercher
                </ChercherButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={openSnackbar} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            Erreur serveur lors de la recherche des habilitations
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  )
}

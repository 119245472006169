import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface ProfilState {
  id?: string
  groupeIds?: string[]
  isProfilGroupe: boolean
  groupeName?: string
}

const initialState: ProfilState = {
  id: undefined,
  groupeIds: undefined,
  isProfilGroupe: false,
  groupeName: undefined,
}

export const profilSlice = createSlice({
  name: 'profil',
  initialState,
  reducers: {
    setIsProfilGroupe: (state, action: PayloadAction<boolean>) => {
      state.isProfilGroupe = action.payload
    },
    chooseGroupeProfil: (state, action: PayloadAction<string[] | undefined>) => {
      state.groupeIds = action.payload
    },
    chooseProfil: (state, action: PayloadAction<string>) => {
      state.id = action.payload
    },
    setGroupeName: (state, action: PayloadAction<string | undefined>) => {
      state.groupeName = action.payload
    },
    reset: (state, _action: PayloadAction) => {
      state.id = undefined
    },
  },
})

export const { setIsProfilGroupe, chooseGroupeProfil, chooseProfil, setGroupeName, reset } = profilSlice.actions

export const getProfilSelectionne = (state: RootState) => state.profil?.id
export const getGroupeProfilSelectionne = (state: RootState) => state.profil?.groupeIds
export const getIsProfilGroupe = (state: RootState) => state.profil?.isProfilGroupe
export const getGroupeName = (state: RootState) => state.profil?.groupeName

export default profilSlice.reducer

import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { useAppDispatch } from 'plateforme/store/hooks/hooks'
import { UploadState } from 'plateforme/store/slices/uploadSlice'
import { ReadOnlyTextField } from 'plateforme/components'
import { setDocumentFactureUploadValid } from 'medecin/store/slices/document/documentFactureUploadSlice'
import { DocumentUpload } from 'plateforme/store/types/documentUpload'

export default React.memo(UploadRowFacture)

type UploadRowFactureProps = {
  documentUpload: DocumentUpload
  disabled?: boolean
}

function UploadRowFacture({ documentUpload, disabled: disabledFromParent }: UploadRowFactureProps) {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { getFieldState } = useFormContext()

  const isNomFacture = getFieldState('file').error === undefined

  useEffect(() => {
    dispatch(setDocumentFactureUploadValid({ key: documentUpload.key, isValid: isNomFacture }))
  }, [dispatch, documentUpload.key, isNomFacture])

  useEffect(() => {
    const status = documentUpload?.error?.status
    if (status === 500) {
      enqueueSnackbar(`${documentUpload?.error?.message}`, {
        variant: 'error',
      })
    }
  }, [documentUpload?.error, enqueueSnackbar])

  const disabled = disabledFromParent || documentUpload.state === UploadState.DONE

  return <ReadOnlyTextField fullWidth label="Nom du fichier" value={documentUpload.nomFichier} disabled={disabled} />
}

import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Stack } from '@mui/material'
import {
  AjouterButton,
  AnnulerButton,
  AreaLoading,
  ButtonsStack,
  DatePickerInput,
  EditTextField,
  ModifierButton,
  ReadOnlyTextField,
  SupprimerButton,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { formatDateISO, isDateAfterNow } from 'plateforme/services/dates.services'
import HabilitationEntreprise, {
  CreerHabilitationRequest,
  ModifierHabilitationRequest,
} from 'assureur/store/types/habilitationEntreprise'
import {
  usePostCreerHabilitationMutation,
  usePutModifierHabilitationMutation,
} from 'assureur/store/apis/habilitationEntrepriseApi'
import { useGetProfilEntrepriseQuery } from 'assureur/store/apis/profilEntrepriseApi'
import { trimToUndefined } from 'plateforme/services/utils'
import MedecinEntreprise from 'assureur/store/types/medecinEntreprise'

export enum OperationHabilitationForm {
  'AJOUTER_HABILITATION',
  'CLOTURER_HABILITATION',
  'MODIFIER_HABILITATION',
}

interface HabilitationMedecinFormProps {
  medecin: MedecinEntreprise
  typeOpration: OperationHabilitationForm
  habilitation?: HabilitationEntreprise
  refetchHabilitations: VoidFunction
  onClose: VoidFunction
}

interface DataHabilitationMedecinForm {
  numeroHabilitation: string
  intervenantOccasionnel: boolean
  expertiseSurPiece: boolean
  dateDebut: Date | null
  dateFin: Date | null
}

export default function HabilitationMedecinForm({
  medecin,
  typeOpration,
  habilitation,
  onClose,
  refetchHabilitations,
}: HabilitationMedecinFormProps) {
  const { code: codeMedecin, libelle: identiteMedecin } = medecin
  const isModifierHabilitation = typeOpration === OperationHabilitationForm.MODIFIER_HABILITATION
  const isCloturerHabilitation = typeOpration === OperationHabilitationForm.CLOTURER_HABILITATION
  const now = new Date()

  const {
    data: profilEntreprise,
    isLoading: isLoadingProfilEntreprise,
    isFetching: isFetchingProfilEntreprise,
  } = useGetProfilEntrepriseQuery()

  const {
    control,
    getValues,
    setError,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      numeroHabilitation: habilitation?.numeroHabilitation ?? null,
      intervenantOccasionnel: habilitation?.intervenantOccasionnel ?? false,
      expertiseSurPiece: habilitation?.expertiseSurPiece ?? false,
      dateDebut:
        isCloturerHabilitation && isDateAfterNow(habilitation?.dateDebut) ? now : habilitation?.dateDebut ?? null,
      dateFin: isCloturerHabilitation ? new Date() : habilitation?.dateFin ?? null,
    },
  })

  const [postCreerHabilitation, { error: errorCreationHabilitation, isLoading: isLoadingCreationHabilitation }] =
    usePostCreerHabilitationMutation()

  const [
    putModifierHabilitation,
    { error: errorModificationHabilitation, isLoading: isLoadingModificationHabilitation },
  ] = usePutModifierHabilitationMutation()

  const errorResponse = errorCreationHabilitation ?? errorModificationHabilitation
  useErrorFormMapper(errorResponse as IQueryErrorResponse, setError, getValues)
  const { enqueueSnackbar } = useSnackbar()

  const dateDebutReadOnly =
    (isModifierHabilitation && !isDateAfterNow(habilitation?.dateDebut)) || isCloturerHabilitation

  const dateFinReadOnly = isCloturerHabilitation

  const onCloturerHabilitation = async () => {
    const request = {
      codeMedecin,
      dateDebut: isDateAfterNow(habilitation?.dateDebut) ? now : undefined,
      dateFin: formatDateISO(now),
      clotureImmediate: 1,
    } as ModifierHabilitationRequest

    await putModifierHabilitation(request)
      .unwrap()
      .then(() => {
        enqueueSnackbar(`L'habilitation a été clôturée avec succès`, { variant: 'success' })
        onClose()
        refetchHabilitations()
      })
      .catch(() => {
        enqueueSnackbar(`La clôture de l'habilitation a échoué`, { variant: 'error' })
      })
  }

  const onModifierHabilitation = async () => {
    const dataHabilitationForm = getValues() as DataHabilitationMedecinForm
    const request = {
      codeMedecin,
      dateDebut: !dateDebutReadOnly ? formatDateISO(dataHabilitationForm.dateDebut) : undefined,
      dateFin: !dateFinReadOnly ? formatDateISO(dataHabilitationForm.dateFin) : undefined,
      clotureImmediate: 0,
    } as ModifierHabilitationRequest

    await putModifierHabilitation(request)
      .unwrap()
      .then(() => {
        enqueueSnackbar(`L'habilitation a été modifiée avec succès`, { variant: 'success' })
        onClose()
        refetchHabilitations()
      })
      .catch(() => {
        enqueueSnackbar(`La modification de l'habilitation a échoué`, { variant: 'error' })
      })
  }

  const onAjouterHabilitation = async () => {
    const dataHabilitationForm = getValues() as DataHabilitationMedecinForm
    const request = {
      codeMedecin,
      numeroHabilitation: trimToUndefined(dataHabilitationForm.numeroHabilitation),
      intervenantOccasionnel: dataHabilitationForm.intervenantOccasionnel,
      expertiseSurPiece: dataHabilitationForm.expertiseSurPiece,
      dateDebut: formatDateISO(dataHabilitationForm.dateDebut),
      dateFin: formatDateISO(dataHabilitationForm.dateFin),
    } as CreerHabilitationRequest

    await postCreerHabilitation(request)
      .unwrap()
      .then(() => {
        enqueueSnackbar('Le médecin a été habilité avec succès', { variant: 'success' })
        onClose()
        refetchHabilitations()
      })
      .catch((response: IQueryErrorResponse) => {
        if (response.status === 412) {
          enqueueSnackbar(response.data?.message, { variant: 'error' })
        } else {
          enqueueSnackbar(`L'habilitation du médecin a échoué`, { variant: 'error' })
        }
      })
  }

  const parametrage = profilEntreprise?.parametrage

  const afficherExpertiseSurPiece = medecin?.expertiseSurPiece && parametrage?.expertiseSurPiece
  const afficherIntervenantOccasionnel = parametrage?.intervenantOccasionnel

  if (isLoadingProfilEntreprise || isFetchingProfilEntreprise) {
    return <AreaLoading />
  }

  return (
    <form id="form-acceptation-mission">
      <Grid container paddingTop={0}>
        <Grid item xs={6}>
          <ReadOnlyTextField fullWidth id="id-identite-medecin" value={identiteMedecin} label="Identité du médecin" />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="numeroHabilitation"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                fullWidth
                id="numero-habilitation"
                label="Numéro  Habilitation"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                readOnly={isModifierHabilitation || isCloturerHabilitation}
                fieldError={error}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="dateDebut"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <DatePickerInput
                label="Date de début d'habilitation"
                InputProps={{
                  id: 'date-debut',
                  fullWidth: true,
                }}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                readOnly={dateDebutReadOnly}
                fieldError={error}
              />
            )}
            rules={{
              validate: (value: Date | null) => {
                if (!dateDebutReadOnly && value === null) {
                  return 'Doit être renseigné'
                }
                return true
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="dateFin"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <DatePickerInput
                label="Date de fin d'habilitation"
                InputProps={{
                  id: 'date-fin',
                  fullWidth: true,
                }}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                readOnly={dateFinReadOnly}
                fieldError={error}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Stack direction="column" alignItems="flex-start" justifyContent="center" spacing={0}>
            {afficherIntervenantOccasionnel && (
              <Controller
                name="intervenantOccasionnel"
                control={control}
                defaultValue={habilitation?.intervenantOccasionnel}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <FormControl disabled={isModifierHabilitation || isCloturerHabilitation} error={error !== undefined}>
                    <FormGroup>
                      <FormControlLabel
                        label="Intervenant occasionnel"
                        sx={{ height: 30 }}
                        control={
                          <Checkbox
                            name="intervenantOccasionnel"
                            checked={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            sx={{ padding: 0, marginX: 1.5 }}
                          />
                        }
                      />
                    </FormGroup>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            )}
            {afficherExpertiseSurPiece && (
              <Controller
                name="expertiseSurPiece"
                control={control}
                defaultValue={habilitation?.expertiseSurPiece}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <FormControl disabled={isModifierHabilitation || isCloturerHabilitation} error={error !== undefined}>
                    <FormGroup>
                      <FormControlLabel
                        label="Avis technique sur pièce"
                        sx={{ height: 30 }}
                        control={
                          <Checkbox
                            name="expertiseSurPiece"
                            checked={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            sx={{ padding: 0, marginX: 1.5, display: 'block' }}
                          />
                        }
                      />
                    </FormGroup>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <ButtonsStack>
            <AnnulerButton onClick={onClose}>Annuler</AnnulerButton>
            {typeOpration === OperationHabilitationForm.AJOUTER_HABILITATION && (
              <AjouterButton
                disabled={!isValid}
                onClick={onAjouterHabilitation}
                loading={isLoadingCreationHabilitation}
              >
                Créer l&apos;habilitation
              </AjouterButton>
            )}
            {typeOpration === OperationHabilitationForm.MODIFIER_HABILITATION && (
              <ModifierButton
                disabled={!isValid}
                onClick={onModifierHabilitation}
                loading={isLoadingModificationHabilitation}
              >
                Modifier l&apos;habilitation en cours
              </ModifierButton>
            )}
            {typeOpration === OperationHabilitationForm.CLOTURER_HABILITATION && (
              <SupprimerButton
                disabled={!isValid}
                onClick={onCloturerHabilitation}
                loading={isLoadingModificationHabilitation}
              >
                Clôturer l&apos;habilitation en cours
              </SupprimerButton>
            )}
          </ButtonsStack>
        </Grid>
      </Grid>
    </form>
  )
}

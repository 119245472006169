import React from 'react'
import { useSelector } from 'react-redux'
import { useErrorHandler } from 'react-error-boundary'
import { Stack } from '@mui/material'
import { FallbackError } from 'plateforme/components'
import NavigationPart from 'plateforme/parts/NavigationPart'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'
import RechercheHabilitationsEntreprisePart from 'assureur/parts/rechercheHabilitation/RechercheHabilitationsEntreprisePart'
import { useGetListeHabilitationsActivesEntrepriseQuery } from 'assureur/store/apis/habilitationEntrepriseApi'
import ListeHabilitationsEntreprisePart from 'assureur/parts/rechercheHabilitation/ListeHabilitationsEntreprisePart'
import { useGetProfilEntrepriseQuery } from 'assureur/store/apis/profilEntrepriseApi'
import { getHabilitationCriteria } from 'assureur/store/slices/searchCriteriaSlice/searchCriteriaEntrepriseSlice'
import { getHabilitationSort } from 'assureur/store/slices/sortSlice/sortSlice'
import useErrorSnackBar from 'plateforme/hooks/useErrorSnackBar'

export default function RechercheHabilitationsEntreprisePage() {
  const { data: profilEntreprise, isError: isErrorProfil, error: errorProfil } = useGetProfilEntrepriseQuery()
  const handleError = useErrorHandler()

  // handle error of useGetProfilEntrepriseQuery:
  if (isErrorProfil) {
    handleError({
      errorApi: errorProfil,
      title: 'Erreur chargement des données profil',
      api: 'getProfilEntreprise',
    } as FallbackError)
  }

  // get default values of filter and sort params from slice
  const defaultHabilitationCriteria = useSelector(getHabilitationCriteria)
  const defaultHabilitationSort = useSelector(getHabilitationSort)

  const {
    combinedData,
    exportParams,
    readMore,
    remoteTotal,
    search,
    initSort,
    sortBy,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetListeHabilitationsActivesEntrepriseQuery, {
    defaultCriteria: defaultHabilitationCriteria,
    defaultSort: defaultHabilitationSort,
  })

  useErrorSnackBar(error, isError, 'La recherche des habilitations a échoué')

  return (
    <Stack>
      <NavigationPart label="Mon réseau de médecins" />
      <RechercheHabilitationsEntreprisePart search={search} searchError={error} profilEntreprise={profilEntreprise} />
      <ListeHabilitationsEntreprisePart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        combinedData={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        isSuccess={isSuccess}
        exportParams={exportParams}
      />
    </Stack>
  )
}

import React from 'react'
import { useSnackbar } from 'notistack'
import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material'
import { AnnulerButton, ButtonsStack, DatePickerInput, ModifierButton, ReadOnlyTextField } from 'plateforme/components'

import { formatDateISO, isDateAfterNow, isDateAfterOrNow } from 'plateforme/services/dates.services'
import HabilitationEntreprise, {
  HabilitationEntrepriseSortType,
  ModifierHabilitationRequest,
} from 'assureur/store/types/habilitationEntreprise'
import { usePutModifierHabilitationMutation } from 'assureur/store/apis/habilitationEntrepriseApi'
import { Controller, useForm } from 'react-hook-form'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { isAfter, isBefore, isEqual } from 'date-fns'
import { SortParam } from 'plateforme/store/types/pageRecherche'
import MedecinEntreprise from 'assureur/store/types/medecinEntreprise'

interface HabilitationMedecinFormProps {
  medecin: MedecinEntreprise
  habilitations?: HabilitationEntreprise[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetchHabilitations: (filter: any) => void
  onClose: VoidFunction
}

export interface EntreprisesGroupe {
  libelle: string
  code: string
  headerValue?: string
  intervenantOccasionnelParametrage?: boolean
  expertiseSurPieceParametrage?: boolean
}

export default function ModifierHabilitationMedecinGroupeForm({
  medecin,
  habilitations,
  onClose,
  refetchHabilitations,
}: HabilitationMedecinFormProps) {
  const { code: codeMedecin, libelle: identiteMedecin } = medecin
  const [putModifierHabilitation, { error: errorResponse, isLoading: isLoadingModificationHabilitation }] =
    usePutModifierHabilitationMutation()
  const { enqueueSnackbar } = useSnackbar()

  const {
    control,
    getValues,
    setError,
    formState: { isValid },
    watch,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      habilitationsForm: habilitations
        ? habilitations?.map((habilitation: HabilitationEntreprise) => ({
            id: habilitation.id,
            code: habilitation.code,
            libelleEntreprise: habilitation.libelleEntreprise,
            headerValue: habilitation.headerValue,
            dateDebut: habilitation.dateDebut ?? null,
            dateFin: habilitation.dateFin ?? null,
          }))
        : [],
    },
  })

  useErrorFormMapper(errorResponse as IQueryErrorResponse, setError, getValues)

  const onModifierHabilitation = async () => {
    const { habilitationsForm } = getValues()

    if (habilitationsForm && habilitationsForm.length > 0) {
      await Promise.allSettled(
        habilitationsForm.map((habilitation) => {
          const request = {
            codeMedecin,
            dateDebut: isDateAfterOrNow(habilitation?.dateDebut) ? formatDateISO(habilitation.dateDebut) : undefined,
            dateFin: formatDateISO(habilitation.dateFin),
            clotureImmediate: 0,
          } as ModifierHabilitationRequest
          return putModifierHabilitation({ headerGroupe: habilitation?.headerValue, ...request })
            .unwrap()
            .then(() => {
              enqueueSnackbar(`L'habilitation a été modifiée avec succès pour : ${habilitation.libelleEntreprise}`, {
                variant: 'success',
              })
              onClose()
            })
            .catch(() => {
              enqueueSnackbar(`La modification de l'habilitation a échoué`, { variant: 'error' })
            })
        })
      ).then(() => {
        refetchHabilitations({
          filter: { codeMedecin },
          sort: {
            sortType: HabilitationEntrepriseSortType.DATE_DEBUT_SORT,
            sortOrder: 'DESC',
          } as SortParam<HabilitationEntrepriseSortType>,
          perPage: 20,
          _timestamp: Date.now(),
        })
      })
    }
  }

  const checkDateFinValide = (dateFin: Date | null, index: number) => {
    if (dateFin !== null) {
      const dateDebut = watch(`habilitationsForm.${index}.dateDebut`) ?? ''
      const isDateFinPasse = !isDateAfterOrNow(dateFin)
      // NOTE : ici le parse avec new Date est important car la valeur initial de date debut n'est pas iso
      const isDateFinBeforeDateDebut =
        isBefore(new Date(dateFin), new Date(dateDebut)) &&
        !isEqual(new Date(dateFin).setHours(0, 0, 0, 0), new Date(dateDebut).setHours(0, 0, 0, 0))

      if (isDateFinPasse) {
        return 'La date doit être postérieure ou égale à la date du jour'
      }
      if (isDateFinBeforeDateDebut) {
        return 'La date de fin doit être antérieure à la date de début'
      }
    }
    return true
  }

  const checkDateDebutValide = (dateDebut: Date | null, index: number) => {
    if (dateDebut === null) {
      return 'Doit être renseigné'
    }
    const dateFin = watch(`habilitationsForm.${index}.dateFin`) ?? ''
    const isDebutFinPasse = !isDateAfterOrNow(dateDebut)
    // NOTE : ici le parse avec new Date est important car la valeur initial de date fin n'est pas iso
    const isDateDebutAfterDateFin =
      isAfter(new Date(dateDebut), new Date(dateFin)) &&
      !isEqual(new Date(dateDebut).setHours(0, 0, 0, 0), new Date(dateFin).setHours(0, 0, 0, 0))

    if (isDebutFinPasse) {
      return 'La date doit être postérieure ou égale à la date du jour'
    }
    if (isDateDebutAfterDateFin) {
      return 'La date de début doit être antérieure à la date de fin'
    }

    return true
  }

  return (
    <Grid container paddingTop={0}>
      <Grid item xs={12}>
        <ReadOnlyTextField fullWidth id="id-identite-medecin" value={identiteMedecin} label="Identité du médecin" />
      </Grid>
      {habilitations &&
        habilitations.map((habilitation, index) => (
          <Grid item container key={habilitation.id}>
            <Grid item xs={1.5} textAlign="left" sx={{ alignSelf: 'center' }}>
              <Typography variant="body2" color="readOnly.main">
                {habilitation.libelleEntreprise}
              </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <ReadOnlyTextField
                id="numHabilitation"
                label="Numéro Habilitation"
                value={habilitation.numeroHabilitation}
                fullWidth
              />
            </Grid>
            <Grid item xs={2.5}>
              <Controller
                name={`habilitationsForm.${index}.dateDebut`}
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DatePickerInput
                    label="Date de début d'habilitation"
                    InputProps={{
                      id: 'date-debut',
                      fullWidth: true,
                    }}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    readOnly={!isDateAfterNow(habilitation?.dateDebut)}
                    fieldError={error}
                  />
                )}
                rules={{
                  validate: (value: Date | null) =>
                    !isDateAfterNow(habilitation?.dateDebut) ? true : checkDateDebutValide(value, index),
                }}
              />
            </Grid>
            <Grid item xs={2.5}>
              <Controller
                name={`habilitationsForm.${index}.dateFin`}
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DatePickerInput
                    label="Date de fin d'habilitation"
                    InputProps={{
                      id: 'date-fin',
                      fullWidth: true,
                    }}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
                rules={{
                  validate: (value: Date | null) => checkDateFinValide(value, index),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    label="Intervenant occasionnel"
                    sx={{ height: 30 }}
                    control={
                      <Checkbox
                        disabled
                        name="intervenantOccasionnel"
                        checked={habilitation.intervenantOccasionnel}
                        sx={{ padding: 0, marginX: 1.5 }}
                      />
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    label="Avis technique sur pièce"
                    sx={{ height: 30 }}
                    control={
                      <Checkbox
                        disabled
                        name="expertiseSurPiece"
                        checked={habilitation.expertiseSurPiece}
                        sx={{ padding: 0, marginX: 1.5, display: 'bloc' }}
                      />
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        ))}
      <Grid item xs={12}>
        <ButtonsStack>
          <AnnulerButton onClick={onClose}>Annuler</AnnulerButton>
          <ModifierButton
            disabled={!isValid}
            onClick={onModifierHabilitation}
            loading={isLoadingModificationHabilitation}
          >
            Modifier les habilitations en cours
          </ModifierButton>
        </ButtonsStack>
      </Grid>
    </Grid>
  )
}

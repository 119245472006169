import React, { useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import {
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import {
  AjouterButton,
  EditTextField,
  NoRowsOverlay,
  PhoneNumberInput,
  SelectInput,
  SupprimerIconButton,
} from 'plateforme/components'
import { ServiceEntreprise } from 'plateforme/store/types/profilEntreprise'

export const STATUT_TYPES = [
  { code: 'Actif', label: 'Actif' },
  { code: 'Inactif', label: 'Inactif' },
]

interface CloisonnementActiveProps {
  fieldName: string
  readOnly?: boolean
}

export default function CloisonnementActive({ fieldName, readOnly }: CloisonnementActiveProps) {
  const { control, getFieldState, formState, clearErrors, getValues } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  })
  const [initialSize] = useState(fields.length)

  const { error: groupError } = getFieldState(fieldName, formState)

  const addOnClick = () => {
    clearErrors(fieldName)
    append({
      code: '',
      libelle: '',
      mail: '',
      telephone: '',
      statutLabel: 'Actif',
    })
  }

  const removeOnClick = (index: number) => {
    return () => {
      clearErrors(fieldName)
      remove(index)
    }
  }

  const isAlreadyRegistered = (index: number) => {
    return getValues(`${fieldName}.${index}.registered`)
  }

  const checkCodeUnique = (value: string, index: number) => {
    // liste sans le service en cours de saisie
    const services = getValues(`${fieldName}`).filter((_: ServiceEntreprise, i: number) => i !== index)

    if (services.some((c: ServiceEntreprise) => c.code === value)) {
      return 'Doit être unique'
    }
    return true
  }

  return (
    <>
      {!readOnly && (
        <Grid item xs={6} textAlign="right">
          <AjouterButton onClick={addOnClick} title="Ajouter un service">
            Ajouter un service
          </AjouterButton>
        </Grid>
      )}
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Code service</TableCell>
                <TableCell align="center">Libellé service</TableCell>
                <TableCell align="center">BAL service</TableCell>
                <TableCell align="center">Téléphone service</TableCell>
                <TableCell align="center">Statut</TableCell>
                {/* Action */}
                {fields.length !== initialSize && <TableCell align="center">&nbsp;</TableCell>}
              </TableRow>
            </TableHead>
            {fields.length > 0 ? (
              <TableBody>
                {fields.map((item, index) => {
                  return (
                    <TableRow key={`${item.id}`}>
                      <TableCell sx={{ borderBottom: 'none' }} component="th" scope="row">
                        <Controller
                          rules={{
                            validate: (value: string) => checkCodeUnique(value, index),
                          }}
                          name={`${fieldName}.${index}.code`}
                          control={control}
                          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                            <EditTextField
                              fullWidth
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              fieldError={error}
                              readOnly={readOnly || isAlreadyRegistered(index)}
                              label="Code"
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} component="th" scope="row">
                        <Controller
                          name={`${fieldName}.${index}.libelle`}
                          control={control}
                          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                            <EditTextField
                              fullWidth
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              fieldError={error}
                              readOnly={readOnly}
                              label="Libellé"
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} component="th" scope="row">
                        <Controller
                          name={`${fieldName}.${index}.mail`}
                          control={control}
                          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                            <EditTextField
                              fullWidth
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              fieldError={error}
                              readOnly={readOnly}
                              label="BAL service"
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} component="th" scope="row">
                        <Controller
                          name={`${fieldName}.${index}.telephone`}
                          control={control}
                          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                            <PhoneNumberInput
                              id={`${fieldName}.${index}.telephone`}
                              readOnly={readOnly}
                              label="Téléphone service"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              fieldError={error}
                              fullWidth
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} component="th" scope="row">
                        <Controller
                          name={`${fieldName}.${index}.statutLabel`}
                          control={control}
                          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                            <SelectInput
                              fullWidth
                              id={`${fieldName}.${index}.statutLabel`}
                              label="Statut"
                              value={value}
                              onBlur={onBlur}
                              onChange={onChange}
                              options={STATUT_TYPES}
                              fieldError={error}
                              readOnly={readOnly}
                            />
                          )}
                        />
                      </TableCell>
                      {!(readOnly || isAlreadyRegistered(index)) && (
                        <TableCell sx={{ borderBottom: 'none' }} component="th" scope="row">
                          <SupprimerIconButton onClick={removeOnClick(index)} />
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            ) : (
              <NoRowsOverlay />
            )}
          </Table>
        </TableContainer>
        {groupError?.message && (
          <FormControl
            disabled={readOnly}
            error={!!groupError}
            variant="standard"
            sx={{ display: 'flex', alignItems: 'flex-start' }}
          >
            <FormHelperText sx={{ marginLeft: '14px' }}>{groupError.message}</FormHelperText>
          </FormControl>
        )}
      </Grid>
    </>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Sort from '@mui/icons-material/Sort'
import { ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import { StyledMenuComponent } from 'plateforme/components'
import { SortOption, SortParam } from 'plateforme/store/types/pageRecherche'
import { faArrowDownAZ, faArrowDownZA } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'plateforme/store'

const orderIcon = (order?: boolean) =>
  order ? (
    <FontAwesomeIcon size="lg" color="#8b5dbc" icon={faArrowDownZA} />
  ) : (
    <FontAwesomeIcon size="lg" color="#8b5dbc" icon={faArrowDownAZ} />
  )
// the type of the local state sortOptions: localSortParams
export type SortOptionParam<T> = { label?: string; sortType?: T; isOrderAsc?: boolean; actif: boolean }

// the type of the props of the component SortComponentMenuPart
type SortComponentMenuProps<T> = {
  sortBy: (param: SortParam<T>) => void
  sortOptions: SortOption<T>[]
  disabled?: boolean
  initSort: VoidFunction
  setSortSlice: ActionCreatorWithPayload<SortParam<string>, string> // obligatoire
  getSortSlice: (state: RootState) => SortParam<string> | undefined // obligatoire
}

export default function SortComponentMenu({
  initSort,
  disabled,
  sortBy,
  sortOptions,
  setSortSlice,
  getSortSlice,
}: SortComponentMenuProps<any>) {
  // step: 1 set the initial paramsSort geted from the parent
  const initParamsSort: SortOptionParam<any>[] = sortOptions.map((p) => {
    return { ...p, isOrderAsc: true, actif: false }
  })
  const dispatch = useDispatch()
  const defaultSortParam = useSelector(getSortSlice)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [sortOptionParams, setSortOptionParams] = useState<SortOptionParam<any>[] | []>(
    initParamsSort.map((item) => {
      if (defaultSortParam !== undefined && defaultSortParam.sortType === item.sortType) {
        return { ...item, isOrderAsc: defaultSortParam.sortOrder === 'ASC', actif: true }
      }
      return item
    })
  )

  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleSortBy = (param: SortOptionParam<any>) => {
    sortBy({ sortType: param.sortType, sortOrder: `${param.isOrderAsc ? 'ASC' : 'DESC'}` })
    setSortOptionParams([
      ...sortOptionParams.map((sp) =>
        sp.sortType === param.sortType
          ? { ...param, isOrderAsc: !param.isOrderAsc, actif: true }
          : { ...sp, actif: false }
      ),
    ])
    dispatch(
      setSortSlice({
        sortType: param.sortType,
        sortOrder: `${param.isOrderAsc ? 'ASC' : 'DESC'}`,
      })
    )
    handleClose()
  }

  const handleInitClick = () => {
    initSort()
    setSortOptionParams([...sortOptionParams.map((sp) => ({ ...sp, actif: false }))])
    handleClose()
  }

  return (
    <>
      <Tooltip title="Trier par" placement="bottom">
        <Button
          id="sort-component-menu-part"
          color="info"
          aria-controls={open ? 'sort-component-menu-part' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          disabled={disabled}
        >
          <Sort />
        </Button>
      </Tooltip>
      <StyledMenuComponent
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem key="__init__" onClick={handleInitClick} disableRipple>
          <ListItemText>
            <i>Tri par défaut</i>
          </ListItemText>
        </MenuItem>
        {sortOptionParams?.map((sortParam) => (
          <MenuItem key={sortParam.label} onClick={() => handleSortBy(sortParam)} disableRipple>
            <ListItemText> {sortParam.label} </ListItemText>
            {sortParam.actif && (
              <ListItemIcon sx={{ marginLeft: '8px' }}>{orderIcon(sortParam?.isOrderAsc)}</ListItemIcon>
            )}
          </MenuItem>
        ))}
      </StyledMenuComponent>
    </>
  )
}

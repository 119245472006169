import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { ErrorField } from 'plateforme/components'
import InformationsContactRow from './InformationsContactRow'

interface InformationsContactPartProps {
  readOnly?: boolean
}

export default function InformationsContactPart({ readOnly }: InformationsContactPartProps) {
  const { watch, formState } = useFormContext()

  const contactsError = 'parametrage.contacts._error'

  return (
    <Card>
      <CardHeader title="Informations de contact dossier par défaut" />
      <CardContent>
        <Grid container>
          {watch('parametrage.naturesEvenements.BIRC') && (
            <InformationsContactRow
              controllerName="parametrage.contacts.BIRC"
              rowTitle="BADINTER IRCA"
              readOnly={readOnly}
            />
          )}
          {watch('parametrage.naturesEvenements.BNIR') && (
            <InformationsContactRow
              controllerName="parametrage.contacts.BNIR"
              rowTitle="BADINTER NON IRCA"
              readOnly={readOnly}
            />
          )}
          {watch('parametrage.naturesEvenements.RCGE') && (
            <InformationsContactRow
              controllerName="parametrage.contacts.RCGE"
              rowTitle="RC GENERALE"
              readOnly={readOnly}
            />
          )}
          {watch('parametrage.naturesEvenements.RCMD') && (
            <InformationsContactRow
              controllerName="parametrage.contacts.RCMD"
              rowTitle="RC MEDICALE"
              readOnly={readOnly}
            />
          )}
          {watch('parametrage.naturesEvenements.ASSC') && (
            <InformationsContactRow
              controllerName="parametrage.contacts.ASSC"
              rowTitle="ASS CONDUCTEUR"
              readOnly={readOnly}
            />
          )}
          {watch('parametrage.naturesEvenements.PREV') && (
            <InformationsContactRow
              controllerName="parametrage.contacts.PREV"
              rowTitle="PREVOYANCE"
              readOnly={readOnly}
            />
          )}
          {watch('parametrage.naturesEvenements.ACCV') && (
            <InformationsContactRow
              controllerName="parametrage.contacts.ACCV"
              rowTitle="ACCIDENT DE LA VIE"
              readOnly={readOnly}
            />
          )}
          {watch('parametrage.naturesEvenements.FAUT') && (
            <InformationsContactRow
              controllerName="parametrage.contacts.FAUT"
              rowTitle="FAUTE INEXCUSABLE"
              readOnly={readOnly}
            />
          )}
          {watch('parametrage.naturesEvenements.AUTR') && (
            <InformationsContactRow controllerName="parametrage.contacts.AUTR" rowTitle="AUTRE" readOnly={readOnly} />
          )}
          {formState.errors && Object.keys(formState.errors).length !== 0 && (
            <Grid item xs={12} color="error.main">
              <ErrorMessage
                name={contactsError}
                errors={formState.errors}
                render={({ message }) => <ErrorField message={message} />}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

import * as React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { AreaLoading, ButtonsStack, AjouterButton, ModifierButton, SupprimerButton } from 'plateforme/components'
import { StatutsHabilitation } from 'plateforme/store/types/habilitation'

import { formatDateFR } from 'plateforme/services/dates.services'
import { useGetListeHabilitationsEntrepriseQuery } from 'assureur/store/apis/habilitationEntrepriseApi'
import HabilitationEntreprise, { top100HabilitationRequest } from 'assureur/store/types/habilitationEntreprise'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import { habilitationModifiable } from 'assureur/services/rolesEntreprise.services'
import { LoadingProps } from 'plateforme/services/utils'
import MedecinEntreprise from 'assureur/store/types/medecinEntreprise'
import { StatutMedecin } from 'plateforme/services/medecin.services'
import HabilitationMedecinForm, { OperationHabilitationForm } from './HabilitationMedecinForm'

/* Page de la fiche médecin tableau habilitations déclarées */
export interface HabilitationsMedecinPartProps {
  medecin?: MedecinEntreprise
}

export interface HabilitationsMedecinPartLoadedProps {
  medecin: MedecinEntreprise
}

export default function HabilitationsMedecinPart({ medecin, loading }: HabilitationsMedecinPartProps & LoadingProps) {
  if (loading || medecin === undefined) {
    return <AreaLoading height={551} />
  }
  return <HabilitationsMedecinLoadedPart medecin={medecin} />
}

function HabilitationsMedecinLoadedPart({ medecin }: HabilitationsMedecinPartLoadedProps) {
  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  // get the user connected :
  const { data: utilisateur } = useGetUtilisateurActifQuery()
  const profilActif = utilisateur?.profilActif

  const {
    data: habilitationResponse,
    isLoading,
    isFetching,
    refetch: refetchHabilitations,
  } = useGetListeHabilitationsEntrepriseQuery(top100HabilitationRequest(medecin.code))

  if (isLoading || isFetching) {
    return <AreaLoading height={551} />
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const habilitations = habilitationResponse?.items

  const habilitationsEncours = habilitations
    ? habilitations?.filter((item: HabilitationEntreprise) =>
        [StatutsHabilitation.ACTIVE, StatutsHabilitation.ACTIVE_AU_FUTUR].includes(item?.statut as StatutsHabilitation)
      )
    : []

  const total = habilitations?.length ?? 0

  const hasHabilitationActive = habilitationsEncours?.length > 0

  const getSpecifites = (intervenantOccasionnel?: boolean, expertiseSurPiece?: boolean) => {
    return [intervenantOccasionnel && 'Intervenant occasionnel', expertiseSurPiece && 'Expertise sur pièces']
      .filter((item) => !!item)
      .join(' / ')
  }

  const getPeriode = (statut?: StatutsHabilitation, dateDebut?: Date, dateFin?: Date) => {
    switch (statut) {
      case StatutsHabilitation.ACTIVE:
        return `Active depuis le ${formatDateFR(dateDebut)} ${dateFin ? `jusqu'au ${formatDateFR(dateFin)}` : ''}`

      case StatutsHabilitation.ACTIVE_AU_FUTUR:
        return `Active à partir du ${formatDateFR(dateDebut)} ${dateFin ? `jusqu'au ${formatDateFR(dateFin)}` : ''}`

      case StatutsHabilitation.INACTIVE:
        return `Du ${formatDateFR(dateDebut)} ${dateFin ? `au ${formatDateFR(dateFin)}` : ''}`

      default:
        return ''
    }
  }

  const isMedecinActif = medecin.statut === StatutMedecin.ACTIF

  return (
    <Card>
      {confirmDialog}
      <CardHeader title="Habilitations déclarées pour ce médecin" />
      <CardContent>
        {habilitationModifiable(profilActif) && isMedecinActif && (
          <ButtonsStack withGutter>
            {hasHabilitationActive ? (
              <>
                <ModifierButton
                  onClick={() =>
                    confirm({
                      maxWidth: 'lg',
                      fullWidth: true,
                      withForm: true,
                      form: (
                        <HabilitationMedecinForm
                          medecin={medecin}
                          typeOpration={OperationHabilitationForm.MODIFIER_HABILITATION}
                          habilitation={habilitationsEncours.at(0)}
                          onClose={closeConfirmDialog}
                          refetchHabilitations={refetchHabilitations}
                        />
                      ),
                      confirmMsg: 'Êtes-vous sûr de vouloir modifier l’habilitation en cours ?',
                      title: 'Modifier l’habilitation en cours',
                    })
                  }
                >
                  Modifier l’habilitation en cours
                </ModifierButton>
                <SupprimerButton
                  onClick={() =>
                    confirm({
                      maxWidth: 'lg',
                      fullWidth: true,
                      withForm: true,
                      variant: 'delete',
                      form: (
                        <HabilitationMedecinForm
                          medecin={medecin}
                          typeOpration={OperationHabilitationForm.CLOTURER_HABILITATION}
                          habilitation={habilitationsEncours.at(0)}
                          onClose={closeConfirmDialog}
                          refetchHabilitations={refetchHabilitations}
                        />
                      ),
                      confirmMsg: 'Êtes-vous sûr de vouloir clôturer l’habilitation en cours ?',
                      title: 'Clôturer l’habilitation en cours',
                    })
                  }
                >
                  Clôturer l’habilitation en cours
                </SupprimerButton>
              </>
            ) : (
              <AjouterButton
                onClick={() =>
                  confirm({
                    maxWidth: 'lg',
                    fullWidth: true,
                    withForm: true,
                    form: (
                      <HabilitationMedecinForm
                        medecin={medecin}
                        typeOpration={OperationHabilitationForm.AJOUTER_HABILITATION}
                        onClose={closeConfirmDialog}
                        refetchHabilitations={refetchHabilitations}
                      />
                    ),
                    confirmMsg: 'Êtes-vous sûr de vouloir habiliter le médecin en cours ?',
                    title: 'Ajouter une habilitation',
                  })
                }
              >
                Ajouter une habilitation
              </AjouterButton>
            )}
          </ButtonsStack>
        )}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>N°. Habilitation</TableCell>
                <TableCell>Spécificités</TableCell>
                <TableCell>Période d&apos;habilitation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {habilitations
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: HabilitationEntreprise) => (
                  <TableRow key={`${row.numeroHabilitation} ${row.code}`}>
                    <TableCell sx={{ width: '20%' }}>{row.numeroHabilitation ?? row.code}</TableCell>
                    <TableCell sx={{ width: '30%' }}>
                      {getSpecifites(row.intervenantOccasionnel, row.expertiseSurPiece)}
                    </TableCell>
                    <TableCell sx={{ width: '40%' }}>{getPeriode(row?.statut, row?.dateDebut, row?.dateFin)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 50]}
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  )
}

import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { TypeRapport, TypeRapportConseil } from 'plateforme/store/types/rapportConclusion'
import { ErrorMessage } from '@hookform/error-message'
import { ErrorField } from 'plateforme/components'
import ParametrageRapportConseilConclusionsRow from './ParametrageRapportConseilConclusionsRow'

export const RAPPORT_CONSEIL_TYPES = [
  { code: TypeRapportConseil.DESACTIVE, label: 'Désactivé' },
  { code: TypeRapportConseil.FACULTATIF, label: 'Facultatif' },
  { code: TypeRapportConseil.OBLIGATOIRE, label: 'Obligatoire' },
]

interface ParametrageRapportConseilConclusionsPartProps {
  typeConclusion: TypeRapport.CONCLUSION_PROVISOIRE | TypeRapport.CONCLUSION_DEFINITIVE
  readOnly?: boolean
}

export default function ParametrageRapportConseilConclusionsPart({
  typeConclusion,
  readOnly,
}: ParametrageRapportConseilConclusionsPartProps) {
  const { formState, watch } = useFormContext()

  const controllerName =
    typeConclusion === TypeRapport.CONCLUSION_PROVISOIRE ? 'typesRapportConseilPro' : 'typesRapportConseilDef'

  const typesRapportConseilError = `parametrage.${controllerName}._error`
  const libelleConclusion =
    typeConclusion === TypeRapport.CONCLUSION_PROVISOIRE ? 'conclusions provisoires' : 'conclusions définitives'

  return (
    <Card>
      <CardHeader title={`Paramétrage de rapport de conseil des ${libelleConclusion}`} />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Expertise unilatérale</TableCell>
                    <TableCell>Expertise conjointe</TableCell>
                    <TableCell>Ass. Exp judiciaire</TableCell>
                    <TableCell>Ass. Arbitrage</TableCell>
                    {watch('parametrage.expertiseSurPiece') && <TableCell>Avis techniques sur pièces</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {watch('parametrage.naturesEvenements.BIRC') && (
                    <ParametrageRapportConseilConclusionsRow
                      controllerName={`${controllerName}.BIRC`}
                      rowTitle="BADINTER IRCA"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.BNIR') && (
                    <ParametrageRapportConseilConclusionsRow
                      controllerName={`${controllerName}.BNIR`}
                      rowTitle="BADINTER NON IRCA"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.RCGE') && (
                    <ParametrageRapportConseilConclusionsRow
                      controllerName={`${controllerName}.RCGE`}
                      rowTitle="RC GENERALE"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.RCMD') && (
                    <ParametrageRapportConseilConclusionsRow
                      controllerName={`${controllerName}.RCMD`}
                      rowTitle="RC MEDICALE"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.ASSC') && (
                    <ParametrageRapportConseilConclusionsRow
                      controllerName={`${controllerName}.ASSC`}
                      rowTitle="ASS CONDUCTEUR"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.PREV') && (
                    <ParametrageRapportConseilConclusionsRow
                      controllerName={`${controllerName}.PREV`}
                      rowTitle="PREVOYANCE"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.ACCV') && (
                    <ParametrageRapportConseilConclusionsRow
                      controllerName={`${controllerName}.ACCV`}
                      rowTitle="ACCIDENT DE LA VIE"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.FAUT') && (
                    <ParametrageRapportConseilConclusionsRow
                      controllerName={`${controllerName}.FAUT`}
                      rowTitle="FAUTE INEXCUSABLE"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.AUTR') && (
                    <ParametrageRapportConseilConclusionsRow
                      controllerName={`${controllerName}.AUTR`}
                      rowTitle="AUTRE"
                      readOnly={readOnly}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {formState.errors && Object.keys(formState.errors).length !== 0 && (
            <Grid item xs={12} color="error.main">
              <ErrorMessage
                name={typesRapportConseilError}
                errors={formState.errors}
                render={({ message }) => <ErrorField message={message} />}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

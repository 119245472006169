import React, { useState } from 'react'

import { AjouterButton, AreaLoading } from 'plateforme/components'
import { Alert, Stack } from '@mui/material'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionEntreprise from 'medecin/store/types/missionMedecin'
import { isValideDefinitivement } from 'plateforme/services/rapport.services'
import { StatutDossier } from 'plateforme/store/types/dossier'
import FactureForm from './FactureForm'
import FactureMissionCard from './FactureMissionCard'

type TabFacturePartProps = {
  dossier?: DossierMedecin
  mission?: MissionEntreprise
  loading?: boolean
}

export default function TabFactureMedecinPart({ dossier, mission, loading }: TabFacturePartProps) {
  const [afficherFormulaire, setAfficherFormulaire] = useState(
    !dossier?.missions
      ?.filter((missionMedecin) => missionMedecin.associable)
      .some((m) => m.associable === true && (m.factures?.length ?? 0) > 0)
  )

  // aucune facture sur toutes les missions possibles du médecin ?
  const aucuneFacture = !dossier?.missions
    ?.filter((missionMedecin) => missionMedecin.associable)
    .some((m) => m.factures)

  // affiche message "aucune facture déposée" si aucune facture ET dossier clôturé
  const showMessageAucuneFacture = aucuneFacture && dossier?.statut === StatutDossier.CLOTURE

  // affiche le formulaire de dépot de facture si le dossier n'est pas cloturé et
  // mission associable au médecin et facturable ou rapport validé définitivement
  const readOnly = !(
    dossier?.statut !== StatutDossier.CLOTURE &&
    (dossier?.missions
      ?.filter((missionMedecin) => missionMedecin.associable)
      .some((m) => m.facturable || isValideDefinitivement(m?.rapport)) ??
      false)
  )

  return (
    <Stack>
      {showMessageAucuneFacture && (
        <Alert severity="info" sx={{ width: '100%' }}>
          Aucune facture déposée
        </Alert>
      )}
      {!readOnly && (
        <Stack direction="row" justifyContent="right">
          <AjouterButton disabled={afficherFormulaire} onClick={() => setAfficherFormulaire(true)}>
            Ajouter une facture
          </AjouterButton>
        </Stack>
      )}
      {!readOnly && afficherFormulaire && dossier && mission && (
        <FactureForm cacherFormulaire={() => setAfficherFormulaire(false)} dossier={dossier} />
      )}
      {loading ? (
        <AreaLoading height={540} />
      ) : (
        dossier &&
        dossier?.missions
          ?.filter((m) => m.associable && (m.factures?.length ?? 0) > 0)
          .map((m) => (
            <Stack key={m.code}>
              <FactureMissionCard dossier={dossier} mission={m} />
            </Stack>
          ))
      )}
    </Stack>
  )
}

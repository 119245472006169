import React from 'react'
import { Stack } from '@mui/material'
import NavigationPart from 'plateforme/parts/NavigationPart'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'
import { useParams } from 'react-router-dom'
import { useGetAdminMedecinByCodeQuery } from 'admin/store/apis/gestionMedecinApi'
import RechercheHabilitationsMedecinAdminPart from 'admin/part/RechercheHabilitationsMedecinAdminPart/RechercheHabilitationsMedecinAdminPart'
import ListeHabilitationsMedecinAdminPart from 'admin/part/RechercheHabilitationsMedecinAdminPart/ListeHabilitationsMedecinAdminPart'
import { useGetListeHabilitationMedecinAdminQuery } from 'admin/store/apis/habilitationMedecinAdminApi'
import { HabilitationSortType } from 'medecin/store/types/habilitationMedecin'
import useErrorSnackBar from 'plateforme/hooks/useErrorSnackBar'

export default function RechercheHabilitationsMedecinAdminPage() {
  const { codeMedecin } = useParams()

  if (codeMedecin === undefined) {
    throw new Error('Aucun code medecin défini')
  }

  const {
    data: medecin,
    isLoading: profilMedecinLoading,
    isError: isErrorProfilMedecin,
    error: errorProfilMedecin,
  } = useGetAdminMedecinByCodeQuery({ codeMedecin })

  const {
    combinedData,
    exportParams,
    readMore,
    remoteTotal,
    search,
    initSort,
    sortBy,
    isLoading: listeHabilitationLoading,
    isFetching,
    isError: isErrorListeHabilitations,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetListeHabilitationMedecinAdminQuery, {
    defaultCriteria: { codeMedecin },
    defaultSort: { sortType: HabilitationSortType.DATE_CREATION_SORT, sortOrder: 'DESC' },
  })

  const isLoading = profilMedecinLoading || listeHabilitationLoading

  useErrorSnackBar(error, isErrorListeHabilitations, 'La Recherche des habilitations a échoué')

  useErrorSnackBar(errorProfilMedecin, isErrorProfilMedecin, 'Le chargement du profil a échoué')

  return (
    <Stack>
      <NavigationPart label="Liste des habilitations" />
      <RechercheHabilitationsMedecinAdminPart medecin={medecin} search={search} searchError={error} />
      <ListeHabilitationsMedecinAdminPart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        combinedData={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isErrorListeHabilitations}
        isSuccess={isSuccess}
        exportParams={exportParams}
      />
    </Stack>
  )
}

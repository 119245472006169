import { Alert, AlertTitle, Chip, Grid, Stack } from '@mui/material'
import { SauvegarderButton } from 'plateforme/components'
import { formatDateFR } from 'plateforme/services/dates.services'
import { StatutMedecin } from 'plateforme/services/medecin.services'
import React from 'react'
import { useFormContext } from 'react-hook-form'

type AlertAdminMedecinPartProps = {
  readOnly: boolean
}

export default function AlertAdminMedecinPart({ readOnly }: AlertAdminMedecinPartProps) {
  const {
    getValues,
    formState: { isValid },
  } = useFormContext()

  const getChipFromStatut = () => {
    const label = getValues('labelStatut')
    switch (getValues('statut')) {
      case StatutMedecin.ACTIF:
        return <Chip label={label} color="success" />
      case StatutMedecin.INSCRIPTION:
      case StatutMedecin.SUSPENDU:
        return <Chip label={label} color="warning" />
      case StatutMedecin.SUPPRIME:
      default:
        return <Chip label={label} color="error" />
    }
  }

  return (
    <Alert
      severity="info"
      elevation={1}
      sx={{
        '& .MuiAlert-message': {
          width: '100%',
        },
        paddingBottom: 0.5,
      }}
    >
      <AlertTitle>
        <Grid container>
          <Grid item xs={12} lg={12} marginTop="-2px">
            <strong>Date de modification médecin : </strong>
            {formatDateFR(getValues('dateMiseAJour'))}
          </Grid>
          <Grid item xs={12} lg={6} marginTop="-2px">
            <strong>Statut : </strong>
            {getChipFromStatut()}
          </Grid>
          {!readOnly && (
            <Grid item xs={12} lg={6} display="flex" alignItems="flex-end" justifyContent="flex-end">
              <Stack direction="row" alignItems="center" justifyContent="flex-end">
                <SauvegarderButton disabled={!isValid} type="submit">
                  Sauvegarder
                </SauvegarderButton>
              </Stack>
            </Grid>
          )}
        </Grid>
      </AlertTitle>
    </Alert>
  )
}

import { MessageEntrepriseFilter } from 'assureur/store/types/messageEntreprise'
import { formatISO, subWeeks } from 'date-fns'

// NOTE : Attention de ne pas mettre l'initialisation de dateCreationDebut au niveau des fonction xxxxxxxFiltre()
const dateDebut = formatISO(subWeeks(new Date(), 1))

export const dernieresNotificationsDossierNonLueFiltre = () =>
  ({
    typesExclus: ['MEL_MED', 'ACTIV_MED', 'SUP_MED', 'SUSP_MED', 'CREA_HAB'],
    lu: 0,
    dateDebut,
  } as MessageEntrepriseFilter)

export const dernieresNotificationsMessageNonLueFiltre = () =>
  ({
    types: ['MEL_MED'],
    lu: 0,
    dateDebut,
  } as MessageEntrepriseFilter)

export const dernieresNotificationsFiltre = () => {
  return {
    typesExclus: ['MEL_MED', 'ACTIV_MED', 'SUP_MED', 'SUSP_MED', 'CREA_HAB'],
    dateDebut,
  } as MessageEntrepriseFilter
}

export const dernieresNotificationsMessagesFiltre = () => ({ types: ['MEL_MED'], dateDebut } as MessageEntrepriseFilter)

export const dernieresNotificationsSuiviMedecinFiltre = () =>
  ({ types: ['ACTIV_MED', 'SUP_MED', 'SUSP_MED'], dateDebut } as MessageEntrepriseFilter)

export const dernieresNotificationsNonLuesParDossierFiltre = (codeDossier: string) =>
  ({
    codeDossier,
    lu: 0,
    typesExclus: ['MEL_MED', 'ACTIV_MED', 'SUP_MED', 'SUSP_MED', 'CREA_HAB'],
  } as MessageEntrepriseFilter)

export const messagesMissionsParDossierFiltre = (codeDossier: string) =>
  ({
    codeDossier,
    types: ['MEL_MIS'],
    tous: 1,
  } as MessageEntrepriseFilter)

export const messagesMissionsNonLusParDossierFiltre = (codeDossier: string) =>
  ({
    codeDossier,
    types: ['MEL_MIS'],
    tous: 0,
    lu: 0,
  } as MessageEntrepriseFilter)

export const messagesMissionsParDossierEtMissionFiltre = (codeDossier: string, codeMisssion: string) =>
  ({
    codeDossier,
    codeMisssion,
    types: ['MEL_MIS'],
  } as MessageEntrepriseFilter)

export const derniersMessagesMedecinFiltre = (codeMedecin: string) =>
  ({ codeMedecin, types: ['MEL_MED'] } as MessageEntrepriseFilter)

import { SelectOption } from 'plateforme/components'

export default interface Referentiel {
  typologiesMedecin: ReferentielDetail[]
  frequencesNotification: ReferentielDetail[]
  qualitesPersonneAExaminer: ReferentielDetail[]
  qualitesPersonneAContacter?: ReferentielDetail[]
  cadresExpertise: ReferentielCadreExpertise[]
  naturesEvenement: ReferentielNatureEvenement[]
  typesDocument: ReferentielTypeDocument[]
  formatsDocument: ReferentielDetail[]
  motifsCloture: ReferentielDetail[]
  motifsBlocage: ReferentielDetail[]
  pays: ReferentielDetail[]
  statutsHabilitation: ReferentielDetail[]
  statutsMission: ReferentielDetail[]
  statutsDossier: ReferentielDetail[]
  statutsEnterprise: ReferentielDetail[]
  statutsMedecin: ReferentielDetail[]
  motifCarence: ReferentielDetail[]
  statutsFacture: ReferentielDetail[]
  groupe: ReferentielDetail[]
  delaiNouvelExamen: ReferentielDetail[]
  typeFicheConclusion: ReferentielDetail[]
  lesion: ReferentielDetail[]
  sequelle: ReferentielDetail[]
  dommagePredefini: ReferentielDommage[]
  typeMessageNotification: ReferentielTypeMessageNotification[]
  sexes: ReferentielDetail[]
  typeContact: ReferentielDetail[]
}

export enum TypeReferentielLabel {
  typologiesMedecin = 'Typologie médecin',
  frequencesNotification = 'Fréquence des notifications',
  qualitesPersonneAExaminer = 'Qualité personne à examiner',
  qualitesPersonneAContacter = 'Qualité personne à contacter',
  cadresExpertise = `Cadre de l'expertise`,
  naturesEvenement = `Nature de l'événement`,
  typesDocument = 'Type de document',
  formatsDocument = 'Format du document',
  motifsCloture = 'Motif de clôture',
  pays = 'Pays',
  statutsHabilitation = 'Statut habilitation',
  statutsMission = 'Statut mission',
  statutsSuiviMission = 'Statut suivi de mission',
  statutsDossier = 'Statut dossier',
  statutsEnterprise = 'Statut entreprise',
  statutsMedecin = 'Statut médecin',
  motifCarence = 'Motif de carence',
  statutsFacture = 'Statut facture',
  delaiNouvelExamen = 'Délai nouvel examen',
  typeFicheConclusion = 'Types de fiche des conclusions',
  lesion = 'Codes lésions',
  sequelle = 'Codes séquelles',
  dommagePredefini = 'Dommages prédéfinis',
  typeMessageNotification = 'Types de notifications',
  sexes = 'Sexe',
  typeContact = 'Type de contact',
  motifsBlocage = 'Motif de blocage',
}

export type ReferentielName = keyof Referentiel

export interface ReferentielDetail extends SelectOption {
  code: string
  label: string
  statut: string
}

export interface ReferentielNatureEvenement extends ReferentielDetail {
  tags: NatureEvenementTag[]
  ordre: number
}

export interface ReferentielCadreExpertise extends ReferentielDetail {
  tags: CadreExpertiseTag[]
}

export interface ReferentielTypeDocument extends ReferentielDetail {
  code: 'LM' | string
  tags: TypeDocumentTag[]
}

export interface ReferentielDommage extends ReferentielDetail {
  codePrejudice: string
  typeDommage: string
  handicapGrave: boolean
  typesFicheConclusion: TypeFicheConclusion[]
}

export type NatureEvenementTag = 'QUALITE_PERSONNE_A_EXAMINER_REQUISE'

export type TypeDocumentTag =
  | 'ENTREPRISE'
  | 'MEDECIN'
  | 'PLATEFORME'
  | 'ADMIN'
  | 'DOSSIER_ANNEXE'
  | 'CARENCE_RAPPORT'
  | 'CARENCE_ANNEXE'
  | 'CARENCE_FICHE'
  | 'CONCLUSION_PROVISOIRE_RAPPORT'
  | 'CONCLUSION_PROVISOIRE_ANNEXE'
  | 'CONCLUSION_PROVISOIRE_FICHE'
  | 'CONCLUSION_DEFINITIVE_RAPPORT'
  | 'CONCLUSION_DEFINITIVE_ANNEXE'
  | 'CONCLUSION_DEFINITIVE_FICHE'
  | 'MISSION_ANNEXE'
  | 'FACTURE_ANNEXE'
  | 'MEDECIN_HORS_MISSION'
  | 'MESSAGE_PIECE_JOINTE'

export enum TypeFicheConclusion {
  CONV = 'CONV',
  SIMP = 'SIMP',
}

export interface ReferentielTypeMessageNotification extends ReferentielDetail {
  code: TypeMessageNotification
  partenaires: TypeMessageNotificationTag[]
  tags: TypeMessageNotificationTag[]
}

export type TypeMessageNotification =
  | 'ACTIV_MED'
  | 'SUP_MED'
  | 'SUSP_MED'
  | 'REL_MIS_NREP'
  | 'REL_DEXAM'
  | 'REL_CONC'
  | 'REL_FAC'
  | 'REL_MAJ_CONC'
  | 'DEP_DOC_MED'
  | 'DEP_DOC_ENT'
  | 'ACC_MIS'
  | 'REF_MIS'
  | 'MAJ_MIS_DEXAM'
  | 'MAJ_MIS_SBLQ'
  | 'DEP_CONC'
  | 'DMD_MAJ_CONC'
  | 'MAJ_CONC'
  | 'DEP_FAC'
  | 'ANN_FAC'
  | 'REM_AUTO'
  | 'PUR_AUTO'
  | 'MEL_MED'
  | 'SUP_DOC_MED'
  | 'SUP_DOC_ENT'
  | 'CLO_AUTO'
  | 'PROP_MIS'
  | 'ANN_MIS'
  | 'ARR_MIS'
  | 'SS_SUI_MIS'
  | 'MAJ_FAC'
  | 'MAJ_DOS'
  | 'CREA_DOS'
  | 'VAL_DOS'
  | 'ANN_DOS'
  | 'CLO_DOS'
  | 'ROUV_DOS'
  | 'PUR_DOS'
  | 'CREA_HAB'
  | 'MEL_MIS'

export type CadreExpertiseTag = 'AUTRE_MEDECIN_POSSIBLE' | 'CONSENTEMENT_REQUIS'

export type TypeMessageNotificationTag = 'MEDECIN' | 'ENTREPRISE'

import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useErrorHandler } from 'react-error-boundary'
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import {
  AreaLoading,
  ButtonsStack,
  FallbackError,
  ListeButton,
  NoRowsOverlay,
  VoirIconButton,
} from 'plateforme/components'
import { formatDateTimeFR } from 'plateforme/services/dates.services'
import { LoadingProps } from 'plateforme/services/utils'
import PageRecherche from 'plateforme/store/types/pageRecherche'
import { useGetListeMessagesQuery } from 'assureur/store/apis/messageEntrepriseApi'
import {
  MessageDuMedecinVersAssureur,
  MessageEntreprise,
  MessageEntrepriseFilter,
} from 'assureur/store/types/messageEntreprise'
import { setNotificationCriteria } from 'assureur/store/slices/searchCriteriaSlice/searchCriteriaEntrepriseSlice'
import { consultationFicheMedecinHref, listeNotificationsMedecinHref } from 'assureur/EntrepriseApp'
import { dernieresNotificationsSuiviMedecinFiltre } from 'assureur/services/messageEntreprise.services'

export default function DernieresNotificationsActionMedecinPart({ loading }: LoadingProps) {
  const handleError = useErrorHandler()
  const filter = dernieresNotificationsSuiviMedecinFiltre()
  const {
    data: paginatedMessages,
    isLoading: isLoadingMessages,
    isError: isErrorMessages,
    error: errorMessages,
  } = useGetListeMessagesQuery({ perPage: 10, filter })
  if (isErrorMessages) {
    handleError({
      errorApi: errorMessages,
      title: 'Erreur chargement des notifications',
      api: 'useGetListeDerniersMessagesQuery',
    } as FallbackError)
  }

  if (loading || isLoadingMessages || !paginatedMessages) {
    return <AreaLoading height={350} />
  }
  return <DernieresNotificationsActionMedecinPartLoaded paginatedMessages={paginatedMessages} filter={filter} />
}

function DernieresNotificationsActionMedecinPartLoaded({
  paginatedMessages,
  filter,
}: {
  paginatedMessages: PageRecherche<MessageEntreprise>
  filter: MessageEntrepriseFilter
}) {
  const messages = paginatedMessages.items
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleClickNotifications = () => {
    dispatch(setNotificationCriteria(filter))
    navigate(listeNotificationsMedecinHref)
  }

  return (
    <Card>
      <CardHeader title="Dernières notifications" />
      <CardContent>
        <ButtonsStack withGutter>
          <ListeButton onClick={handleClickNotifications}>Toutes mes notifications</ListeButton>
        </ButtonsStack>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '10%' }}>Date / heure</TableCell>
                <TableCell sx={{ width: '40%' }}>Type de notification</TableCell>
                <TableCell sx={{ width: '40%' }}>Médecin</TableCell>
                <TableCell sx={{ width: '10%' }} align="center" />
              </TableRow>
            </TableHead>
            {messages.length > 0 ? (
              <TableBody>
                {(messages as MessageDuMedecinVersAssureur[]).map((m) => (
                  <TableRow key={m.code}>
                    <TableCell sx={{ width: '10%' }}>{formatDateTimeFR(m.date)}</TableCell>
                    <TableCell sx={{ width: '40%' }}>{m.labelType}</TableCell>
                    <TableCell sx={{ width: '40%' }}>{m.medecin?.libelle}</TableCell>
                    <TableCell sx={{ width: '10%', paddingRight: 4 }} align="right">
                      <VoirIconButton
                        onClick={() => navigate(consultationFicheMedecinHref(m.medecin?.code ?? 'undefined'))}
                        tooltip="Voir le médecin"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <NoRowsOverlay />
            )}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

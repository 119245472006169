import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import {
  ButtonsStack,
  ChercherButton,
  DatePickerInput,
  ParametrageNameEnum,
  RechargerButton,
  SelectInputGestionnaire,
  SelectInputParametrage,
  SelectInputReferentiel,
  SelectOption,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { formatDateISO } from 'plateforme/services/dates.services'
import { emptyToUndefined, trimToUndefined } from 'plateforme/services/utils'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'
import ProfilEntreprise from 'plateforme/store/types/profilEntreprise'
import {
  getFactureCriteria,
  setFactureCriteria,
} from 'assureur/store/slices/searchCriteriaSlice/searchCriteriaEntrepriseSlice'
import { FactureFilter, StatutFacture } from 'assureur/store/types/factureEntreprise'
import { setFactureSort } from 'assureur/store/slices/sortSlice/sortSlice'
import AutocompleteMedecin from 'assureur/components/AutocompleteMedecin'
import { useGetMedecinsPartenaireQuery } from 'assureur/store/apis/medecinEntrepriseApi'

type SearchFacturesProps = {
  search: (criteria: FactureFilter) => void
  searchError: IQueryErrorResponse
  profilEntreprise?: ProfilEntreprise
  profilUtilisateur?: ProfilUtilisateurActif
}

type FactureFilterData = {
  statuts?: StatutFacture[] | null
  codeMedecin: SelectOption | null
  dateDebut: Date | null
  dateFin: Date | null
  service?: string | null
  gestionnaire?: string | null
}

export default function RechercheFacturesEntreprisePart({
  search,
  searchError,
  profilEntreprise,
  profilUtilisateur,
}: SearchFacturesProps) {
  const cloisonnementServiceActif =
    !profilUtilisateur?.codeService && profilEntreprise?.parametrage?.cloisonnementService

  // get default values from localStorage
  const defaultFactureCriteria = useSelector(getFactureCriteria) as FactureFilter
  const dispatch = useDispatch()

  // Les valeurs initiales du formulaire :
  const initialValues: FactureFilterData = {
    statuts: defaultFactureCriteria?.statuts,
    codeMedecin: defaultFactureCriteria?.codeMedecin
      ? ({
          code: defaultFactureCriteria?.codeMedecin,
          label: defaultFactureCriteria?.labelMedecin,
        } as SelectOption)
      : null,
    service: defaultFactureCriteria?.service ?? null,
    gestionnaire: defaultFactureCriteria?.gestionnaire ?? null,
    dateDebut: defaultFactureCriteria?.dateDebut ? new Date(defaultFactureCriteria.dateDebut) : null,
    dateFin: defaultFactureCriteria?.dateFin ? new Date(defaultFactureCriteria.dateFin) : null,
  }

  const resetValues: FactureFilterData = {
    statuts: null,
    codeMedecin: null,
    service: null,
    gestionnaire: null,
    dateDebut: null,
    dateFin: null,
  }

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    setError,
    formState: { isValid },
    watch,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })
  useErrorFormMapper(searchError, setError, getValues)

  const onSubmit = async (data: FactureFilterData) => {
    const filtre = {
      statuts: emptyToUndefined(data.statuts),
      codeMedecin: trimToUndefined(data.codeMedecin?.code),
      service: trimToUndefined(data.service),
      gestionnaire: trimToUndefined(data.gestionnaire),
      dateDebut: formatDateISO(data.dateDebut),
      dateFin: formatDateISO(data.dateFin),
    }
    search(filtre)
    dispatch(
      setFactureCriteria({
        ...filtre,
        labelMedecin: trimToUndefined(data.codeMedecin?.label),
      })
    )
  }

  const handleClickInitSearch = () => {
    const filtre = {
      statuts: undefined,
      codeMedecin: undefined,
      affectationCentrale: undefined,
      service: undefined,
      gestionnaire: undefined,
      dateDebut: undefined,
      dateFin: undefined,
    } as FactureFilter
    reset(resetValues)
    search(filtre)
    dispatch(setFactureCriteria(filtre))
    dispatch(setFactureSort({}))
  }

  const serviceEntreprise = profilUtilisateur?.codeService
    ? profilUtilisateur?.codeService
    : trimToUndefined(watch('service'))

  return (
    <Card>
      <CardHeader title="Recherche avancée" />
      <CardContent sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-facture">
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="codeMedecin"
                control={control}
                defaultValue={initialValues.codeMedecin}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <AutocompleteMedecin
                    id="code-medecin-facture"
                    label="Médecin"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    useQuery={useGetMedecinsPartenaireQuery}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="statuts"
                control={control}
                defaultValue={initialValues.statuts}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputReferentiel
                    id="statuts"
                    label="Statuts"
                    value={value}
                    onBlur={onBlur}
                    onChangeMultiple={onChange}
                    referentielName="statutsFacture"
                    multiple
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="dateDebut"
                control={control}
                defaultValue={initialValues.dateDebut}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DatePickerInput
                    InputProps={{
                      id: 'date-debut',
                      fullWidth: true,
                    }}
                    label="Date de début"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="dateFin"
                control={control}
                defaultValue={initialValues.dateFin}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DatePickerInput
                    InputProps={{
                      id: 'date-fin',
                      fullWidth: true,
                    }}
                    label="Date de fin"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            {cloisonnementServiceActif && (
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="service"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <SelectInputParametrage
                      id="service"
                      parametrageName={ParametrageNameEnum.SERVICE}
                      label="Service"
                      value={value}
                      onChange={onChange}
                      fullWidth
                      onBlur={onBlur}
                      fieldError={error}
                      withNoSelectionItem
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="gestionnaire"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectInputGestionnaire
                    id="gestionnaire"
                    label="Gestionnaire"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    onBlur={onBlur}
                    fieldError={error}
                    codeService={serviceEntreprise}
                    withNoSelectionItem
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonsStack>
                <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                  Réinitialiser
                </RechargerButton>
                <ChercherButton variant="contained" type="submit" form="form-recherche-facture" disabled={!isValid}>
                  Rechercher
                </ChercherButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { TypePartenaire } from 'plateforme/store/types/utilisateur'
import { StatutDossier } from 'plateforme/store/types/dossier'
import { StatutMission } from 'plateforme/store/types/mission'
import Contact, { ContactComplet } from './contactEntreprise'
import MissionEntreprise from './missionEntreprise'

export enum EventNature {
  BIRC = 'BIRC',
  BNIR = 'BNIR',
  RCGE = 'RCGE',
  RCMD = 'RCMD',
  ASSC = 'ASSC',
  PREV = 'PREV',
  ACCV = 'ACCV',
  FAUT = 'FAUT',
  AUTR = 'AUTR',
}

export default interface DossierEntreprise {
  code: string
  refDossierEntreprise?: string
  refVictimeEntreprise?: string
  libelle?: string
  entreprise?: ReferenceEntreprise
  serviceEntreprise?: string
  libelleServiceEntreprise?: string
  gestionnaire?: ReferenceGestionnaire
  typeContactEntreprise?: string
  labelTypeContactEntreprise?: string
  contactEntreprise?: ContactEntreprise
  assure?: Assure
  evenement?: Evenement
  expertise?: Expertise
  personneAExaminer?: PersonneAExaminer
  personneAContacter?: PersonneAContacter
  consentement?: boolean
  refConsentement?: string
  statut?: StatutDossier
  labelStatut?: string
  descriptionStatut?: string
  motifCloture?: string
  labelMotifCloture?: string
  commentaireCloture?: string
  commentaireReouverture?: string
  commentaireAnnulation?: string
  documents?: DocumentTeleverse[]
  missions?: [MissionEntreprise]
  horsDelais?: boolean
  typeFicheConclusionPrevisible?: string
  lettreMissionDeposable?: boolean
  editable?: boolean
  editableMajeure?: boolean
  documentDeposable?: boolean
  affectable?: boolean
  validable?: boolean
  missionable?: boolean
  annulable?: boolean
  cloturable?: boolean
  reouvrable?: boolean
  consentementRetirable?: boolean
  purgeable?: boolean
  dateCreation?: Date
  dateEdition?: Date
  dateEditionMajeure?: Date
  dateEnCours?: Date
  dateAnnulation?: Date
  dateCloture?: Date
  dateReouverture?: Date
  dateMiseAJour?: Date
}

export interface Gestionnaire {
  isActive: boolean
  idUtilisateur?: string
  nom?: string
  prenom?: string
  codeService?: string
}
export interface Assure {
  nom?: string
  numeroContrat?: string
  codeAssure?: string
}

export interface DossierEntrepriseList {
  values: DossierEntreprise[]
  pageIndex: number
  total: number
  next: string
}

export interface ContactEntreprise {
  libelle?: string
  mail?: string
  telephone?: string
}

export interface ReferenceDossier {
  code?: string
  libelle?: string
  entreprise?: ReferenceEntreprise
  refDossierEntreprise?: string
  refVictimeEntreprise?: string
  nomPersonneAExaminer?: string
  prenomPersonneAExaminer?: string
}

export interface ReferenceEntreprise {
  code?: string
  codeACPR?: string
  codeSIREN?: string
  libelle?: string
}

export interface ReferenceGestionnaire {
  code?: string
  libelle?: string
  mail?: string
  telephone?: string
}

export interface AutreMedecin extends Contact {
  codeMedecin?: string
  libelle?: string
  mail?: string
  telephoneFixe?: string
  telephoneMobile?: string
  adresse1?: string
  adresse2?: string
  adresse3?: string
  codePostal?: string
  commune?: string
  pays?: string
}

export interface DomaineContractuel {
  actif?: boolean
  code?: string
  libelle?: string
  dommagesPredefinis?: string[]
}
export interface Expertise extends Contact, ContactComplet {
  cadreExpertise?: string
  labelCadreExpertise?: string
  garantieApplicable?: string
  domaineContractuel?: DomaineContractuel
  commentaire?: string
  isMedecinTransMED: boolean
  autreMedecin?: AutreMedecin
  prejudiceEnAggravation?: boolean
  typeLieuExpertise?: TypeLieuExpertise
  labelTypeLieuExpertise?: string
  libelleAdresse?: string
}

export enum TypeLieuExpertise {
  AUTRE = 'AUTRE',
  CABINET = 'CABINET',
  NR_A_DEFINIR = 'NR_A_DEFINIR',
}

export interface Evenement {
  reference?: string
  date?: Date
  nature?: string
  labelNature?: string
  commentaire?: string
}

export interface PersonneAExaminer extends Contact, ContactComplet {
  nom?: string
  prenom?: string
  sexe?: string
  labelSexe?: string
  dateNaissance?: Date
  profession?: string
  qualite?: string
  labelQualite?: string
  assure?: boolean
  contactUnique?: boolean
}

export interface PersonneAContacter extends Contact, ContactComplet {
  nom?: string
  prenom?: string
  qualite?: string
  labelQualite?: string
  coordonneesIdentiquesPersonneAExaminer?: boolean
}

export interface InformationDossier {
  etape?: EtapeCreationParam
  refDossierEntreprise?: string
  refVictimeEntreprise: string
  evenement: {
    nature: string | null
    date: Date | null
  }
  identifiantDossierPlateforme: string
  gestionnaire: string
  service: string
  nomGestionnaire: string
  preNomGestionnaire: string
  mailGestionnaire: string
  telephoneGestionnaire: string
  gestinnaireSwitch: boolean
  refEvenement: string
  civiliteGestionnaire: string
  commentaire: string
  codeDossier: string
}

export interface HistoriqueDossierEntreprise {
  dateAction?: Date
  codeMission?: string
  codeDocument?: string
  codeFacture?: string
  utilisateur?: HistoriqueUtilisateurEntreprise
  partenaire?: HistoriquePartenaireEntreprise
  action?: string
  actionLabel?: string
  propertiesChanged?: string[]
}

export enum HistoriqueAction {
  MODIFIER_DOSSIER_ENTREPRISE = 'D04',
}

export interface HistoriqueUtilisateurEntreprise {
  id?: string
  title?: string
  nom?: string
  prenom: string
  libelle?: string
}

export interface HistoriquePartenaireEntreprise {
  codePartenaire?: string
  typePartenaire?: TypePartenaire
  libelle?: string
}

export interface DossierRequest {
  codeDossier: string
}

export interface CreationDossierRequest {
  refDossierEntreprise?: string
  refVictimeEntreprise?: string
  gestionnaire?: string
  serviceEntreprise?: string
  evenement?: {
    nature?: string
  }
  expertise?: {
    cadreExpertise?: string
  }
  consentement?: boolean
  refConsentement?: string
}

export interface ModificationDossierRequest {
  refDossierEntreprise?: string
  refVictimeEntreprise?: string
  serviceEntreprise?: string
  gestionnaire?: string
  typeContactEntreprise?: string
  contactEntreprisePersonnalise?: {
    libelle?: string
    mail?: string
    telephone?: string
  }
  assure?: {
    nom?: string
    numeroContrat?: string
    codeAssure?: string
  }
  evenement?: {
    reference?: string
    date?: string
    nature?: string
    commentaire?: string
  }
  expertise?: {
    cadreExpertise?: string
    garantieApplicable?: string
    domaineContractuel?: string
    commentaire?: string
    autreMedecin?: {
      code?: string
      libelle?: string
      mail?: string
      telephoneFixe?: string
      telephoneMobile?: string
      adresse1?: string
      adresse2?: string
      adresse3?: string
      codePostal?: string
      commune?: string
      pays?: string
    }
    prejudiceEnAggravation?: 0 | 1
    typeLieuExpertise?: string
    mail?: string
    telephoneFixe?: string
    telephoneMobile?: string
    adresse1?: string
    adresse2?: string
    adresse3?: string
    codePostal?: string
    commune?: string
    pays?: string
  }
  personneAExaminer?: {
    nom?: string
    prenom?: string
    sexe?: string
    dateNaissance?: string
    profession?: string
    qualite?: string
    assure?: boolean
    contactUnique?: boolean
    mail?: string
    telephoneFixe?: string
    telephoneMobile?: string
    adresse1?: string
    adresse2?: string
    adresse3?: string
    codePostal?: string
    commune?: string
    pays?: string
  }
  personneAContacter?: {
    nom?: string
    prenom?: string
    qualite?: string
    coordonneesIdentiquesPersonneAExaminer?: boolean
    mail?: string
    telephoneFixe?: string
    telephoneMobile?: string
    adresse1?: string
    adresse2?: string
    adresse3?: string
    codePostal?: string
    commune?: string
    pays?: string
  }
  consentement?: boolean | null
  refConsentement?: string
}

export interface ChangerAffectationExEntRequest {
  serviceEntreprise?: string
  gestionnaire?: string
}

export interface CreerMissionRequest {
  codeMedecin?: string
  refMissionEntreprise?: string
  refMissionAnterieure?: string
  precisions?: string
}

export interface DossierEntrepriseFilter {
  motCle?: string
  statutsDossier?: StatutDossier[]
  statutsMission?: StatutMission[]
  aucuneMission?: 0 | 1
  natureEvenement?: string
  cadreExpertise?: string
  codeMedecin?: string
  labelMedecin?: string
  affectationCentrale?: 0 | 1
  service?: string
  gestionnaire?: string
  dateCreationDebut?: string
  dateCreationFin?: string
  numeroVictime?: string
  horsDelai?: 0 | 1
}

export enum DossierEntrepriseSortType {
  ENTREPRISE_SORT = 'entreprise',
  MEDECIN_SORT = 'medecin',
  DATE_REPONSE_MEDECIN_SORT = 'date_reponse_medecin',
  DATE_CREATION_DOSSIER_SORT = 'date_creation_dossier',
  STATUT_SORT = 'statut',
  CADRE_EXPERTISE_SORT = 'cadre_expertise',
  NATURE_EVENEMENT_SORT = 'nature_evenement',
  REFERENCE_DOSSIER_SORT = 'ref_dossier',
  PERSONNE_A_EXAMINER_SORT = 'personne_a_examiner',
}

export enum EtapeCreationParam {
  INITIAL = 'initial',
  EXPERTISE = 'expertise',
  EVENEMENT = 'evenement',
  PERSONNES = 'personnes',
  ASSURE = 'assure',
  VALIDE = 'valide',
}

export interface DocumentRequest extends DossierRequest {
  codeDocument: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SupprimerDocumentRequest extends DocumentRequest {}

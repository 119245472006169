import React from 'react'
import { Stack, Typography } from '@mui/material'
import { AutocompleteSelectOption, AutocompleteWithQuery, AutocompleteWithQueryProps } from 'plateforme/components'
import { DEFAULT_OPTION, LOCALE } from 'plateforme/constantes'
import { trimToUndefined } from 'plateforme/services/utils'
import { useGetReferentielQuery } from 'plateforme/store/apis/referentielApi'
import Referentiel, { ReferentielDetail } from 'plateforme/store/types/referentiel'

type AutocompleteEntrepriseProps = Omit<AutocompleteWithQueryProps<Referentiel>, 'useQuery' | 'dataTransformer'>

export default function AutocompleteLesion({ ...restProps }: AutocompleteEntrepriseProps) {
  const dataTransformer = (data: Referentiel) => {
    return data.lesion.map((ref) => {
      return {
        code: ref.code,
        label: `${ref.code} - ${ref.label}`,
        data: { code: ref.code, label: ref.label },
      }
    })
  }

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: AutocompleteSelectOption<ReferentielDetail>
  ) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <li {...props}>
      {option.code === DEFAULT_OPTION.code ? (
        <Typography align="left" variant="body2" color="text.primary">
          <i>{option.label}</i>
        </Typography>
      ) : (
        <Stack spacing={0} direction="row" justifyContent="space-between" alignItems="start" width="100%">
          <Typography align="left" variant="subtitle2" color="text.primary">
            {option.code ?? ''}
          </Typography>
          <Stack spacing={0} margin={0} width={328}>
            <Typography variant="body2" color="readOnly.main">
              {option.label ?? ''}
            </Typography>
          </Stack>
        </Stack>
      )}
    </li>
  )

  const filter = (options: AutocompleteSelectOption<ReferentielDetail>[], state: { inputValue: string }) => {
    const { inputValue } = state
    const isDigit = /^\d+$/.test(inputValue)

    if (!trimToUndefined(inputValue)) {
      return options
    }

    // si le input saisie par l'utilisateur est un chiffre
    if (isDigit) {
      const reg = new RegExp(inputValue)

      return options.filter(
        (option: AutocompleteSelectOption<ReferentielDetail>) =>
          option.data?.code?.match(reg) || option.data?.label?.match(reg)
      )
    }

    return options.filter((option) =>
      option.data?.label.toLocaleLowerCase(LOCALE).includes(inputValue.toLocaleLowerCase(LOCALE))
    )
  }

  return (
    <AutocompleteWithQuery
      {...restProps}
      orderBy="code"
      placeholder="Code, label"
      useQuery={useGetReferentielQuery}
      dataTransformer={dataTransformer}
      renderOption={renderOption}
      filterOptions={filter}
    />
  )
}

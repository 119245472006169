import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import MedecinEntreprise from 'assureur/store/types/medecinEntreprise'
import { RootState } from 'plateforme/store'

export interface SearchMedecin {
  medecins?: MedecinEntreprise[]
}

export const searchMedecinSliceName = 'searchMedecin'

const searchMedecinSlice = createSlice({
  name: searchMedecinSliceName,
  initialState: {
    medecins: undefined,
  } as SearchMedecin,
  reducers: {
    setSearchMedecin: (state, action: PayloadAction<MedecinEntreprise[]>) => {
      state.medecins = action.payload
    },
  },
})

export const { setSearchMedecin } = searchMedecinSlice.actions

export const getSearchMedecin = (state: RootState) => state?.searchMedecin.medecins

export default searchMedecinSlice.reducer

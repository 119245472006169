import Utilisateur, { RoleUtilisateur } from 'plateforme/store/types/utilisateur'

const getUtilisataurRoles = (utilisateur?: Utilisateur): RoleUtilisateur[] => {
  const flettenRoles: RoleUtilisateur[] = []
  if (utilisateur) {
    utilisateur?.profils
      .filter((profil) => !profil.isProfilGroupe)
      .forEach((profil) => flettenRoles.push(...profil.roles))
  }

  return flettenRoles
}

export default getUtilisataurRoles

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DossierMedecinFilter } from 'medecin/store/types/dossierMedecin'
import { FactureFilter } from 'medecin/store/types/factureMedecin'
import { NotificationMedecinFilter } from 'medecin/store/types/notificationsMedecin'
import { RootState } from 'plateforme/store'

export interface SearchCriteria {
  dossierCriteria?: DossierMedecinFilter
  factureCriteria?: FactureFilter
  notificationCriteria?: NotificationMedecinFilter
}

export const searchCrieteriaSliceName = 'searchCriteria'

const searchCriteriaSlice = createSlice({
  name: searchCrieteriaSliceName,
  initialState: {
    dossierCriteria: undefined,
    factureCriteria: undefined,
    notificationCriteria: undefined,
  } as SearchCriteria,
  reducers: {
    setDossierCriteria: (state, action: PayloadAction<DossierMedecinFilter>) => {
      state.dossierCriteria = action.payload
    },
    setDossierMotCleCriteria: (state, action: PayloadAction<string>) => {
      state.dossierCriteria = {
        motCle: action.payload === '' ? undefined : action.payload,
        numeroVictime: undefined,
        codeEntreprise: undefined,
        statuts: undefined,
        natureEvenement: undefined,
        cadreExpertise: undefined,
        dateDebut: undefined,
        dateFin: undefined,
      }
    },
    setFactureCriteria: (state, action: PayloadAction<FactureFilter>) => {
      state.factureCriteria = action.payload
    },
    setNotificationCriteria: (state, action: PayloadAction<NotificationMedecinFilter>) => {
      state.notificationCriteria = action.payload
    },
  },
})

export const { setDossierCriteria, setDossierMotCleCriteria, setFactureCriteria, setNotificationCriteria } =
  searchCriteriaSlice.actions

export const getSearchCriteria = (state: RootState) => state?.searchCriteria
export const getDossierCriteria = (state: RootState) => state?.searchCriteria.dossierCriteria
export const getFactureCriteria = (state: RootState) => state?.searchCriteria.factureCriteria
export const getNotificationCriteria = (state: RootState) => state?.searchCriteria.notificationCriteria

export default searchCriteriaSlice.reducer

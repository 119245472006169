import React, { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Stack,
} from '@mui/material'
import { ButtonsStack, ChercherButton, EditTextField, RechargerButton } from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import MedecinEntreprise, { MedecinEntrepriseFilter } from 'assureur/store/types/medecinEntreprise'
import { useDispatch, useSelector } from 'react-redux'
import {
  getMedecinGroupeCriteria,
  setMedecinGroupeCriteria,
} from 'assureur/store/slices/searchCriteriaSlice/searchCriteriaEntrepriseSlice'
import { setSearchMedecin } from 'assureur/store/slices/searchMedecin/searchMedecinSlice'
import { getMedecinSort } from 'assureur/store/slices/sortSlice/sortSlice'
import { SortParam } from 'plateforme/store/types/pageRecherche'
import { trimToUndefined } from 'plateforme/services/utils'
import { formatBooleanFilter } from 'plateforme/services/apis.services'

type SearchMedecinsProps = {
  searchError: IQueryErrorResponse
  combinedData: MedecinEntreprise[]
  sortFrontFunction: (param: SortParam<string>, medecins: MedecinEntreprise[]) => MedecinEntreprise[]
}

export interface MedecinFilterData {
  nomMedecin?: string
  codeMedecin?: string
  habilitationActive?: boolean
  habilitable?: boolean
  irca?: boolean
  codePostal?: string
}

export default function RechercheMedecinsEntrepriseGroupePart({
  searchError,
  combinedData,
  sortFrontFunction,
}: SearchMedecinsProps) {
  const defaultMedecinCriteria = useSelector(getMedecinGroupeCriteria) as MedecinEntrepriseFilter
  const dispatch = useDispatch()
  const sort = useSelector(getMedecinSort)

  // Les valeurs initiales du formulaire :
  const initialValues: MedecinFilterData = {
    nomMedecin: defaultMedecinCriteria?.nomMedecin ?? '',
    codeMedecin: defaultMedecinCriteria?.codeMedecin ?? '',
    habilitationActive: defaultMedecinCriteria?.habilitationActive === 1,
    habilitable: defaultMedecinCriteria?.habilitable === 1,
    irca: defaultMedecinCriteria?.irca === 1,
    codePostal: defaultMedecinCriteria?.codePostal ?? '',
  }

  const {
    handleSubmit,
    clearErrors,
    control,
    setError,
    getValues,
    reset,
    formState: { isValid },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })
  useErrorFormMapper(searchError, setError, getValues)

  const setCriteresRecherche = (data: MedecinFilterData) => {
    const filtre = {
      nomMedecin: trimToUndefined(data.nomMedecin),
      codeMedecin: trimToUndefined(data.codeMedecin),
      codePostal: trimToUndefined(data.codePostal),
      irca: formatBooleanFilter(data.irca),
    }
    dispatch(setMedecinGroupeCriteria(filtre))
  }

  const onSubmit = useCallback(
    async (data: MedecinFilterData) => {
      let result: MedecinEntreprise[] = combinedData

      if (data.nomMedecin) {
        result = result.filter(
          (c) => c.libelle && data.nomMedecin && c.libelle.toLowerCase().includes(data.nomMedecin.toLowerCase().trim())
        )
      }

      if (data.codeMedecin) {
        result = result.filter((c) => c.code?.startsWith(data.codeMedecin?.trim() as string))
      }

      if (data.codePostal) {
        result = result.filter((c) =>
          c.lieuxExercice.some(
            (lieu) => lieu.codePostal && lieu.codePostal.startsWith(data.codePostal?.trim() as string)
          )
        )
      }

      if (data.irca) {
        result = result.filter((c) => data.irca === c.irca)
      }

      setCriteresRecherche(data)

      if (sort) {
        return dispatch(setSearchMedecin(sortFrontFunction(sort, result)))
      }

      return dispatch(setSearchMedecin(result))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [combinedData, dispatch, sort, sortFrontFunction]
  )

  useEffect(() => {
    const data = getValues()
    onSubmit(data)
  }, [getValues, onSubmit, sort])

  const handleClickInitSearch = () => {
    reset({
      nomMedecin: '',
      codeMedecin: '',
      irca: false,
      codePostal: '',
    })
    const data = getValues()
    onSubmit(data)
  }

  return (
    <Card>
      <CardHeader title="Recherche avancée" />
      <CardContent sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-medecins">
          <Grid container>
            <Grid item xs={12}>
              <Controller
                name="nomMedecin"
                control={control}
                defaultValue={initialValues.nomMedecin}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="nom-medecin"
                    label="Nom du médecin"
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => {
                      clearErrors('nomMedecin')
                      onChange(e)
                    }}
                    fullWidth
                    fieldError={error}
                  />
                )}
                rules={{
                  validate: {
                    length: (value) =>
                      value === '' ||
                      (value && value?.length <= 50) ||
                      `La taille (${value?.length}) ne doit pas être supérieure à 50`,
                  },
                }}
              />
            </Grid>

            <Grid container item xs={12}>
              <Grid container item xs={8} md={8} sm={12}>
                <Grid item xs={6} sm={12} md={6}>
                  <Controller
                    name="codeMedecin"
                    control={control}
                    defaultValue={initialValues.codeMedecin}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <EditTextField
                        id="id-medecin-plateforme"
                        label="Identifiant médecin plateforme"
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => {
                          clearErrors('codeMedecin')
                          onChange(e)
                        }}
                        fullWidth
                        fieldError={error}
                      />
                    )}
                    rules={{
                      pattern: {
                        value: /^((R_\d{11})|(S_\d{9}))$/,
                        message:
                          'Un code valide (R_<Code RPPS - 11 chiffres> ou S_<Code SIREN - 9 chiffres>) est requis',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={12} md={6}>
                  <Controller
                    name="codePostal"
                    control={control}
                    defaultValue={initialValues.codePostal}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <EditTextField
                        id="numero-agrement"
                        label="Code postal du lieu d'exercice"
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => {
                          clearErrors('codePostal')
                          onChange(e)
                        }}
                        fullWidth
                        fieldError={error}
                      />
                    )}
                    rules={{
                      validate: {
                        length: (value) =>
                          value === '' ||
                          (value && value.length >= 2 && value.length <= 16) ||
                          `La taille (${value?.length}) doit être comprise entre 2 et 16`,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4} sm={6} md={4}>
                <Stack direction="column" alignItems="flex-start" justifyContent="center" spacing={0} height="100%">
                  <Controller
                    name="irca"
                    control={control}
                    defaultValue={initialValues.irca}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <FormControl error={error !== undefined}>
                        <FormGroup>
                          <FormControlLabel
                            label="Médecin IRCA"
                            sx={{ height: 30 }}
                            control={
                              <Checkbox
                                name="typologieMedecin"
                                checked={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                sx={{ padding: 0, marginX: 1.5, display: 'bloc' }}
                              />
                            }
                          />
                        </FormGroup>
                        {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ButtonsStack>
                <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                  Réinitialiser
                </RechargerButton>
                <ChercherButton variant="contained" type="submit" form="form-recherche-medecins" disabled={!isValid}>
                  Rechercher
                </ChercherButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid, Stack } from '@mui/material'
import { SauvegarderButton } from 'plateforme/components'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import ActivationEvenementPart from './ActivationEvenementPart'
import GestionRemissionnementPart from './GestionRemissionnementPart'
import IdentificationDossierPart from './IdentificationDossierPart'
import InformationsAssurePart from './InformationsAssurePart'
import InformationsContactPart from './InformationsContactPart'
import ParametrageCarteMedecinPart from './ParametrageCarteMedecinPart'
import ParametrageFichesConclusionsPart from './ParametrageFichesConclusionsPart'
import ParametragesAvisTechniquePart from './ParametragesAvisTechniquePart'
import ReglesMetiersPart from './ReglesMetiersPart'
import ParametrageRapportConseilConclusionsPart from './ParametrageRapportConseilConclusionsPart'
import ParametrageMissionPart from './ParametrageMissionPart'
import TiercePersonnePart from './TiercePersonnePart'
import DommagesPeriodesMaxPart from './DommagesPeriodesMaxPart'
import DommagesCommentairesMaxPart from './DommagesCommentairesMaxPart'
import ChoixLieuRDVPart from './ChoixLieuRDVPart'
import MessageriePart from './MessageriePart'
import ParametrageDossierPart from './ParametrageDossierPart'
import LieuExpertisePart from './LieuExpertisePart'

interface TabReglesMetiersPartProps {
  onSubmit?: VoidFunction
}

export default function TabReglesMetiersPart({ onSubmit }: TabReglesMetiersPartProps) {
  const { ...methods } = useFormContext()
  const readOnly = !onSubmit
  const formId = 'form-parametres-regles-metier'
  return (
    <form onSubmit={onSubmit && methods.handleSubmit(onSubmit)} id={formId} name={formId}>
      <Grid container justifyContent="flex-start" alignItems="flex-start">
        <Grid item container md={12} lg={12}>
          <Grid item xs={12}>
            <ReglesMetiersPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <InformationsAssurePart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <LieuExpertisePart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <ChoixLieuRDVPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <MessageriePart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <ParametragesAvisTechniquePart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <ActivationEvenementPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <ParametrageFichesConclusionsPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <ParametrageCarteMedecinPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <InformationsContactPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <IdentificationDossierPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <GestionRemissionnementPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <ParametrageMissionPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <ParametrageDossierPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <TiercePersonnePart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <ParametrageRapportConseilConclusionsPart
              typeConclusion={TypeRapport.CONCLUSION_PROVISOIRE}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <ParametrageRapportConseilConclusionsPart
              typeConclusion={TypeRapport.CONCLUSION_DEFINITIVE}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <DommagesPeriodesMaxPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <DommagesCommentairesMaxPart readOnly={readOnly} />
          </Grid>
          {!readOnly && (
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="right">
                <SauvegarderButton onClick={onSubmit}>Sauvegarder</SauvegarderButton>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  )
}

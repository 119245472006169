import { NotificationAdmin } from 'admin/store/types/notificationsAdmin'
import { MessageEntreprise } from 'assureur/store/types/messageEntreprise'
import { NotificationEntreprise } from 'assureur/store/types/notificationsEntreprise'
import { MessageMedecin } from 'medecin/store/types/messageMedecin'
import { NotificationMedecin } from 'medecin/store/types/notificationsMedecin'

const determineLuNonLuInitial = (lu?: 0 | 1) => {
  if (lu === undefined) {
    return ['lue', 'nonLue']
  }
  return lu === 0 ? ['nonLue'] : ['lue']
}

export const isVoirNotificationEnable = (
  notification: MessageEntreprise | NotificationEntreprise | MessageMedecin | NotificationMedecin | NotificationAdmin
) => {
  return (
    notification.type === 'MEL_MIS' ||
    notification.type === 'CREA_HAB' ||
    (notification.dossier !== undefined && notification.type !== 'PUR_DOS' && notification.type !== 'PUR_AUTO')
  )
}

export const tooltipVoirNotification = (
  notification: MessageEntreprise | NotificationEntreprise | MessageMedecin | NotificationMedecin | NotificationAdmin
) => {
  if (notification?.type === 'CREA_HAB') {
    return `Voir l'habilitation`
  }
  if (notification?.type === 'MEL_MIS') {
    return `Voir le message`
  }
  if (notification?.type === 'PUR_DOS' || notification?.type === 'PUR_AUTO' || notification.dossier === undefined) {
    return `Dossier non accessible`
  }
  return 'Voir le dossier'
}

export default determineLuNonLuInitial

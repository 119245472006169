import React from 'react'
import { Stack } from '@mui/material'
import { useGetListeHabilitationsMedecinQuery } from 'medecin/store/apis/habilitationMedecinApi'
import NavigationPart from 'plateforme/parts/NavigationPart'
import RechercheHabilitationsMedecinPart from 'medecin/parts/rechercheHabilitation/RechercheHabilitationsMedecinPart/RechercheHabilitationsMedecinPart'
import ListeHabilitationsMedecinPart from 'medecin/parts/rechercheHabilitation/ListeHabilitationsMedecinPart'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'
import { useGetProfilMedecinQuery } from 'medecin/store/apis/profilMedecinApi'
import { HabilitationSortType } from 'medecin/store/types/habilitationMedecin'
import useErrorSnackBar from 'plateforme/hooks/useErrorSnackBar'

export default function RechercheHabilitationsMedecinPage() {
  const {
    data: medecin,
    isLoading: profilMedecinLoading,
    isError: isErrorProfilMedecin,
    error: errorProfilMedecin,
  } = useGetProfilMedecinQuery()

  const {
    combinedData,
    exportParams,
    readMore,
    remoteTotal,
    search,
    initSearch,
    initSort,
    sortBy,
    isLoading: listeHabilitationLoading,
    isFetching,
    isError: isErrorListeHabilitations,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetListeHabilitationsMedecinQuery, {
    defaultSort: { sortType: HabilitationSortType.DATE_CREATION_SORT, sortOrder: 'DESC' },
  })

  const isLoading = profilMedecinLoading || listeHabilitationLoading

  useErrorSnackBar(error, isErrorListeHabilitations, 'La Recherche des habilitations a échoué')

  useErrorSnackBar(errorProfilMedecin, isErrorProfilMedecin, 'Le chargement du profil a échoué')

  return (
    <Stack>
      <NavigationPart label="Liste des habilitations" />
      <RechercheHabilitationsMedecinPart
        medecin={medecin}
        initSearch={initSearch}
        search={search}
        searchError={error}
      />
      <ListeHabilitationsMedecinPart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        combinedData={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isErrorListeHabilitations}
        isSuccess={isSuccess}
        exportParams={exportParams}
      />
    </Stack>
  )
}

import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { FormControl, FormControlLabel, FormHelperText, Grid, Switch } from '@mui/material'
import {
  AnnulerButton,
  DividerFormButton,
  EditTextField,
  PhoneNumberInput,
  SauvegarderButton,
  SelectInputReferentiel,
} from 'plateforme/components'
import useErrorFormMapper, {
  IQueryErrorDetailsResponse,
  IQueryErrorResponse,
} from 'plateforme/hooks/useErrorFormMapper'
import { trimToUndefined } from 'plateforme/services/utils'
import { adminModifierMedecinHref } from 'admin/AdminApp'
import { usePostAdminMedecinMutation } from 'admin/store/apis/gestionMedecinApi'
import ProfilMedecinAdmin from 'admin/store/types/profilMedecinAdmin'

interface InscrireMedecinFormProps {
  onClose: () => void
}

export default function InscrireMedecinForm({ onClose }: InscrireMedecinFormProps) {
  const {
    control,
    setError,
    getValues,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      code: '',
      codeRPPS: '',
      codeSIREN: '',
      libelle: '',
      mailContact: '',
      signature: '',
      irca: false,
      expertiseSurPiece: false,
      lieuxExercice: [
        {
          principal: true,
          adresse1: '',
          adresse2: '',
          adresse3: '',
          codePostal: '',
          commune: '',
          pays: 'FR',
          codeSIRET: '',
          telephone: '',
        },
      ],
      parametrage: {
        mailNotification: ['', ''],
        frequenceNotification: '02',
        signature: '',
      },
    } as ProfilMedecinAdmin,
  })
  const [postAdminMedecin, { error: errorPut }] = usePostAdminMedecinMutation()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const onSubmit = async () => {
    const formValues = getValues() as ProfilMedecinAdmin

    // gère le cas ou le deuxieme mail de notification est renseigné mais pas le premier
    if (!formValues.parametrage.mailNotification[0] && formValues.parametrage.mailNotification[1]) {
      setError(`parametrage.mailNotification.0`, {
        type: 'global',
        message: 'Doit être renseigné',
      })
      return
    }

    await postAdminMedecin({
      codeRPPS: trimToUndefined(formValues.codeRPPS),
      codeSIREN: trimToUndefined(formValues.codeSIREN),
      libelle: trimToUndefined(formValues.libelle),
      mailContact: trimToUndefined(formValues.mailContact),
      irca: formValues.irca,
      expertiseSurPiece: formValues.expertiseSurPiece,
      lieuxExercice: [
        {
          principal: true,
          adresse1: trimToUndefined(formValues.lieuxExercice[0].adresse1),
          adresse2: trimToUndefined(formValues.lieuxExercice[0].adresse2),
          adresse3: trimToUndefined(formValues.lieuxExercice[0].adresse3),
          codePostal: trimToUndefined(formValues.lieuxExercice[0].codePostal),
          commune: trimToUndefined(formValues.lieuxExercice[0].commune),
          pays: trimToUndefined(formValues.lieuxExercice[0].pays),
          codeSIRET: trimToUndefined(formValues.lieuxExercice[0].codeSIRET),
          telephone: trimToUndefined(formValues.lieuxExercice[0].telephone),
        },
      ],
      parametrage: {
        signature: trimToUndefined(formValues.parametrage.signature),
        mailNotification: [
          trimToUndefined(formValues.parametrage.mailNotification[0]),
          trimToUndefined(formValues.parametrage.mailNotification[1]),
        ].filter((mail) => mail !== undefined),
        frequenceNotification: formValues.parametrage.frequenceNotification,
      },
    } as ProfilMedecinAdmin)
      .unwrap()
      .then((response) => {
        enqueueSnackbar(`Le medecin a été créé`, { variant: 'success' })
        navigate(adminModifierMedecinHref(response.code as string))
      })
      .catch((error) => {
        // gère la cas ou aucun mailNotification n'est renseigné
        error?.data?.details.forEach((err: IQueryErrorDetailsResponse) => {
          if (err.fields?.includes('parametrage.mailNotification')) {
            setError(`parametrage.mailNotification.0`, err)
          }
        })
        enqueueSnackbar(`La création du medecin a échoué`, { variant: 'error' })
      })
  }

  // Mapping Errors in form:
  useErrorFormMapper(errorPut as IQueryErrorResponse, setError, getValues)

  return (
    <form id="inscrire-medecin">
      <Grid container justifyContent="flex-start" alignItems="flex-start" rowSpacing={2} columnSpacing={2} padding={2}>
        <Grid item xs={6}>
          <Controller
            name="codeRPPS"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                label="Numéro de RPPS"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="codeSIREN"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                label="Code SIREN"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="libelle"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                label="Identité médecin"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="mailContact"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                label="Adresse mail"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="irca"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <FormControl fullWidth error={!!error} variant="standard">
                <FormControlLabel
                  id="irca"
                  value={value}
                  control={<Switch onChange={onChange} onBlur={onBlur} checked={value} />}
                  label="IRCA"
                />
                <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="expertiseSurPiece"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <FormControl fullWidth error={!!error} variant="standard">
                <FormControlLabel
                  id="expertiseSurPiece"
                  value={value}
                  control={<Switch onChange={onChange} onBlur={onBlur} checked={value} />}
                  label="Possibilité de faire des avis techniques sur pièces"
                />
                <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <DividerFormButton title="Établissement principal" readOnly />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="lieuxExercice.0.codeSIRET"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                label="Numéro de SIRET"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="lieuxExercice.0.telephone"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <PhoneNumberInput
                label="Téléphone"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="lieuxExercice.0.adresse1"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                label="Adresse 1"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="lieuxExercice.0.adresse2"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                label="Adresse 2"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="lieuxExercice.0.adresse3"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                label="Adresse 3"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="lieuxExercice.0.codePostal"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                label="Code postal"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="lieuxExercice.0.commune"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                label="Ville"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="lieuxExercice.0.pays"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectInputReferentiel
                id="pays"
                label="Pays"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                referentielName="pays"
                fullWidth
                fieldError={error}
                dataFormatter={(code, label) => {
                  return `${label}`
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <DividerFormButton title="Paramétrage" readOnly />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="parametrage.mailNotification.0"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                label="Adresse mail notifications"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="parametrage.frequenceNotification"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectInputReferentiel
                id="frequenceNotification"
                label="Fréquence des notifications"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                referentielName="frequencesNotification"
                fullWidth
                fieldError={error}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="parametrage.mailNotification.1"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                label="Adresse mail notifications"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="parametrage.signature"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                label="Signature"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid justifyContent="flex-end" container item xs={12} columnSpacing={2} paddingTop={4}>
        <Grid item>
          <AnnulerButton onClick={onClose}>Annuler</AnnulerButton>
        </Grid>
        <Grid item>
          <SauvegarderButton disabled={!isValid} onClick={onSubmit}>
            Créer l&apos;organisation médecin
          </SauvegarderButton>
        </Grid>
      </Grid>
    </form>
  )
}

import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryGlobal } from 'plateforme/security/keycloak'
import PageRecherche, { ListRechercheRequest, PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import { OPTION_GROUPE } from 'plateforme/constantes'
import HabilitationEntreprise, {
  CreerHabilitationRequest,
  HabilitationEntrepriseFilter,
  HabilitationEntrepriseSortType,
  ModifierHabilitationRequest,
} from '../types/habilitationEntreprise'

export const habilitationEntrepriseApi = createApi({
  reducerPath: 'habilitationEntrepriseApi',
  baseQuery: baseQueryGlobal,
  tagTypes: ['HabilitationsEntreprise'],
  endpoints: (builder) => ({
    getListeHabilitationsEntreprise: builder.query<
      PageRecherche<HabilitationEntreprise>,
      PageRechercheRequest<HabilitationEntrepriseFilter, HabilitationEntrepriseSortType>
    >({
      query: ({ perPage, page, filter, sort, _timestamp, onlyTotal, headerGroupe }) => ({
        url: 'entreprise/habilitation',
        extraOptions: !headerGroupe || OPTION_GROUPE,
        headers: headerGroupe !== undefined ? { 'X-Profile-Id': headerGroupe } : undefined,
        params: {
          ...filter,
          ...sort,
          perPage,
          page,
          onlyTotal,
          _timestamp, // note : ce parametre sert a forcer le refetch dans le cas de useLazyGetXXXX
        },
      }),
    }),
    // NOTE: Cet endpoint récupère toute la liste des habilitations sans pagination.
    listerHabilitationsEntreprise: builder.query<
      HabilitationEntreprise[],
      ListRechercheRequest<HabilitationEntrepriseFilter, HabilitationEntrepriseSortType>
    >({
      query: ({ filter, sort, onlyTotal, headerGroupe, _timestamp }) => ({
        url: 'entreprise/habilitations',
        extraOptions: !headerGroupe || OPTION_GROUPE,
        headers: headerGroupe !== undefined ? { 'X-Profile-Id': headerGroupe } : undefined,
        params: {
          ...filter,
          ...sort,
          onlyTotal,
          _timestamp,
        },
      }),
      providesTags: ['HabilitationsEntreprise'],
    }),

    postCreerHabilitation: builder.mutation<HabilitationEntreprise, CreerHabilitationRequest>({
      query: ({ headerGroupe, ...body }) => ({
        url: 'entreprise/habilitation',
        extraOptions: !headerGroupe || OPTION_GROUPE,
        headers: headerGroupe !== undefined ? { 'X-Profile-Id': headerGroupe } : undefined,
        method: 'POST',
        body,
      }),
    }),
    putModifierHabilitation: builder.mutation<HabilitationEntreprise, ModifierHabilitationRequest>({
      query: ({ codeMedecin, clotureImmediate, headerGroupe, ...body }) => ({
        url: `entreprise/habilitation/${codeMedecin}?clotureImmediate=${clotureImmediate}`,
        extraOptions: !headerGroupe || OPTION_GROUPE,
        headers: headerGroupe !== undefined ? { 'X-Profile-Id': headerGroupe } : undefined,
        method: 'PUT',
        param: {
          clotureImmediate,
        },
        body,
      }),
    }),
  }),
})

export const {
  useGetListeHabilitationsEntrepriseQuery,
  useLazyGetListeHabilitationsEntrepriseQuery,
  useListerHabilitationsEntrepriseQuery,
  useLazyListerHabilitationsEntrepriseQuery,
  usePostCreerHabilitationMutation,
  usePutModifierHabilitationMutation,
} = habilitationEntrepriseApi

export const useGetListeHabilitationsActivesEntrepriseQuery = (
  {
    filter,
    perPage,
    page,
    sort,
    onlyTotal,
    headerGroupe,
  }: PageRechercheRequest<HabilitationEntrepriseFilter, HabilitationEntrepriseSortType>,
  { skip }: { skip: boolean } = { skip: false }
) =>
  useGetListeHabilitationsEntrepriseQuery(
    {
      perPage,
      page,
      sort,
      filter: {
        ...filter,
      },
      onlyTotal,
      headerGroupe,
    },
    { skip }
  )

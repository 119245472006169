/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Box, Grid, Tooltip } from '@mui/material'
import { GridSize } from '@mui/material/Grid/Grid'
import { AreaLoading, VoirPlusButton } from 'plateforme/components'

interface InfinitComponentProps {
  dataLength: number
  next: any
  hasMore: any
  children: any
  isLoading?: boolean
  xs?: boolean | GridSize
  sm?: boolean | GridSize
  md?: boolean | GridSize
  lg?: boolean | GridSize
  xl?: boolean | GridSize
  areaLoading?: JSX.Element
  onScroll?: ((e: MouseEvent) => any) | undefined
}

export default function InfiniteScrollComponent({
  dataLength,
  isLoading,
  next,
  hasMore,
  children,
  xs,
  sm,
  md,
  lg,
  xl,
  areaLoading = <AreaLoading height={180} />,
  onScroll,
}: InfinitComponentProps) {
  const loader = (
    <Grid item container xs={12}>
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        {areaLoading}
      </Grid>
    </Grid>
  )
  return (
    <InfiniteScroll
      style={{ overflow: 'hidden', width: '100%', paddingBottom: 8 }}
      dataLength={dataLength}
      next={next}
      hasMore={hasMore}
      onScroll={onScroll}
      loader={<Box sx={{ width: '100%', padding: 0, margin: 0, marginTop: 2.5 }}>{loader}</Box>}
      scrollThreshold="3px"
    >
      {children}
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: 0,
          margin: 0,
          marginTop: 2,
          marginBottom: 1.5,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Tooltip
          title="Le chargement des données est terminé"
          placement="bottom"
          disableInteractive={hasMore}
          disableHoverListener={hasMore}
          disableFocusListener={hasMore}
          disableTouchListener={hasMore}
        >
          {/* La div n'est ici que pour tricher, car sinon la tooltip ne s'affiche pas quand le bouton est disable */}
          <div>
            <VoirPlusButton onClick={next} disabled={!hasMore} loading={isLoading}>
              Voir plus
            </VoirPlusButton>
          </div>
        </Tooltip>
      </Box>
    </InfiniteScroll>
  )
}

import React from 'react'
import { Stack } from '@mui/material'
import NavigationPart from 'plateforme/parts/NavigationPart'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'
import { useSelector } from 'react-redux'
import { useGetListeMessagesQuery } from 'assureur/store/apis/messageEntrepriseApi'
import { getNotificationCriteria } from 'assureur/store/slices/searchCriteriaSlice/searchCriteriaEntrepriseSlice'
import { NotificationEntrepriseFilter } from 'assureur/store/types/notificationsEntreprise'
import RechercheNotificationsMessageEntreprisePart from 'assureur/parts/rechercheNotificationMessage/RechercheNotificationsMessageEntreprisePart'
import ListeNotificationsMessageEntreprisePart from 'assureur/parts/rechercheNotificationMessage/ListeNotificationsMessageEntreprisePart'
import { getNotificationMessageSort } from 'assureur/store/slices/sortSlice/sortSlice'
import useErrorSnackBar from 'plateforme/hooks/useErrorSnackBar'

export default function RechercheNotificationsMessageEntreprisePage() {
  // get default values of filter and sort params from slice
  const defaultNotificationCriteria = useSelector(getNotificationCriteria) as NotificationEntrepriseFilter
  const defaultNotificationSort = useSelector(getNotificationMessageSort)
  const {
    combinedData,
    readMore,
    remoteTotal,
    search,
    initSort,
    sortBy,
    isLoading: listeNotificationLoading,
    isFetching,
    isError: isErrorListeNotifications,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetListeMessagesQuery, {
    defaultCriteria: defaultNotificationCriteria,
    defaultSort: defaultNotificationSort,
  })

  const isLoading = listeNotificationLoading

  useErrorSnackBar(error, isErrorListeNotifications, 'La Recherche des notifications a échoué')

  return (
    <Stack>
      <NavigationPart label="Messages des médecins" />
      <RechercheNotificationsMessageEntreprisePart search={search} searchError={error} />
      <ListeNotificationsMessageEntreprisePart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        combinedData={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isErrorListeNotifications}
        isSuccess={isSuccess}
      />
    </Stack>
  )
}

import React from 'react'
import { Stack } from '@mui/material'
import NavigationPart from 'plateforme/parts/NavigationPart'
import { useGetMedecinsHabilitablesQuery } from 'assureur/store/apis/medecinEntrepriseApi'
import { FallbackError } from 'plateforme/components'
import { useErrorHandler } from 'react-error-boundary'
import RechercheMedecinsEntrepriseGroupePart from 'assureur/parts/rechercheMedecin/RechercheMedecinsEntrepriseGroupePart'
import ListeMedecinsEntrepriseGroupePart from 'assureur/parts/rechercheMedecin/ListeMedecinsEntrepriseGroupePart'
import { setSearchMedecin } from 'assureur/store/slices/searchMedecin/searchMedecinSlice'
import { useDispatch } from 'react-redux'
import MedecinEntreprise, { MedecinEntrepriseSortType } from 'assureur/store/types/medecinEntreprise'
import { SortParam } from 'plateforme/store/types/pageRecherche'
import { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'

// page de recherche des medecins groupe
export default function RechercheMedecinsEntrepriseGroupePage() {
  const handleError = useErrorHandler()
  const dispatch = useDispatch()

  const { isLoading, isError, error, data: combinedData } = useGetMedecinsHabilitablesQuery({})

  if (isError) {
    handleError({
      errorApi: error,
      title: 'Erreur chargement des medecins habilitables pour le groupe',
      api: 'useLazyGetMedecinsHabilitablesQuery',
    } as FallbackError)
  }

  // tri des médecins coté front
  const sortFrontMedecins = (param: SortParam<string>, medecins: MedecinEntreprise[]) => {
    let result: MedecinEntreprise[] | undefined = []

    if (param.sortType === MedecinEntrepriseSortType.LIBELLE_SORT) {
      result = medecins?.slice().sort((a, b) => {
        if (a.libelle === undefined) return 1
        if (b.libelle === undefined) return -1
        return param.sortOrder === 'ASC' ? a.libelle?.localeCompare(b.libelle) : b.libelle?.localeCompare(a.libelle)
      })
    }

    if (param.sortType === MedecinEntrepriseSortType.CODE_MEDECIN_SORT) {
      result = medecins?.slice().sort((a, b) => {
        const codeA = a.codeRPPS ?? a.codeSIREN ?? ''
        const codeB = b.codeRPPS ?? b.codeSIREN ?? ''
        return param.sortOrder === 'ASC' ? codeA.localeCompare(codeB) : codeB.localeCompare(codeA)
      })
    }

    if (result && result.length > 0) {
      dispatch(setSearchMedecin(result))
    }

    return result
  }

  return (
    <Stack>
      <NavigationPart label="Liste des médecins" />
      <RechercheMedecinsEntrepriseGroupePart
        searchError={error as IQueryErrorResponse}
        combinedData={combinedData ?? []}
        sortFrontFunction={sortFrontMedecins}
      />
      <ListeMedecinsEntrepriseGroupePart
        isLoading={isLoading}
        isError={isError}
        sortFrontFunction={sortFrontMedecins}
      />
    </Stack>
  )
}

import { TypeMessageNotification } from 'plateforme/store/types/referentiel'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { ReferenceDossier, ReferenceEntreprise } from './dossierMedecin'
import { ReferenceMedecin } from './profilMedecin'
import { ReferenceMission } from './missionMedecin'

export interface NotificationMedecin {
  code?: string
  type?: TypeMessageNotification
  labelType?: string
  date: Date
  entreprise?: ReferenceEntreprise
  medecin?: ReferenceMedecin
  dossier?: ReferenceDossier
  mission?: ReferenceMission
  documents?: DocumentTeleverse[]
  objet?: string
  message?: string
  lu?: 0 | 1
}

export interface MessageVersAssureur extends NotificationMedecin {
  type: 'MEL_MED'
  message: string
  objet: string
}

export interface NotificationMedecinFilter {
  motCle?: string
  objet?: string
  typesExclus?: TypeMessageNotification[]
  types?: TypeMessageNotification[]
  codeMedecin?: string
  codeEntreprise?: string
  labelEntreprise?: string
  codeEntrepriseDest?: string
  labelEntrepriseDest?: string
  dateDebut?: string
  dateFin?: string
  lu?: 0 | 1
  tous?: 0 | 1
}

export enum NotificationMedecinDossierSortType {
  DATE_CREATION_SORT = 'date_creation',
  TYPE_NOTIFICATION = 'type_notification',
  DOSSIER = 'dossier',
  ENTREPRISE = 'entreprise',
  STATUT_NOTIFICATION = 'statut',
}

export enum NotificationMedecinMessageSortType {
  DATE_CREATION_SORT = 'date_creation',
  DESTINATAIRE = 'entreprise_dest',
  OBJET = 'objet',
  STATUT_NOTIFICATION = 'statut',
}

import React from 'react'
import { Stack } from '@mui/material'
import ListeHabilitationsEntrepriseAdminPart from 'admin/part/HabilitationsEntrepriseAdminPart/ListeHabilitationsEntrepriseAdminPart'
import RechercheHabilitationsEntrepriseAdminPart from 'admin/part/HabilitationsEntrepriseAdminPart/RechercheHabilitationsEntrepriseAdminPart'
import { useGetListeHabilitationEntrepriseAdminQuery } from 'admin/store/apis/habilitationEntrepriseAdminApi'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll'
import { useSelector } from 'react-redux'
import { getHabilitationEntrepriseSort } from 'admin/store/slices/sortSlice/sortSlice'
import useErrorSnackBar from 'plateforme/hooks/useErrorSnackBar'

interface TabHabilitationsEntrepriseAdminProps {
  codeEntreprise?: string
}

export default function TabHabilitationsEntrepriseAdminPart({ codeEntreprise }: TabHabilitationsEntrepriseAdminProps) {
  if (codeEntreprise === undefined || codeEntreprise.length === 0) {
    throw new Error('Aucun code entreprise défini')
  }

  // get default values of sort params from slice
  const defaultHabilitationSort = useSelector(getHabilitationEntrepriseSort)

  const {
    combinedData,
    exportParams,
    readMore,
    remoteTotal,
    search,
    initSort,
    sortBy,
    isLoading: listeHabilitationLoading,
    isFetching,
    isError: isErrorListeHabilitations,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetListeHabilitationEntrepriseAdminQuery, {
    defaultCriteria: { codeEntreprise },
    defaultSort: defaultHabilitationSort,
  })

  const isLoading = listeHabilitationLoading

  useErrorSnackBar(error, isErrorListeHabilitations, 'La Recherche des habilitations a échoué')

  return (
    <Stack>
      <RechercheHabilitationsEntrepriseAdminPart codeEntreprise={codeEntreprise} search={search} searchError={error} />
      <ListeHabilitationsEntrepriseAdminPart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        combinedData={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isErrorListeHabilitations}
        isSuccess={isSuccess}
        exportParams={exportParams}
      />
    </Stack>
  )
}

import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { endOfYesterday, startOfYesterday } from 'date-fns'
import { Alert, Card, CardContent, CardHeader, Grid, Snackbar } from '@mui/material'
import {
  ButtonsStack,
  ChercherButton,
  DateTimePickerInput,
  EditTextField,
  RechargerButton,
  SelectInput,
  SelectOption,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { numberToUndefined, parseIntSafe, trimToUndefined } from 'plateforme/services/utils'
import { formatDateTimeISO } from 'plateforme/services/dates.services'
import {
  getFluxTechniqueCriteria,
  setFluxTechniqueCriteria,
} from 'admin/store/slices/searchCriteriaSlice/searchCriteriaAdminSlice'
import { AllHttpMethods, FluxTechniqueAdminFilter, HttpMethod } from 'admin/store/types/fluxTechniqueAdmin'
import { setFluxTechniquesSort } from 'admin/store/slices/sortSlice/sortSlice'
import AutocompleteEntreprise from 'medecin/components/AutocompleteEntreprise'
import { useGetReferentielEntrepriseAdminQuery } from 'admin/store/apis/gestionEntrepriseApi'
import { ReferentielEntreprise } from 'plateforme/store/types/profilEntreprise'

type SearchFluxTechniquesProps = {
  search: (criteria: FluxTechniqueAdminFilter) => void
  searchError: IQueryErrorResponse
}

export interface FluxTechniqueFilterData {
  dateDebut: Date
  dateFin: Date
  idEntreprise: SelectOption | null
  idMedecin: number | null | undefined
  action: string
  detail: string
  httpMethod: HttpMethod | ''
  httpPath: string
  httpStatusCode: string
}

export default function RechercheFluxTechniquesAdminPart({ search, searchError }: SearchFluxTechniquesProps) {
  const dispatch = useDispatch()
  // get default values from localStorage
  const defaultCriteria = useSelector(getFluxTechniqueCriteria) as FluxTechniqueAdminFilter

  // Les valeurs initiales du formulaire :
  const initialValues: FluxTechniqueFilterData = {
    dateDebut: defaultCriteria?.dateDebut ? new Date(defaultCriteria.dateDebut) : startOfYesterday(),
    dateFin: defaultCriteria?.dateFin ? new Date(defaultCriteria.dateFin) : endOfYesterday(),
    idEntreprise: defaultCriteria?.idEntreprise
      ? ({ code: defaultCriteria?.idEntreprise, label: defaultCriteria.labelEntreprise } as SelectOption)
      : null,
    idMedecin: defaultCriteria?.idMedecin ?? null,
    action: defaultCriteria?.action ?? '',
    detail: defaultCriteria?.detail ?? '',
    httpMethod: defaultCriteria?.httpMethod ?? '',
    httpPath: defaultCriteria?.httpPath ?? '',
    httpStatusCode: defaultCriteria?.httpStatusCode?.toString() ?? '',
  }

  const resetValues: FluxTechniqueFilterData = {
    dateDebut: startOfYesterday(),
    dateFin: endOfYesterday(),
    idEntreprise: null,
    idMedecin: null,
    action: '',
    detail: '',
    httpMethod: '',
    httpPath: '',
    httpStatusCode: '',
  }

  const {
    handleSubmit,
    control,
    setError,
    getValues,
    reset,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })
  useErrorFormMapper(searchError, setError, getValues)

  // state management
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const onSubmit = async (data: FluxTechniqueFilterData) => {
    const filtre = {
      idEntreprise: trimToUndefined(data.idEntreprise?.code),
      idMedecin: numberToUndefined(data.idMedecin),
      dateDebut: formatDateTimeISO(data.dateDebut),
      dateFin: formatDateTimeISO(data.dateFin),
      action: trimToUndefined(data.action),
      detail: trimToUndefined(data.detail),
      httpMethod: trimToUndefined(data.httpMethod) as HttpMethod,
      httpPath: trimToUndefined(data.httpPath),
      httpStatusCode: parseIntSafe(data.httpStatusCode),
    }
    search(filtre)
    dispatch(setFluxTechniqueCriteria({ ...filtre, labelEntreprise: trimToUndefined(data.idEntreprise?.label) }))
  }

  const handleClickInitSearch = () => {
    const filtre = {
      idEntreprise: undefined,
      idMedecin: undefined,
      dateDebut: formatDateTimeISO(resetValues.dateDebut),
      dateFin: formatDateTimeISO(resetValues.dateFin),
      httpMethod: undefined,
      action: undefined,
      detail: undefined,
      httpPath: undefined,
      httpStatusCode: parseIntSafe(resetValues.httpStatusCode),
    }
    reset(resetValues)
    search(filtre)
    dispatch(setFluxTechniqueCriteria(filtre))
    dispatch(setFluxTechniquesSort({}))
  }

  // Snackbar handlers :
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <Card>
      <CardHeader title="Recherche avancée" />
      <CardContent sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-flux-techniques">
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Controller
                name="dateDebut"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DateTimePickerInput
                    InputProps={{
                      id: 'dateDebut',
                      fullWidth: true,
                    }}
                    label="Date de début"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="dateFin"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DateTimePickerInput
                    InputProps={{
                      id: 'dateFin',
                      fullWidth: true,
                    }}
                    label="Date de fin"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="idEntreprise"
                control={control}
                defaultValue={initialValues.idEntreprise}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <AutocompleteEntreprise
                    id="entreprise-fluxTechnique"
                    label="Entreprise"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    useQuery={useGetReferentielEntrepriseAdminQuery}
                    fullWidth
                    withNoSelectionItem
                    dataTransformer={(data: ReferentielEntreprise[]) => {
                      return data.map((ref) => {
                        return {
                          code: ref.id.toString(),
                          label: ref.libelle,
                          data: { codeACPR: ref.codeACPR, codeSIREN: ref.codeSIREN },
                        }
                      })
                    }}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="idMedecin"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="medecin-fluxTechnique"
                    label="Médecin (à venir)"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                    readOnly
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="action"
                control={control}
                defaultValue={initialValues.action}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="action"
                    label="Action"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="detail"
                control={control}
                defaultValue={initialValues.detail}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="detail"
                    label="Détail de l'action"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="httpMethod"
                control={control}
                defaultValue={initialValues.httpMethod}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInput
                    id="httpMethod"
                    label="Méthode HTTP"
                    options={[...AllHttpMethods.map((m) => ({ code: m, label: m }))]}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="httpPath"
                control={control}
                defaultValue={initialValues.httpPath}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="httpPath"
                    label="Chemin HTTP"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="httpStatusCode"
                control={control}
                defaultValue={initialValues.httpStatusCode}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    type="number"
                    id="httpStatusCode"
                    label="Code statut HTTP"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonsStack>
                <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                  Réinitialiser
                </RechargerButton>
                <ChercherButton
                  variant="contained"
                  type="submit"
                  form="form-recherche-flux-techniques"
                  disabled={!isValid}
                >
                  Rechercher
                </ChercherButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={openSnackbar} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            Erreur serveur lors de la recherche des flux techniques
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  )
}

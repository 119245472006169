import React, { ReactNode } from 'react'
import { FieldError } from 'react-hook-form'
import { Noop } from 'react-hook-form/dist/types/utils'
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import {
  BACKGROUND_COLORS,
  DEFAULT_OPTION,
  ALL_OPTION_CODE,
  NO_OPTION_CODE,
  ALL_OPTION,
  AFFECTATION_CENTRALE_VALUE_OPTION,
} from 'plateforme/constantes'
import ReadOnlyTextField from './ReadOnlyTextField'
import { MessageAlert } from '../index'

export interface SelectOption {
  code: string
  label: string
}

export interface SelectComponentProps {
  id: string
  label: string
  value?: string | string[] | number | null
  onBlur?: Noop
  onChange?: (value: string) => void
  onChangeMultiple?: (value: string[]) => void
  onChangeRaw?: (event: SelectChangeEvent, child: React.ReactNode) => void
  fullWidth?: boolean
  required?: boolean
  sx?: SxProps
  disabled?: boolean
  readOnly?: boolean
  multiple?: boolean
  requiredSelection?: boolean
  error?: boolean
  fieldError?: FieldError
  helperText?: string
  withNoSelectionItem?: boolean
  withAllSelectionItem?: boolean
  options: SelectOption[]
  optionsLoader?: {
    isLoading?: boolean
    isError?: boolean
  }
  withColors?: boolean
  optionsAdded?: SelectOption[]
}

export default function SelectInput({
  id,
  label,
  value,
  onBlur,
  onChange,
  onChangeMultiple,
  onChangeRaw,
  options,
  fullWidth,
  required,
  multiple,
  requiredSelection,
  sx,
  disabled,
  readOnly,
  error,
  fieldError,
  helperText,
  withNoSelectionItem,
  withAllSelectionItem,
  optionsLoader,
  withColors,
  optionsAdded,
}: SelectComponentProps) {
  const handleChange = (event: SelectChangeEvent<string | string[]>, child: React.ReactNode) => {
    if (onChangeRaw) {
      onChangeRaw(event as SelectChangeEvent<string>, child)
    }
    if (onChange && !multiple) {
      onChange(event.target.value as string)
    } else if (onChangeMultiple && multiple) {
      if (requiredSelection && event.target.value.length === 0) {
        return
      }
      if ((event.target.value as string[]).some((v) => v === NO_OPTION_CODE)) {
        onChangeMultiple([])
      } else if ((event.target.value as string[]).some((v) => v === ALL_OPTION_CODE)) {
        onChangeMultiple([...options.map((option) => option.code)])
      } else {
        onChangeMultiple(event.target.value as string[])
      }
    }
  }

  let items: JSX.Element[]
  if (optionsLoader?.isLoading) {
    items = [loadingItem()]
  } else if (optionsLoader?.isError) {
    items = [errorItem(fieldError?.message)]
  } else {
    items = successItems(options, withNoSelectionItem, withAllSelectionItem, optionsAdded)
  }
  const isLoaderSuccess = !optionsLoader?.isLoading && !optionsLoader?.isError
  const isDisabled = !isLoaderSuccess || disabled
  const isReadOnly = !isLoaderSuccess || readOnly
  const isError = error || fieldError !== undefined || optionsLoader?.isError

  const formHelperText = fieldError?.message ?? helperText

  function getValue() {
    if (multiple) {
      return isLoaderSuccess && value ? (value as string[]) : []
    }
    return isLoaderSuccess && value ? (value as string) : DEFAULT_OPTION.code
  }

  if (readOnly && !multiple) {
    return (
      <ReadOnlyTextField
        fullWidth={fullWidth}
        id={id}
        value={options.find((o) => o.code === getValue())?.label ?? ' '}
        label={label}
      />
    )
  }

  return (
    <FormControl
      variant="filled"
      fullWidth={fullWidth}
      required={required}
      sx={sx}
      disabled={isDisabled}
      error={isError}
    >
      <InputLabel shrink id={`${id}-label`}>
        {label}
      </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={getValue()}
        onChange={handleChange}
        onBlur={onBlur}
        multiple={multiple}
        displayEmpty={!isLoaderSuccess}
        readOnly={isReadOnly}
        IconComponent={isReadOnly ? Box : ExpandMore}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        renderValue={
          multiple && !isError
            ? (selected: string[]) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1px' }}>
                  {selected.map((code, index) => (
                    <Chip
                      sx={{
                        backgroundColor: withColors ? BACKGROUND_COLORS[index] : 'primary',
                        '.MuiChip-label': { padding: '5px' },
                      }}
                      size="small"
                      key={code}
                      label={[...options, ...(optionsAdded ?? [])].find((o) => o.code === code)?.label}
                    />
                  ))}
                </Box>
              )
            : undefined
        }
      >
        {items}
      </Select>
      {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
    </FormControl>
  )
}

function emptyItem(children?: ReactNode) {
  return (
    <MenuItem value="" key="">
      <Box display="flex" alignItems="center" justifyContent="center">
        {children}
      </Box>
    </MenuItem>
  )
}

function errorItem(message?: string) {
  return emptyItem(<MessageAlert severity="error">{message ?? 'Erreur de chargement de données'}</MessageAlert>)
}

function loadingItem() {
  return emptyItem(<CircularProgress size="1rem" />)
}

function successItems(
  options: SelectOption[],
  withNoSelectionItem?: boolean,
  withAllSelectionItem?: boolean,
  optionAdded?: SelectOption[]
) {
  const items = optionAdded && optionAdded.length > 0 ? optionAdded.concat(options) : options

  const finalItems = [
    withNoSelectionItem ? DEFAULT_OPTION : undefined,
    withAllSelectionItem ? ALL_OPTION : undefined,
    ...items,
  ].filter((option) => option !== undefined)

  return finalItems.map((option) => (
    <MenuItem key={option?.code} value={option?.code}>
      {option &&
      [DEFAULT_OPTION.code, ALL_OPTION.code, AFFECTATION_CENTRALE_VALUE_OPTION.code].includes(option.code) ? (
        <i>{option?.label}</i>
      ) : (
        option?.label
      )}
    </MenuItem>
  ))
}

import { Card, CardContent, FormControl, FormControlLabel, FormHelperText, Grid, Stack, Switch } from '@mui/material'
import { EditTextField, SelectInput, SelectInputReferentiel } from 'plateforme/components'
import { Controller, useFormContext } from 'react-hook-form'
import React from 'react'
import { ParametrageLogo } from 'plateforme/store/types/profilEntreprise'
import EntrepriseLogo from 'plateforme/components/images/EntrepriseLogo'

interface OrganisationPartProps {
  readOnly?: boolean
}

export default function OrganisationPart({ readOnly }: OrganisationPartProps) {
  const { control, watch, clearErrors, getValues } = useFormContext()
  const isCanalWsActive = watch('parametrage.canalWs')
  const codeEntreprise = getValues('code')

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Controller
              name="code"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  readOnly
                  label="Code organisation"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="libelle"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  readOnly={readOnly}
                  label="Raison sociale"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <Stack width="100%">
                <Grid item xs={12}>
                  <Controller
                    name="adresse1"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <EditTextField
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldError={error}
                        readOnly={readOnly}
                        label="Adresse 1"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="adresse2"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <EditTextField
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldError={error}
                        readOnly={readOnly}
                        label="Adresse 2"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="adresse3"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <EditTextField
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldError={error}
                        readOnly={readOnly}
                        label="Adresse 3"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Stack>
              <Stack justifyContent="center" alignItems="center">
                <EntrepriseLogo codeEntreprise={codeEntreprise} width={200} height={200} />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="codePostal"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  readOnly={readOnly}
                  label="Code postal"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="commune"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  readOnly={readOnly}
                  label="Ville"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="pays"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectInputReferentiel
                  id="pays"
                  label="Pays"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  referentielName="pays"
                  readOnly={readOnly}
                  fullWidth
                  fieldError={error}
                  dataFormatter={(code, label) => {
                    return `${label}`
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="parametrage.canalWs"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="canalWs"
                    value={value}
                    control={
                      <Switch
                        onChange={(e) => {
                          clearErrors('parametrage.clientId')
                          onChange(e)
                        }}
                        onBlur={onBlur}
                        checked={value}
                      />
                    }
                    label="Canal web service (échanges automatisés) activé"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          {isCanalWsActive && (
            <Grid item xs={12}>
              <Controller
                name="parametrage.clientId"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <EditTextField
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    fieldError={error}
                    readOnly={readOnly}
                    label="Client ID"
                    fullWidth
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              name="parametrage.lettreMission"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="lettreMission"
                    value={value}
                    control={<Switch onChange={onChange} onBlur={onBlur} checked={value} />}
                    label="Génération lettre de mission"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="parametrage.parametrageLogo"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectInput
                  fullWidth
                  id="parametrage.parametrageLogo"
                  label="Paramétrage PDF lettre de mission"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  options={[
                    { code: ParametrageLogo.LOGO, label: 'Logo' },
                    { code: ParametrageLogo.LOGO_ET_RAISON_SOCIALE, label: 'Logo et raison sociale' },
                    { code: ParametrageLogo.RAISON_SOCIALE, label: 'Raison sociale' },
                    { code: ParametrageLogo.AUCUN, label: 'Aucun' },
                  ]}
                  fieldError={error}
                  readOnly={readOnly}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="parametrage.mailReseauMedecin"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  readOnly={readOnly}
                  label="Adresse mail réseau médecin"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

import React from 'react'
import { Alert, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import {
  AreaLoading,
  InfiniteScrollComponent,
  NoRowsOverlay,
  ResultatRechercheBarCard,
  VoirIconButton,
} from 'plateforme/components'
import { formatDateTimeFR } from 'plateforme/services/dates.services'
import { SortOption, SortParam } from 'plateforme/store/types/pageRecherche'
import { useNavigate } from 'react-router-dom'
import { consultationDossierHref, consultationFicheMedecinHref } from 'assureur/EntrepriseApp'
import {
  NotificationEntreprise,
  NotificationEntrepriseDossierSortType,
} from 'assureur/store/types/notificationsEntreprise'
import { makeEllipsis } from 'plateforme/services/utils'
import { TabsConsultationDossierEntrepriseTabId } from 'assureur/parts/consultationDossier/TabsConsultationDossierEntreprise'
import useTabNavigate from 'plateforme/hooks/useTabNavigate'
import { isVoirNotificationEnable, tooltipVoirNotification } from 'plateforme/services/notification.services'
import { getNotificationDossierSort, setNotificationDossierSort } from 'assureur/store/slices/sortSlice/sortSlice'
import { NOTIFICATIONS_SILENCIEUSES } from 'plateforme/constantes'

type ListeNotificationsDossierEntreprisePartProps = {
  combinedData: Array<NotificationEntreprise>
  total?: number
  readMore: VoidFunction
  sortBy: (param: SortParam<NotificationEntrepriseDossierSortType>) => void
  initSort: VoidFunction
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  isSuccess: boolean
}

export default function ListeNotificationsDossierEntreprisePart({
  combinedData,
  total,
  readMore,
  sortBy,
  initSort,
  isError,
  isLoading,
  isFetching,
  isSuccess,
}: ListeNotificationsDossierEntreprisePartProps) {
  const navigate = useNavigate()
  const { navigateTab } = useTabNavigate()

  // les options de tri disponibles a passer au composant SortComponentMenu
  const sortOptions: SortOption<NotificationEntrepriseDossierSortType>[] = [
    { sortType: NotificationEntrepriseDossierSortType.TYPE_NOTIFICATION, label: 'Type de notification' },
    { sortType: NotificationEntrepriseDossierSortType.DOSSIER, label: 'Référence dossier entreprise' },
    { sortType: NotificationEntrepriseDossierSortType.MEDECIN, label: 'Médecin' },
    { sortType: NotificationEntrepriseDossierSortType.STATUT_NOTIFICATION, label: 'Statut' },
    { sortType: NotificationEntrepriseDossierSortType.DATE_CREATION_SORT, label: 'Date' },
  ]

  const handleClickVoirNotification = (notifEntreprise: NotificationEntreprise) => {
    if (notifEntreprise.type === 'CREA_HAB') {
      return navigate(consultationFicheMedecinHref(notifEntreprise.medecin?.code ?? 'undefined'))
    }
    if (notifEntreprise.type === 'MEL_MIS') {
      return navigateTab(
        TabsConsultationDossierEntrepriseTabId.MESSAGES_TAB,
        consultationDossierHref(notifEntreprise.dossier?.code ?? 'undefined'),
        notifEntreprise?.mission?.code
      )
    }
    return navigate(consultationDossierHref(notifEntreprise.dossier?.code as string))
  }

  const displayLuNonLu = (notification: NotificationEntreprise) => {
    if (notification.type && NOTIFICATIONS_SILENCIEUSES.includes(notification.type)) {
      return '--'
    }
    return notification.lu ? 'Lue' : 'Non lue'
  }

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      sx={{ '& .infinite-scroll-component__outerdiv': { width: '100%' } }}
    >
      <Grid item container xs={12} paddingBottom={2.5}>
        <ResultatRechercheBarCard
          title="Liste des notifications"
          total={total}
          sortBy={sortBy}
          sortOptions={sortOptions}
          initSort={initSort}
          withSorting
          withExportCSV={false}
          setSortSlice={setNotificationDossierSort}
          getSortSlice={getNotificationDossierSort}
        />
      </Grid>
      {isError && !isSuccess && !isLoading && !isFetching ? (
        <Alert severity="info" sx={{ width: '100%' }}>
          Aucune donnée
        </Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '30%' }}>Type de notification</TableCell>
                <TableCell sx={{ width: '20%' }}>Référence dossier entreprise</TableCell>
                <TableCell sx={{ width: '20%' }}>Médecin</TableCell>
                <TableCell sx={{ width: '10%' }}>Statut</TableCell>
                <TableCell sx={{ width: '10%' }}>Date/Heure</TableCell>
                <TableCell sx={{ width: '10%' }} align="center" />
              </TableRow>
            </TableHead>
          </Table>
          {total && total > 0 ? (
            <InfiniteScrollComponent
              dataLength={combinedData?.length}
              next={readMore}
              hasMore={combinedData.length < (total ?? 0)}
              isLoading={isLoading || isFetching}
              areaLoading={<AreaLoading height={90} />}
              xs={12}
            >
              {combinedData?.map((notification: NotificationEntreprise) => (
                <Table key={notification.code}>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ width: '30%' }}>{notification.labelType}</TableCell>
                      <TableCell sx={{ width: '20%' }}>
                        {notification.dossier ? makeEllipsis(notification.dossier?.libelle, 40) : '--'}
                      </TableCell>
                      <TableCell sx={{ width: '20%' }}>{notification.medecin?.libelle}</TableCell>
                      <TableCell sx={{ width: '10%' }}>{displayLuNonLu(notification)}</TableCell>
                      <TableCell sx={{ width: '10%' }}>{formatDateTimeFR(notification.date)}</TableCell>
                      <TableCell sx={{ width: '10%', paddingRight: 4 }} align="right">
                        <VoirIconButton
                          disabled={!isVoirNotificationEnable(notification)}
                          color="tile"
                          onClick={() => handleClickVoirNotification(notification)}
                          tooltip={tooltipVoirNotification(notification)}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ))}
            </InfiniteScrollComponent>
          ) : (
            <Table>
              <NoRowsOverlay />
            </Table>
          )}
        </TableContainer>
      )}
    </Grid>
  )
}

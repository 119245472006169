import { TypeMessageNotification } from 'plateforme/store/types/referentiel'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { ReferenceDossier } from './dossierEntreprise'
import { ReferenceMedecin, ReferenceMission } from './missionEntreprise'

export interface NotificationEntreprise {
  code: string
  type?: TypeMessageNotification
  labelType?: string
  date?: Date
  medecin?: ReferenceMedecin
  dossier?: ReferenceDossier
  mission?: ReferenceMission
  documents?: DocumentTeleverse[]
  objet?: string
  lu?: 0 | 1
}

export interface NotificationEntrepriseFilter {
  motCle?: string
  objet?: string
  typesExclus?: TypeMessageNotification[]
  types?: TypeMessageNotification[]
  codeMedecin?: string
  labelMedecin?: string
  codeEntreprise?: string
  codeExpediteur?: string
  dateDebut?: string
  dateFin?: string
  lu?: 0 | 1
  tous?: 0 | 1
}

export enum NotificationEntrepriseDossierSortType {
  DATE_CREATION_SORT = 'date_creation',
  TYPE_NOTIFICATION = 'type_notification',
  DOSSIER = 'dossier',
  MEDECIN = 'expediteur',
  STATUT_NOTIFICATION = 'statut',
}

export enum NotificationEntrepriseMessageSortType {
  DATE_CREATION_SORT = 'date_creation',
  EXPEDITEUR = 'expediteur',
  DESTINATAIRE = 'entreprise',
  OBJET = 'objet',
  STATUT_NOTIFICATION = 'statut',
}

import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { EditTextField, SelectInputReferentiel } from 'plateforme/components'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type PreferencesPlateformeAdminMedecinPartProps = {
  readOnly: boolean
}

export default function PreferencesPlateformeAdminMedecinPart({
  readOnly,
}: PreferencesPlateformeAdminMedecinPartProps) {
  const { control, clearErrors } = useFormContext()

  return (
    <Card>
      <CardHeader title="Préférences plateforme" />
      <CardContent>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          rowSpacing={2}
          columnSpacing={2}
          padding={2}
        >
          <Grid item xs={12}>
            <Controller
              name="parametrage.mailNotification.0"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  value={value}
                  onChange={(e) => {
                    clearErrors('parametrage.mailNotification._error')
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  fieldError={error}
                  label="Adresse mail notifications"
                  fullWidth
                  readOnly={readOnly}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="parametrage.mailNotification.1"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  label="Adresse mail notifications"
                  fullWidth
                  readOnly={readOnly}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="parametrage.frequenceNotification"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectInputReferentiel
                  id="frequenceNotification"
                  label="Fréquence des notifications"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  referentielName="frequencesNotification"
                  fullWidth
                  fieldError={error}
                  readOnly={readOnly}
                  dataFormatter={(code, label) => {
                    return `${label}`
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="parametrage.signature"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  label="Signature"
                  fullWidth
                  readOnly={readOnly}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="commentaireActivite"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  label="Commentaire activité"
                  fullWidth
                  readOnly={readOnly}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

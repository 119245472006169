import React from 'react'
import { Card, CardContent, Checkbox, Grid, Typography, Box } from '@mui/material'
import { formatDateFR } from 'plateforme/services/dates.services'
import { StatutHabilitationChip } from 'plateforme/components'
import HabilitationMedecin from 'medecin/store/types/habilitationMedecin'
import EntrepriseLogo from 'plateforme/components/images/EntrepriseLogo'

interface HabilitationMedecinCardProps {
  habilitation: HabilitationMedecin
  isAdmin?: boolean
}

function ValueLabled({ label, value }: { label?: string; value?: string }) {
  return (
    <Box sx={{ flexDirection: 'column' }}>
      <Typography variant="body2" color="readOnly.main">
        {label}
      </Typography>
      <Typography variant="body2" color="text.primary" gutterBottom>
        {value}
      </Typography>
    </Box>
  )
}

export default React.memo(HabilitationMedecinCard)

function HabilitationMedecinCard({ habilitation, isAdmin = false }: HabilitationMedecinCardProps) {
  const {
    code,
    numeroHabilitation,
    libelle: entreprise,
    expertiseSurPiece,
    intervenantOccasionnel,
    dateDebut,
    dateFin,
  } = habilitation

  const dateDebutFormatee = formatDateFR(dateDebut)
  const dateFinFormatee = formatDateFR(dateFin)

  const habilitationInfo = `${intervenantOccasionnel ? 'Intervenant occasionnel' : ''}${
    intervenantOccasionnel && expertiseSurPiece ? ' - ' : ''
  }${expertiseSurPiece ? 'Avis technique sur pièce' : ''}`

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Box sx={{ display: 'flex', width: '80px', alignItems: 'center' }}>
          <Checkbox color="default" />
        </Box>
        <Grid container spacing={2} width="100%">
          <Grid item xs={6}>
            {isAdmin === false && <EntrepriseLogo codeEntreprise={code} width={100} height={100} />}
            <Typography align="left" variant="h3" sx={{ fontWeight: 'bold' }} color="text.primary">
              {`${entreprise}`}
            </Typography>
            {numeroHabilitation && (
              <Typography variant="body2" color="readOnly.main">{`Référence ${numeroHabilitation}`}</Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <StatutHabilitationChip habilitation={habilitation} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="readOnly.main">
              {habilitationInfo}&nbsp;
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <ValueLabled label="Date de début" value={dateDebutFormatee} />
          </Grid>
          <Grid item xs={3}>
            {dateFin && <ValueLabled label="Date de fin" value={dateFinFormatee} />}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid, Stack } from '@mui/material'
import { SauvegarderButton } from 'plateforme/components'
import MissionsSpecifiquesPart from './MissionsSpecifiquesPart'

interface TabMissionsSpecifiquesPartProps {
  onSubmit?: VoidFunction
}

export default function TabMissionsSpecifiquesPart({ onSubmit }: TabMissionsSpecifiquesPartProps) {
  const { ...methods } = useFormContext()
  const readOnly = !onSubmit
  const formId = 'form-parametres-missions-specifiques'
  return (
    <form onSubmit={onSubmit && methods.handleSubmit(onSubmit)} id={formId} name={formId}>
      <Grid container justifyContent="flex-start" alignItems="flex-start">
        <Grid item container md={12} lg={12}>
          <Grid item xs={12}>
            <MissionsSpecifiquesPart readOnly={readOnly} />
          </Grid>
          {!readOnly && (
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="right">
                <SauvegarderButton onClick={onSubmit}>Sauvegarder</SauvegarderButton>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  )
}

import React from 'react'
import { Chip, Tooltip } from '@mui/material'

export enum StatutMedecin {
  INSCRIPTION = 'INSCRIPTION',
  ACTIF = 'ACTIF',
  SUSPENDU = 'SUSPENDU',
  SUPPRIME = 'SUPPRIME',
}

export const getColorStatutMedecin = (statut?: StatutMedecin) => {
  switch (statut) {
    case StatutMedecin.ACTIF:
      return '#4db251'
    case StatutMedecin.INSCRIPTION:
      return '#e9de43'
    case StatutMedecin.SUSPENDU:
      return '#f17913'
    case StatutMedecin.SUPPRIME:
      return '#e74242'
    default:
      return '#e74242'
  }
}

export function chipStatutMedecin(statut?: StatutMedecin, label?: string) {
  return (
    <Tooltip title="Statut plateforme">
      <Chip label={label} sx={{ backgroundColor: getColorStatutMedecin(statut) }} />
    </Tooltip>
  )
}

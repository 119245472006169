import React from 'react'
import { Stack } from '@mui/material'
import NavigationPart from 'plateforme/parts/NavigationPart'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'
import RechercheFacturesMedecinPart from 'medecin/parts/rechercheFacture/RechercheFacturesPart'
import ListeFacturesMedecinPart from 'medecin/parts/rechercheFacture/ListeFacturesMedecinPart'
import { getFactureCriteria } from 'medecin/store/slices/searchCriteriaSlice/searchCriteriaSlice'
import { useSelector } from 'react-redux'
import { useGetRechercheFactureQuery } from 'medecin/store/apis/dossierMedecinApi'
import { getFactureSort } from 'medecin/store/slices/sortSlice/sortSlice'

export default function RechercheFacturesMedecinPage() {
  // get default values of filter and sort params from slice
  const defaultFactureCriteria = useSelector(getFactureCriteria)
  const defaultFactureSort = useSelector(getFactureSort)
  const {
    combinedData,
    exportParams,
    readMore,
    remoteTotal,
    search,
    initSort,
    sortBy,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetRechercheFactureQuery, {
    defaultCriteria: defaultFactureCriteria,
    defaultSort: defaultFactureSort,
  })

  return (
    <Stack>
      <NavigationPart label="Liste des factures " />
      <RechercheFacturesMedecinPart searchError={error} search={search} />
      <ListeFacturesMedecinPart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        combinedData={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        isSuccess={isSuccess}
        exportParams={exportParams}
      />
    </Stack>
  )
}

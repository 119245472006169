import { ReferentielEntrepriseFilter, ReferentielEntrepriseSortType } from 'admin/store/types/profilEntrepriseAdmin'
import { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import DossierAdmin from 'admin/store/types/dossierAdmin'
import MissionAdmin from 'admin/store/types/missionAdmin'

// Nombre des entreprises référencées
// eslint-disable-next-line import/prefer-default-export
export const totalEntreprisesReferencesRequest = () =>
  ({
    onlyTotal: 1,
  } as PageRechercheRequest<ReferentielEntrepriseFilter, ReferentielEntrepriseSortType>)

export function determineDerniereMission(dossier?: DossierAdmin): MissionAdmin | undefined {
  return dossier?.missions?.at(0)
}

import React from 'react'
import { useSnackbar } from 'notistack'
import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material'
import {
  AnnulerButton,
  ButtonsStack,
  ReadOnlyDatePicker,
  ReadOnlyTextField,
  SupprimerButton,
} from 'plateforme/components'

import { formatDateISO, isDateAfterNow } from 'plateforme/services/dates.services'
import HabilitationEntreprise, {
  HabilitationEntrepriseSortType,
  ModifierHabilitationRequest,
} from 'assureur/store/types/habilitationEntreprise'
import { usePutModifierHabilitationMutation } from 'assureur/store/apis/habilitationEntrepriseApi'
import { SortParam } from 'plateforme/store/types/pageRecherche'
import MedecinEntreprise from 'assureur/store/types/medecinEntreprise'
import { medecinEntrepriseApi } from 'assureur/store/apis/medecinEntrepriseApi'
import { useDispatch } from 'react-redux'

interface HabilitationMedecinFormProps {
  medecin: MedecinEntreprise
  habilitations?: HabilitationEntreprise[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetchHabilitations: (filter: any) => void
  onClose: VoidFunction
}

export interface EntreprisesGroupe {
  libelle: string
  code: string
  headerValue?: string
  intervenantOccasionnelParametrage?: boolean
  expertiseSurPieceParametrage?: boolean
}

export default function CloturerHabilitationMedecinGroupeForm({
  medecin,
  habilitations,
  onClose,
  refetchHabilitations,
}: HabilitationMedecinFormProps) {
  const { code: codeMedecin, libelle: identiteMedecin } = medecin
  const [putModifierHabilitation, { isLoading: isLoadingModificationHabilitation }] =
    usePutModifierHabilitationMutation()

  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const onCloturerHabilitation = async () => {
    const now = new Date()

    if (habilitations && habilitations.length > 0) {
      await Promise.allSettled(
        habilitations.map((habilitation) => {
          const request = {
            codeMedecin,
            dateDebut: isDateAfterNow(habilitation?.dateDebut) ? now : undefined,
            dateFin: formatDateISO(now),
            clotureImmediate: 1,
          } as ModifierHabilitationRequest
          return putModifierHabilitation({ headerGroupe: habilitation?.headerValue, ...request })
            .unwrap()
            .then(() => {
              enqueueSnackbar(`L'habilitation a été clôturée avec succès pour : ${habilitation.libelleEntreprise}`, {
                variant: 'success',
              })
              onClose()
            })
            .catch(() => {
              enqueueSnackbar(`La clôture de l'habilitation a échoué : ${habilitation.libelleEntreprise}`, {
                variant: 'error',
              })
            })
        })
      ).then(() => {
        refetchHabilitations({
          filter: { codeMedecin },
          sort: {
            sortType: HabilitationEntrepriseSortType.DATE_DEBUT_SORT,
            sortOrder: 'DESC',
          } as SortParam<HabilitationEntrepriseSortType>,
          perPage: 20,
          _timestamp: Date.now(),
        })
      })

      dispatch(medecinEntrepriseApi.util.invalidateTags(['MedecinHabilitable']))
    }
  }

  return (
    <Grid container paddingTop={0}>
      <Grid item xs={12}>
        <ReadOnlyTextField fullWidth id="id-identite-medecin" value={identiteMedecin} label="Identité du médecin" />
      </Grid>
      {habilitations &&
        habilitations.map((habilitation) => (
          <Grid item container key={habilitation.id}>
            <Grid item xs={1.5} textAlign="left" sx={{ alignSelf: 'center' }}>
              <Typography variant="body2" color="readOnly.main">
                {habilitation.libelleEntreprise}
              </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <ReadOnlyTextField
                id="numHabilitation"
                label="Numéro Habilitation"
                value={habilitation.numeroHabilitation}
                fullWidth
              />
            </Grid>
            <Grid item xs={2.5}>
              <ReadOnlyDatePicker
                InputProps={{
                  id: 'date-debut',
                  fullWidth: true,
                }}
                label="Date de début d'habilitation"
                value={habilitation.dateDebut ?? null}
              />
            </Grid>
            <Grid item xs={2.5}>
              <ReadOnlyDatePicker
                InputProps={{
                  id: 'date-fin',
                  fullWidth: true,
                }}
                label="Date de fin d'habilitation"
                value={habilitation.dateFin ?? null}
              />
            </Grid>
            <Grid item xs={3}>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    label="Intervenant occasionnel"
                    sx={{ height: 30 }}
                    control={
                      <Checkbox
                        disabled
                        name="intervenantOccasionnel"
                        checked={habilitation.intervenantOccasionnel}
                        sx={{ padding: 0, marginX: 1.5 }}
                      />
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    label="Avis technique sur pièce"
                    sx={{ height: 30 }}
                    control={
                      <Checkbox
                        disabled
                        name="expertiseSurPiece"
                        checked={habilitation.expertiseSurPiece}
                        sx={{ padding: 0, marginX: 1.5, display: 'bloc' }}
                      />
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        ))}
      <Grid item xs={12}>
        <ButtonsStack>
          <AnnulerButton onClick={onClose}>Annuler</AnnulerButton>
          <SupprimerButton onClick={onCloturerHabilitation} loading={isLoadingModificationHabilitation}>
            Clôturer les habilitations en cours
          </SupprimerButton>
        </ButtonsStack>
      </Grid>
    </Grid>
  )
}

import React from 'react'
import { Chip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { AdminIcon, EntrepriseIcon, MedecinIcon, TableauDeBordButton } from 'plateforme/components'
import { ProfilUtilisateur, Role, TypePartenaire } from 'plateforme/store/types/utilisateur'

interface RoleUtilisateurTuileProps {
  role: Role
  profil: ProfilUtilisateur
  onClick: VoidFunction
}

function RoleUtilisateurTuile({ role, profil, onClick }: RoleUtilisateurTuileProps) {
  const { isProfilGroupe, actif, raisonSociale, codeProfessionel, libelleService, restrictionIp, typePartenaire } =
    profil
  const description = isProfilGroupe ? `${role.description} Groupe` : role.description
  const titre = isProfilGroupe ? raisonSociale : `${raisonSociale ?? 'Partenaire inconnu'} (${codeProfessionel})`

  const getTypePartenaireIcon = () => {
    if (isProfilGroupe) {
      return (
        <>
          <EntrepriseIcon color="primary" /> <EntrepriseIcon color="primary" /> <EntrepriseIcon color="primary" />
        </>
      )
    }

    switch (typePartenaire) {
      case TypePartenaire.Entreprise: {
        return <EntrepriseIcon color="primary" />
      }
      case TypePartenaire.Medecin: {
        return <MedecinIcon color="primary" />
      }
      case TypePartenaire.Admin: {
        return <AdminIcon color="primary" />
      }
      default:
        return null
    }
  }

  return (
    <TableauDeBordButton
      variant="contained"
      onClick={onClick}
      backgroundColor={isProfilGroupe ? '#7EC8E3' : '#deebff'}
      sx={{ display: 'block', textShadow: 'initial', backgroundColor: '' }}
      disabled={restrictionIp}
    >
      {getTypePartenaireIcon()}
      <Stack alignItems="center" spacing={1}>
        {!actif && (
          <Typography color="gray" variant="h2">
            Inactif
          </Typography>
        )}
        <Typography variant="h2" color="text.primary">
          {titre}
        </Typography>
        {libelleService && !isProfilGroupe && (
          <Typography color="dimgray" fontWeight="500">{`${libelleService}`}</Typography>
        )}
        <Typography color="text.primary">{`${description}`}</Typography>
        {restrictionIp && <Chip color="error" label="Adresse IP non-autorisée" />}
      </Stack>
    </TableauDeBordButton>
  )
}

export default RoleUtilisateurTuile

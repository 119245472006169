import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Typography } from '@mui/material'
import {
  AjouterButton,
  AnnulerButton,
  ButtonsStack,
  DatePickerInput,
  EditTextField,
  MessageAlert,
  ReadOnlyTextField,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { formatDateISO } from 'plateforme/services/dates.services'
import HabilitationEntreprise, {
  CreerHabilitationRequest,
  HabilitationEntrepriseSortType,
} from 'assureur/store/types/habilitationEntreprise'
import { usePostCreerHabilitationMutation } from 'assureur/store/apis/habilitationEntrepriseApi'
import { trimToUndefined } from 'plateforme/services/utils'
import { SortParam } from 'plateforme/store/types/pageRecherche'
import MedecinEntreprise from 'assureur/store/types/medecinEntreprise'
import { useDispatch } from 'react-redux'
import { medecinEntrepriseApi } from 'assureur/store/apis/medecinEntrepriseApi'

interface HabilitationMedecinFormProps {
  medecin: MedecinEntreprise
  entreprises: EntreprisesGroupe[]
  habilitation?: HabilitationEntreprise

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetchHabilitations: (filter: any) => void
  onClose: VoidFunction
}

export interface EntreprisesGroupe {
  libelle: string
  code: string
  headerValue?: string
  intervenantOccasionnelParametrage?: boolean
  expertiseSurPieceParametrage?: boolean
}

interface EntrepriseGroupeData {
  code: string
  libelle: string
  isChecked?: boolean
  headerValue?: string
  intervenantOccasionnel?: boolean
  expertiseSurPiece?: boolean
}

interface DataHabilitationMedecinForm {
  numeroHabilitation: string
  dateDebut: Date | null
  dateFin: Date | null
  entreprisesGroupe: EntrepriseGroupeData[]
}

export default function AjoutHabilitationMedecinGroupeForm({
  medecin,
  entreprises,
  habilitation,
  onClose,
  refetchHabilitations,
}: HabilitationMedecinFormProps) {
  const { code: codeMedecin, libelle: identiteMedecin } = medecin
  const {
    control,
    getValues,
    trigger,
    setError,
    watch,
    getFieldState,
    clearErrors,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      numeroHabilitation: habilitation?.numeroHabilitation ?? null,
      dateDebut: habilitation?.dateDebut ?? null,
      dateFin: habilitation?.dateFin ?? null,
      entreprisesGroupe: entreprises?.map((item: EntreprisesGroupe) => ({
        code: item.code,
        libelle: item.libelle,
        headerValue: item.headerValue,
        isChecked: false,
        intervenantOccasionnel: false,
        expertiseSurPiece: false,
      })) as EntrepriseGroupeData[],
    },
  })

  const [postCreerHabilitation, { error: errorResponse, isLoading: isLoadingCreationHabilitation }] =
    usePostCreerHabilitationMutation()

  useErrorFormMapper(errorResponse as IQueryErrorResponse, setError, getValues)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const isValidResponse = (
    responses: PromiseSettledResult<HabilitationEntreprise | IQueryErrorResponse>[]
  ): boolean => {
    if (
      responses.every((resp) => resp.status === 'fulfilled' && !('status' in resp.value && resp?.value.status === 400))
    )
      return true
    return false
  }

  const onAjouterHabilitation = async () => {
    const dataHabilitationForm = getValues() as DataHabilitationMedecinForm
    const dataEntrepisesGoupe = dataHabilitationForm.entreprisesGroupe

    if (dataEntrepisesGoupe.length > 0 && isCheckedEntreprisesValide) {
      await Promise.allSettled(
        dataEntrepisesGoupe
          .filter((entreprise) => entreprise.isChecked)
          .map((entreprise) => {
            const request = {
              codeMedecin,
              numeroHabilitation: trimToUndefined(dataHabilitationForm.numeroHabilitation),
              dateDebut: formatDateISO(dataHabilitationForm.dateDebut),
              dateFin: formatDateISO(dataHabilitationForm.dateFin),
              intervenantOccasionnel: entreprise.intervenantOccasionnel,
              expertiseSurPiece: entreprise.expertiseSurPiece,
            } as CreerHabilitationRequest

            return postCreerHabilitation({ headerGroupe: entreprise.headerValue, ...request })
              .unwrap()
              .then((response) => {
                enqueueSnackbar(`Le médecin a été habilité avec succès pour : ${entreprise.libelle}`, {
                  variant: 'success',
                })
                return response
              })
              .catch((response: IQueryErrorResponse) => {
                if (response.status === 412) {
                  enqueueSnackbar(response.data?.message, { variant: 'error' })
                } else {
                  enqueueSnackbar(`L'habilitation du médecin a échoué pour : ${entreprise.libelle}`, {
                    variant: 'error',
                  })
                }
                return response
              })
          })
      ).then((responses) => {
        if (isValidResponse(responses)) {
          refetchHabilitations({
            filter: { codeMedecin },
            sort: {
              sortType: HabilitationEntrepriseSortType.DATE_DEBUT_SORT,
              sortOrder: 'DESC',
            } as SortParam<HabilitationEntrepriseSortType>,
            perPage: 20,
            _timestamp: Date.now(),
          })
          onClose()
        }
      })

      dispatch(medecinEntrepriseApi.util.invalidateTags(['MedecinHabilitable']))
    } else {
      setError('entreprisesGroupe', { type: 'global', message: 'Vous devez cocher au moins une entreprise.' })
    }
  }

  const isCheckedEntreprisesValide = watch('entreprisesGroupe').some((entreprise) => entreprise.isChecked)
  const { error: entreprisesGroupeError } = getFieldState('entreprisesGroupe')
  const clearErrorEntreprisesGroupe = () => {
    clearErrors('entreprisesGroupe')
    trigger('entreprisesGroupe')
  }

  return (
    <form id="form-ajout-habilitation">
      <Grid container paddingTop={0}>
        <Grid item xs={6}>
          <ReadOnlyTextField fullWidth id="id-identite-medecin" value={identiteMedecin} label="Identité du médecin" />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="numeroHabilitation"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                fullWidth
                id="numero-habilitation"
                label="Numéro  Habilitation"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fieldError={error}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="dateDebut"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <DatePickerInput
                label="Date de début d'habilitation"
                InputProps={{
                  id: 'date-debut',
                  fullWidth: true,
                }}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fieldError={error}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="dateFin"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <DatePickerInput
                label="Date de fin d'habilitation"
                InputProps={{
                  id: 'date-fin',
                  fullWidth: true,
                }}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fieldError={error}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography> Choisissez les entreprises que vous voulez habiliter </Typography>
        </Grid>
        <Grid item xs={8}>
          {entreprises.map((entreprise, index) => (
            <Grid container item xs={12} sm={12} md={12} key={entreprise.code}>
              <Grid item xs={4}>
                <Controller
                  key={entreprise.code}
                  name={`entreprisesGroupe.${index}.isChecked`}
                  control={control}
                  render={({ field: { onBlur, onChange }, fieldState: { error } }) => (
                    <FormControl error={error !== undefined}>
                      <FormGroup>
                        <FormControlLabel
                          label={entreprise.libelle}
                          sx={{ height: 30 }}
                          control={
                            <Checkbox
                              name={entreprise.code}
                              onBlur={onBlur}
                              onChange={(e) => {
                                clearErrorEntreprisesGroupe()
                                onChange(e)
                              }}
                              sx={{ padding: 0, marginX: 1.5 }}
                            />
                          }
                        />
                      </FormGroup>
                      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  key={entreprise.code}
                  name={`entreprisesGroupe.${index}.intervenantOccasionnel`}
                  control={control}
                  defaultValue={habilitation?.intervenantOccasionnel}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <FormControl error={error !== undefined}>
                      <FormGroup>
                        <FormControlLabel
                          label="Intervenant occasionnel"
                          sx={{ height: 30 }}
                          control={
                            <Checkbox
                              disabled={!entreprise?.intervenantOccasionnelParametrage}
                              name="intervenantOccasionnel"
                              checked={value}
                              onBlur={onBlur}
                              onChange={onChange}
                              sx={{ padding: 0, marginX: 1.5 }}
                            />
                          }
                        />
                      </FormGroup>
                      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  key={entreprise.code}
                  name={`entreprisesGroupe.${index}.expertiseSurPiece`}
                  control={control}
                  defaultValue={habilitation?.expertiseSurPiece}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <FormControl error={error !== undefined}>
                      <FormGroup>
                        <FormControlLabel
                          label="Avis technique sur pièce"
                          sx={{ height: 30 }}
                          control={
                            <Checkbox
                              disabled={!(entreprise?.expertiseSurPieceParametrage && medecin?.expertiseSurPiece)}
                              name="expertiseSurPiece"
                              checked={value}
                              onBlur={onBlur}
                              onChange={onChange}
                              sx={{ padding: 0, marginX: 1.5, display: 'block' }}
                            />
                          }
                        />
                      </FormGroup>
                      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
        {entreprisesGroupeError && (
          <Grid item xs={12}>
            <MessageAlert severity="error">{entreprisesGroupeError?.message}</MessageAlert>
          </Grid>
        )}

        <Grid item xs={12}>
          <ButtonsStack>
            <AnnulerButton onClick={onClose}>Annuler</AnnulerButton>
            <AjouterButton disabled={!isValid} onClick={onAjouterHabilitation} loading={isLoadingCreationHabilitation}>
              Créer l&apos;habilitation
            </AjouterButton>
          </ButtonsStack>
        </Grid>
      </Grid>
    </form>
  )
}

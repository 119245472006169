import React from 'react'
import { Alert, Grid } from '@mui/material'
import HabilitationMedecin, { HabilitationSortType } from 'medecin/store/types/habilitationMedecin'
import { InfiniteScrollComponent, ResultatRechercheBarCard } from 'plateforme/components'
import { SortParam, SortOption } from 'plateforme/store/types/pageRecherche'
import HabilitationMedecinCard from 'medecin/parts/rechercheHabilitation/ListeHabilitationsMedecinPart/HabilitationMedecinCard'
import { toTelechargementExportHabilitationHref } from 'plateforme/App'
import { AuthDownloadAction } from 'plateforme/store/types/utilisateur'
import { getHabilitationMedecinSort, setHabilitationMedecinSort } from 'admin/store/slices/sortSlice/sortSlice'

type ListeHabilitationsMedecinAdminPartProps = {
  combinedData: Array<HabilitationMedecin>
  total?: number
  readMore: () => void
  sortBy: (param: SortParam<HabilitationSortType>) => void
  initSort: () => void
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  isSuccess: boolean
  exportParams?: string
}

export default function ListeHabilitationsMedecinAdminPart({
  combinedData,
  total,
  readMore,
  sortBy,
  initSort,
  isError,
  isLoading,
  isFetching,
  isSuccess,
  exportParams,
}: ListeHabilitationsMedecinAdminPartProps) {
  // les options de tri disponibles a passer au composant SortComponentMenu
  const sortOptions: SortOption<HabilitationSortType>[] = [
    { sortType: HabilitationSortType.DATE_CREATION_SORT, label: 'Date de création' },
    { sortType: HabilitationSortType.NUMERO_HABILITATION_SORT, label: `Numéro d'habilitation` },
    { sortType: HabilitationSortType.ENTREPRISE_SORT, label: 'Entreprise' },
    { sortType: HabilitationSortType.DATE_DEBUT_SORT, label: 'Date de début' },
    { sortType: HabilitationSortType.DATE_FIN_SORT, label: 'Date de fin' },
    { sortType: HabilitationSortType.STATUT_SORT, label: 'Statut' },
  ]

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      sx={{ '& .infinite-scroll-component__outerdiv': { width: '100%' } }}
    >
      <Grid item container xs={12} paddingBottom={2.5}>
        <ResultatRechercheBarCard
          title="Liste des habilitations"
          total={total}
          loading={isLoading || isFetching}
          sortBy={sortBy}
          sortOptions={sortOptions}
          initSort={initSort}
          withSorting
          withExportCSV
          hrefExportCSV={toTelechargementExportHabilitationHref(exportParams)}
          requestExportCSV={{ action: AuthDownloadAction.TELECHARGER_CSV_HABILITATION }}
          setSortSlice={setHabilitationMedecinSort}
          getSortSlice={getHabilitationMedecinSort}
        />
      </Grid>
      {(isError && !isSuccess && !isLoading && !isFetching) || total === 0 ? (
        <Alert severity="info" sx={{ width: '100%' }}>
          Aucune habilitation remontée
        </Alert>
      ) : (
        <InfiniteScrollComponent
          dataLength={combinedData?.length}
          next={readMore}
          hasMore={combinedData.length < (total ?? 0)}
          isLoading={isLoading || isFetching}
          xs={12}
          lg={6}
        >
          <Grid item container xs={12}>
            {combinedData?.map((habilitation: HabilitationMedecin) => (
              <Grid item xs={12} lg={6} key={`${habilitation.libelle}${habilitation.dateDebut}`}>
                <HabilitationMedecinCard
                  isAdmin
                  key={`${habilitation.libelle}${habilitation.dateDebut}`}
                  habilitation={habilitation}
                />
              </Grid>
            ))}
          </Grid>
        </InfiniteScrollComponent>
      )}
    </Grid>
  )
}

export default interface FluxTechniqueAdmin {
  id: number
  action?: string
  detail?: string
  httpMethod: HttpMethod
  httpPath: string
  httpStatusCode: number
  ip?: string
  timestamp: string
}

export interface FluxTechniqueAdminFilter {
  dateDebut?: string
  dateFin?: string
  idEntreprise?: string
  labelEntreprise?: string
  idMedecin?: number
  action?: string
  detail?: string
  httpMethod?: HttpMethod
  httpPath?: string
  httpStatusCode?: number
  onlyTotal?: 0 | 1
}

export const AllHttpMethods = ['OPTIONS', 'GET', 'HEAD', 'POST', 'PUT', 'DELETE', 'TRACE', 'CONNECT', 'PATCH'] as const

export type HttpMethod = typeof AllHttpMethods[number]

export enum FluxTechniqueAdminSortType {
  ACTION_SORT = 'action',
  DETAIL_SORT = 'detail',
  HTTP_METHOD_SORT = 'http_method',
  HTTP_PATH_SORT = 'http_path',
  HTTP_STATUS_CODE_SORT = 'http_status_code',
  ADRESSE_IP_SORT = 'ip',
  TIMESTAMP_SORT = 'timestamp',
}

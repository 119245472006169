import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'plateforme/store'
import { SortParam } from 'plateforme/store/types/pageRecherche'

interface SortCriteria<SortType> {
  dossierSort?: SortParam<SortType>
  notificationMessageSort?: SortParam<SortType>
  notificationDossierSort?: SortParam<SortType>
  notificationMedecinSort?: SortParam<SortType>
  medecinSort?: SortParam<SortType>
  medecinGroupeSort?: SortParam<SortType>
  habilitationSort?: SortParam<SortType>
  fluxTechniquesSort?: SortParam<SortType>
  factureSort?: SortParam<SortType>
}

export const sortSliceName = 'sortSliceEntreprise'

const sortSliceEntreprise = createSlice({
  name: sortSliceName,
  initialState: {
    dossierSort: undefined,
    notificationMessageSort: undefined,
    notificationDossierSort: undefined,
    notificationMedecinSort: undefined,
    medecinSort: undefined,
    medecinGroupeSort: undefined,
    habilitationSort: undefined,
    fluxTechniquesSort: undefined,
    factureSort: undefined,
  } as SortCriteria<string>,
  reducers: {
    setDossierSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.dossierSort = action.payload
    },
    setNotificationMessageSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.notificationMessageSort = action.payload
    },
    setNotificationDossierSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.notificationDossierSort = action.payload
    },
    setNotificationMedecinSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.notificationMedecinSort = action.payload
    },
    setMedecinSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.medecinSort = action.payload
    },
    setMedecinGroupeSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.medecinGroupeSort = action.payload
    },
    setHabilitationSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.habilitationSort = action.payload
    },
    setFluxTechniquesSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.fluxTechniquesSort = action.payload
    },
    setFactureSort: (state, action: PayloadAction<SortParam<string>>) => {
      state.factureSort = action.payload
    },
  },
})

export const {
  setDossierSort,
  setNotificationMessageSort,
  setNotificationDossierSort,
  setNotificationMedecinSort,
  setMedecinSort,
  setMedecinGroupeSort,
  setHabilitationSort,
  setFluxTechniquesSort,
  setFactureSort,
} = sortSliceEntreprise.actions

export const getDossierSort = (state: RootState) => state?.sortSliceEntreprise.dossierSort
export const getNotificationMessageSort = (state: RootState) => state?.sortSliceEntreprise.notificationMessageSort
export const getNotificationDossierSort = (state: RootState) => state?.sortSliceEntreprise.notificationDossierSort
export const getNotificationMedecinSort = (state: RootState) => state?.sortSliceEntreprise.notificationMedecinSort
export const getMedecinSort = (state: RootState) => state?.sortSliceEntreprise.medecinSort
export const getMedecinGroupeSort = (state: RootState) => state?.sortSliceEntreprise.medecinGroupeSort
export const getHabilitationSort = (state: RootState) => state?.sortSliceEntreprise.habilitationSort
export const getFluxTechniquesSort = (state: RootState) => state?.sortSliceEntreprise.fluxTechniquesSort
export const getFactureSort = (state: RootState) => state?.sortSliceEntreprise.factureSort

export default sortSliceEntreprise.reducer

import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Path } from 'history'
import { Alert, Box, Container, Grid, Stack, styled, Typography } from '@mui/material'
import { BackdropComponent, ChangerProfilMenuButton, DeconnexionMenuButton, TransmedLogo } from 'plateforme/components'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import { useAppDispatch } from 'plateforme/store/hooks/hooks'
import { chooseGroupeProfil, chooseProfil, setIsProfilGroupe, setGroupeName } from 'plateforme/store/slices/profilSlice'
import Utilisateur, { RoleUtilisateur, RoleUtilisateurGroupe } from 'plateforme/store/types/utilisateur'
import { rootUtils } from 'plateforme/store/store'
import { logout } from 'plateforme/security/keycloak'
import redirectPath from './redirectPath'
import RoleUtilisateurTuile from './RoleTuile'

const BoxStyled = styled(Box)(() => ({
  backgroundColor: '#ffffff',
}))

function getProfilPreselectionne(search: URLSearchParams, utilisateur?: Utilisateur) {
  const profilPreselectionne = search.get('profil')
  // si le query param 'profil' commence par un @ dans le profil demandé
  if (profilPreselectionne?.startsWith('@')) {
    // un profil actif + le nombre de role est max de 1 (mono role) pour un couple 'entreprise+codeService'
    // le profilId doit contenir le profil.value (code ACPR) + profil.codeService (si présent) de l'utilisateur
    return utilisateur?.profils
      .filter((profil) => profil.actif && profil?.roles?.length === 1)
      .map((profil) => profil?.roles.at(0)?.id)
      .find((profilId) => profilId?.endsWith(profilPreselectionne) ?? false)
  }

  return utilisateur?.profils
    ?.filter((profil) => profil.actif && !profil.isProfilGroupe)
    ?.flatMap((profil) => profil.roles)
    .map((role) => role.id)
    .find((profilId) => profilPreselectionne === profilId)
}

export default function ProfilSelectionPage({ forceRedirect = true }: { forceRedirect?: boolean }) {
  const { data: utilisateur, isLoading, isError, isFetching, refetch } = useGetUtilisateurActifQuery()
  const [isRedirect, setIsRedirect] = useState(false)
  const [showProfilInactifMsg, setShowProfilInactifMsg] = useState(false)
  const compteurProfils = utilisateur?.profils?.length ?? 0
  const dispatch = useAppDispatch()
  const location = useLocation()

  const profilsGroupe = utilisateur?.profils?.filter((profil) => profil.isProfilGroupe)
  const profils = utilisateur?.profils?.filter((profil) => !profil.isProfilGroupe)

  const { from } = (location.state ?? { from: undefined }) as {
    from: Path | undefined
  }
  if (isError && from?.pathname === '/logout') {
    window.location.reload()
  }

  const search = new URLSearchParams(from?.search)
  const profilPreselectionne = getProfilPreselectionne(search, utilisateur)

  useEffect(() => {
    if (profilPreselectionne) {
      dispatch(chooseProfil(profilPreselectionne))
      refetch()
      rootUtils.forEach((util) => dispatch(util.resetApiState()))
      setIsRedirect(true)
    }
  }, [profilPreselectionne, dispatch, refetch])

  if (isRedirect || forceRedirect) {
    const redirect = redirectPath(utilisateur, from)
    if (redirect !== undefined) {
      return <Navigate to={redirect} replace state={{ from }} />
    }
  }

  const choose = (isProfilActif: boolean, roleUtilisateur: RoleUtilisateur) => {
    if (isProfilActif) {
      dispatch(chooseProfil(roleUtilisateur.id))
      dispatch(chooseGroupeProfil(undefined))
      dispatch(setIsProfilGroupe(false))
      refetch()
      rootUtils.forEach((util) => dispatch(util.resetApiState()))
      setIsRedirect(true)
    } else {
      setShowProfilInactifMsg(true)
    }
  }

  const chooseGroupe = (isProfilActif: boolean, roleGroupe?: RoleUtilisateurGroupe) => {
    if (isProfilActif) {
      dispatch(chooseProfil(roleGroupe?.ids[0] ?? ''))
      dispatch(chooseGroupeProfil(roleGroupe?.ids ?? []))
      dispatch(setGroupeName(roleGroupe?.groupe))
      dispatch(setIsProfilGroupe(true))
      refetch()
      rootUtils.forEach((util) => dispatch(util.resetApiState()))
      setIsRedirect(true)
    } else {
      setShowProfilInactifMsg(true)
    }
  }

  if (isLoading || isFetching || isError) {
    return <BackdropComponent />
  }

  return (
    <Box
      margin={0}
      padding={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="calc(100vh - 18px)"
    >
      <BoxStyled>
        <Stack direction="row" alignItems="center" justifyContent="center" padding={4}>
          <TransmedLogo />
        </Stack>
        <Typography variant="h1" sx={{ textAlign: 'center' }} padding={4}>
          Veuillez choisir pour quelle entreprise et avec quel profil vous souhaitez vous connecter sur la plateforme
          TransMED
        </Typography>
        {!showProfilInactifMsg && (
          <Container maxWidth="md">
            <Grid container padding={2}>
              {profilsGroupe?.map((profil) => (
                <Grid item xs={6}>
                  <RoleUtilisateurTuile
                    role={profil.rolesGroupe}
                    profil={profil}
                    onClick={() => chooseGroupe(profil.actif, profil.rolesGroupe)}
                  />
                </Grid>
              ))}
            </Grid>

            {profils?.map((profil) => (
              <div key={profil.code + profil.codeService}>
                <Grid container padding={2}>
                  {profil.roles?.map((roleUtilisateur, _indexRole) => (
                    <Grid item xs={6} key={roleUtilisateur.id}>
                      <RoleUtilisateurTuile
                        role={roleUtilisateur}
                        profil={profil}
                        onClick={() => choose(profil.actif, roleUtilisateur)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            ))}
          </Container>
        )}
        {(compteurProfils < 1 || showProfilInactifMsg) && (
          <Box padding={4}>
            <Alert severity="info">
              <Typography variant="subtitle1">
                {`Vous disposez de droits pour une organisation non connue de
        l'application. Nous vous invitons à contacter notre assistance à l'adresse : `}
              </Typography>
              <a href="mailto:assistance-transmed@agira.asso.fr">assistance-transmed@agira.asso.fr</a>
            </Alert>
          </Box>
        )}
        <Box display="flex" justifyContent="center" padding={4}>
          <DeconnexionMenuButton
            style={{
              color: 'red',
              minWidth: 150,
              marginRight: 16,
            }}
            onClick={() => logout(dispatch)}
          >
            Déconnexion
          </DeconnexionMenuButton>
          {showProfilInactifMsg && compteurProfils > 0 && (
            <ChangerProfilMenuButton
              fontSize={11}
              lineHeight={1.2}
              noWrap
              style={{
                minWidth: 150,
              }}
              onClick={() => setShowProfilInactifMsg(false)}
            >
              Sélectionner
              <br />
              un autre profil
            </ChangerProfilMenuButton>
          )}
        </Box>
      </BoxStyled>
    </Box>
  )
}
